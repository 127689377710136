import '@brightspace-ui/core/components/button/button.js';
import '../../../../components/permissions-table/permissions-table.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';

import { css, html, LitElement } from 'lit';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class EditPermissions extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      admins: { type: Array, attribute: false },
      allRoles: { type: Array, attribute: false },
      tenantId: { type: String, attribute: false },
      _deleteDialogOpen: { type: Boolean, attribute: false },
      _selectedAdmin: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
      /* Add styles here */
`];
  }

  constructor() {
    super();
    this.admins = [];
    this.allRoles = [];
    this.tenantId = '';
    this._selectedAdmin = null;
    this._deleteDialogOpen = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = true;
    this.allRoles = [
      { roleId: '15a6057f-025d-4162-b49e-d97bdb19efdd', name: 'Admin', description: 'Add and update activities in the catalogue with some restrictions' },
      { roleId: '8b744b1a-59d4-4d1f-898e-cb55250c8370', name: 'Catalogue Manager', description: 'Add and update activities in the catalogue' },
      { roleId: '8c84cba9-2a29-4809-b4dc-b99a0ece0cc5', name: 'Corporate Client Manager', description: 'Add and update corporate client settings' },
      { roleId: '878cd2f8-b8fe-4c1f-8850-df5e7154a96c', name: 'Education Partner Manager', description: 'Add and update education partner settings' },
    ];
    this.tenantId = this.session._tenant.id;
    this.admins = await this.client.getAllUsersByTenantId(this.tenantId, { includeRoles: true });
    // TODO get all roles from the client

    this.skeleton = false;
  }

  render() {
    const activeAdmins = this.admins.filter(admin => admin.active);
    const inactiveAdmins = this.admins.filter(admin => !admin.active);
    return html`
      <h2>${this.localize('edit-permissions.userPermissions')}</h2>
      <p>${this.localize('edit-permissions.userPermissions.description')}</p>
      <d2l-tabs>
        <d2l-tab-panel selected text="${this.localize('edit-permissions.userPermissions.activeTab')}">
          <permissions-table class="d2l-skeletize" @nova-activity-state-change="${this._activeStateChanged}" .allRoles=${this.allRoles} .admins=${activeAdmins} .tenantId="${this.tenantId}"></permissions-table>
        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('edit-permissions.userPermissions.inactiveTab')}">
          <permissions-table @nova-activity-state-change="${this._activeStateChanged}" .allRoles=${this.allRoles} .admins=${inactiveAdmins} .tenantId="${this.tenantId}"></permissions-table>
        </d2l-tab-panel>
      </d2l-tabs>
      ${this._confirmationDialogTemplate}
    `; }

  async _activeStateChanged(e) {
    const { admin } = e.detail;
    this._selectedAdmin = admin;
    this._deleteDialogOpen = true;
  }

  get _confirmationDialogTemplate() {
    const fullName = this._selectedAdmin ? `${this._selectedAdmin.firstName} ${this._selectedAdmin.lastName}` : '';
    return html`
      <confirmation-dialog
        ?opened=${this._deleteDialogOpen}
        type="inactivateUserConfirmation"
        .data="${fullName}"
        @d2l-dialog-close=${this._dialogClose}>
      </confirmation-dialog>`;
  }

  _dialogClose(e) {
    // TODO: actually update the user active status and roles here
    const { action } = e.detail;
    this._deleteDialogOpen = false;

    if (action === 'inactivate') {
      this.admins = this.admins.map(admin => (admin.id === this._selectedAdmin.id ? { ...admin, active: false, roles: [] } : admin));
      // TODO: update toast to include undo and be undismissable
      this.session.toast({ type: 'default', message: this.localize('edit-permissions.permissions-table.deleteUserRolesConfirmation.toast') });
    }
  }

}

window.customElements.define('edit-permissions', EditPermissions);

