import Activity from '../shared/models/activity/activity.js';
import { createUrlWithParams } from '../shared/helpers/util.js';
import { Currency } from '../shared/drivers/currency.js';
import { getTopCareerItems } from '../shared/helpers/jobs.js';

export const headers = {
  'Content-Type': 'application/json',
};

export default class Client {

  constructor({ session }) {
    this.session = session;
  }

  async callWithRetries(fn, options = { maxRetries: 3 }, attempts = 0) {
    const backoff = amount => new Promise(resolve => setTimeout(resolve, Math.round(2000 + (1000 * (amount - 1)))));

    if (attempts > options.maxRetries) {
      throw new Error('Out of retries');
    }

    try {
      attempts += 1;
      const result = await fn();
      return result;
    } catch (error) {
      await backoff(attempts);
      return await this.callWithRetries(fn, options, attempts);
    }
  }

  /**
   * This search is taking a naive approach of just returning everything.
   * It will get all activities using large pages of 500 at a time.
   */
  async activityList(category, filters = {}) {
    const activities = [];
    let from = 0;
    let numOfRecords;
    const size = 500;

    // Query 500 records at a time, stop querying when all records have been retrieved
    do {
      // eslint-disable-next-line no-await-in-loop
      const docs = await this.searchActivities({ from, size, filters, category });
      const { hits, total } = docs;
      activities.push(...hits.map(hit => new Activity(hit)));
      from += size;
      numOfRecords = numOfRecords || total.value;
    } while (from < numOfRecords);

    return activities;
  }

  addCourses(programId, addedCourses) {
    return this.session.request(`activities/relation/${programId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ addedCourses }),
    });
  }

  applyActivity(activityId, requestedApprover, message, tax, tosVersion, extraAttributes, locationDetails) {
    return this.session.request(`activities/${activityId}/apply`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ requestedApprover, message, tax, tosVersion, extraAttributes, location: locationDetails }),
    });
  }

  applyExternalActivity(application) {
    return this.session.request('activities/external-activity/apply', {
      method: 'POST',
      headers,
      body: JSON.stringify(application),
    });
  }

  calculateTaxRates(taxData) {
    return this.session.request('tax/rates', {
      method: 'POST',
      headers,
      body: JSON.stringify({ taxData }),
    });
  }

  // Employee initiated cancellations
  revokeApplication(uuid, cancelReason) {
    const completedDate = new Date().toISOString();
    return this.session.request(`requests/${uuid}/revoke`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ completedDate, cancelReason }),
    });
  }

  completeApplication(uuid, enrollDate, completedDate, completionStatus, cancelReason, refundPct) {
    return this.session.request(`requests/${uuid}/complete`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ enrollDate, completedDate, completionStatus, cancelReason, refundPct }),
    });
  }

  contactSupport(userInfo, message, category) {
    return this.session.request('support/contact', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ userInfo, message, category }),
    });
  }

  convertCostToEmployee(applicationUuid, toCurrency) {
    return this.session.request(createUrlWithParams(`requests/${applicationUuid}/cost-to-employee`, {
      in: toCurrency,
    }), {
      method: 'GET',
      headers,
    });
  }

  convertCurrency(from, to, amount) {
    return Currency.convert(from, to, amount);
  }

  countReportAdoption(opts) {
    return this.session.request('report/adoption/execute/count', {
      method: 'PUT',
      headers,
      body: JSON.stringify(opts),
    });
  }

  countryNames() {
    const lang = document.documentElement.getAttribute('lang');
    return this.session.request(`lang/countries/${lang}`);
  }

  createLoginLink(opts) {
    return this.session.domainRequest('login/magic/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(opts),
    });
  }

  createGuestLogin(opts) {
    return this.session.domainRequest('login/magic/guest/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(opts),
    });
  }

  createPaymentFlow({ uuid }) {
    return this.session.request('payment/create', {
      method: 'POST',
      headers,
      body: JSON.stringify({ applicationUuid: uuid }),
    });
  }

  createRequestExport(appliedRequestFilters) {
    return this.session.request('requests/export', {
      method: 'POST',
      headers,
      body: JSON.stringify({ appliedRequestFilters }),
    });
  }

  createJobsExport(tenantId) {
    return this.session.request(`jobs/${tenantId}/export`, {
      method: 'GET',
      headers,
    });
  }

  checkFirstTimeUser(userGuid) {
    return this.session.request(`users/is-first-session/${userGuid}`, {
      method: 'GET',
      headers,
    });
  }

  checkJobsExportStatus(tenantId) {
    return this.session.request(`jobs/${tenantId}/export/status`, {
      method: 'GET',
      headers,
    });
  }

  checkActivitiesSearchStatus(tenantId, userId) {
    return this.session.request(`activities/search/export/${tenantId}/${userId}/status`, {
      method: 'GET',
      headers,
    });
  }

  checkApplicationOverviewReportStatus(tenantId, userId) {
    return this.session.request(`requests/export/${tenantId}/${userId}/status`, {
      method: 'GET',
      headers,
    });
  }

  createTenant(tenant) {
    return this.session.request('tenants', {
      method: 'POST',
      headers,
      body: JSON.stringify({ tenant }),
    });
  }

  deleteTenantSSO(tenantSSO) {
    return this.session.request(`tenants/${tenantSSO.tenantId}/sso/${tenantSSO.id}`, {
      method: 'DELETE',
      headers,
    });
  }

  executeReportWidget(type, opts) {
    return this.session.request(`report/${type}/execute`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(opts),
    });
  }

  // Extracts skills from activity description and saves extracted skills to activity
  extractSkills(activityId) {
    return this.session.request('activities/extract-skills', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ activityId }),
    });
  }

  fetchActivity(activityId) {
    return this.session.request(`activities/${activityId}`);
  }

  fetchTenant(tenantId) {
    return this.session.request(`tenants/${tenantId}`);
  }

  fetchTenantSSO(tenantId) {
    return this.session.request(`tenants/${tenantId}/sso`);
  }

  fetchCoordinatesFromPostalCodes(postalCode, country) {
    const response = this.session.request('location/coordinates', {
      method: 'POST',
      headers,
      body: JSON.stringify({ postalCode, country }),
    });
    return response;
  }

  generateActivityHierarchy(tenantId, providerId) {
    return this.session.request(`activities/generate-hierarchy/${tenantId}/${providerId}`, {
      method: 'GET',
      headers,
    });
  }

  getAccountConnection(userGuid, providerId) {
    const url = createUrlWithParams('connection', { userGuid, providerId });
    return this.session.request(url);
  }

  getActivityCompletion(activityId) {
    return this.session.request(`activities/${activityId}/completion`);
  }

  getActivityReport(tenantId, userId) {
    return this.session.request(`activities/search/export/${tenantId}/${userId}`, {
      method: 'GET',
      headers,
    });
  }

  getAllStreams(tenantId) {
    return this.session.request(`tenants/${tenantId}/all-streams`);
  }

  async getStreamFromPath(tenantId, path) {
    const allStreams = await this.session.request(`tenants/${tenantId}/all-streams`);
    return allStreams.find(stream => stream.path === path);
  }

  /**
 * Retrieves an application by its UUID.
 * Optionally includes related provider and activity information based on query parameters.
 *
 * NOTE: If you inlcude optional parameters, the response will be an object with the following properties:
 * - application: The application object. (included as a separate property ONLY if any flags are true)
 * - provider: The provider object. (if withProvider is true)
 * - activity: The activity object. (if withActivity is true)
 * - tenant: The tenant object. (if withTenant is true)
 *
 * @param {string} uuid - The unique identifier of the application.
 * @param {Object} params - An object containing optional query parameters.
 * @param {boolean} [params.withProvider=false] - If true, includes provider information in the response.
 * @param {boolean} [params.withActivity=false] - If true, includes activity information in the response.
 * @param {boolean} [params.withApplicantTenant=false] - If true, includes applicant tenant information in the response
 */
  getApplication(uuid, params = {}) {
    const url = createUrlWithParams(`requests/${uuid}`, params);
    return this.session.request(url);
  }

  getApplications(userGuid, includeRequested = false, tenantIdFilter) {
    const url = createUrlWithParams('requests', { userGuid, includeRequested, tenantIdFilter });
    return this.session.request(url);
  }

  getApplicationOverviewReportURL(tenantId, userId) {
    return this.session.request(`requests/export/${tenantId}/${userId}`, {
      method: 'GET',
      headers,
    });
  }

  searchApplications({ includeAggregations, filters, from, size, sort }) {
    const url = createUrlWithParams('requests/search', { from, size, filters, sort, includeAggregations });
    return this.session.request(url);
  }

  /**
   * @param activityId
   * @param [includeAllApplications]
   * @returns Array<{}>
   */
  getApplicationsForActivity(activityId, includeAllApplications) {
    const url = createUrlWithParams(`requests/activity/${activityId}`, { includeAllApplications });
    return this.session.request(url);
  }

  /**
   * Currently this function assumes that there is only one application per activity. Once that changes the code that calls this function will have to be updated
   * @param activityId
   * @param userGuid
   * @returns Array<{}>
   */
  getApplicationsForActivityForUser(activityId, userGuid) {
    return this.session.request(`requests/activity/${activityId}/${userGuid}`);
  }

  getRequestedApproverDetails(tenantId, applicationId) {
    return this.session.request(`users/${tenantId}/${applicationId}/requested-approver`);
  }

  getAudit(tenantId, pageSize, action, timeframe, search, from, to, nextToken) {
    const params = { tenantId, pageSize, action, timeframe, search, nextToken, from, to };

    const url = createUrlWithParams('audit', params);
    return this.session.request(url);
  }

  getAvailableCredit(userGuid) {
    const url = createUrlWithParams('requests/available-credit', { userGuid });
    return this.session.request(url);
  }

  getUsedCredit(userGuid) {
    const url = createUrlWithParams('requests/used-credit', { userGuid });
    return this.session.request(url);
  }

  getJobsList(tenantId) {
    return this.session.request(`jobs/${tenantId}`);
  }

  async getCareerItems(type = 'jobs') {
    const { lightcastJobTitleId: userJobId, tenantId } = this.session.user;
    const { jobTitles } = await this.session.request(`jobs/${tenantId}`);
    const hasUserJobData = userJobId && jobTitles.find(({ jobId }) => jobId === userJobId)?.activities > 0;

    if (userJobId && !hasUserJobData) {
      try {
        const { jobData } = await this.session.request(`jobs/${tenantId}/${userJobId}/driver`);
        if (jobData) jobTitles.push(jobData);
      } catch (err) {
        console.error('Fetching user job data failed');
      }
    }

    return getTopCareerItems(userJobId, jobTitles, type);
  }

  getJobData(jobId) {
    const { tenantId } = this.session.user;
    return this.session.request(`jobs/${tenantId}/${jobId}`);
  }

  getCurrencyConversionRate(from, to) {
    return Currency.getRate(from, to);
  }

  getCustomCondition(tenantId) {
    return this.session.request(`tenants/${tenantId}/custom-content/type/customCondition`);
  }

  getCustomFAQContent(tenantId) {
    return this.session.request(`tenants/${tenantId}/custom-content/type/faq`);
  }

  getCustomHomepageContent(tenantId) {
    return this.session.request(`tenants/${tenantId}/custom-content/type/homepage`);
  }

  getCustomMagicLinkContent(tenantId, lang) {
    return this.session.domainRequest(`login/content/${tenantId}/${lang}`);
  }

  getCustomContent(tenantId, type, language, strict) {
    const url = createUrlWithParams(`tenants/${tenantId}/custom-content/type/${type}`, { language, strict });
    return this.session.request(url);
  }

  getExtraUserInfoRequired(userId) {
    return this.session.domainRequest(`users/extra-info-required/${userId}`);
  }

  getFeatures(tenantId) {
    return this.session.request(`features/${tenantId}`);
  }

  getInProgressActivities() {
    return this.session.request('activities/in-progress');
  }

  getMarketingInfo(userId) {
    return this.session.domainRequest(`login/marketing/${userId}`);
  }

  getSearchTermTitlesBySkillOverlap(tenantId, jobTitleId, term) {
    const url = `jobs/${tenantId}/${jobTitleId}/search/by-skill-overlap`;
    return this.session.request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ term }),
    });
  }

  getSimilarTitlesBySkillOverlap(tenantId, jobTitleId, jobTitleName, jobTitleSOC) {
    const params = new URLSearchParams({ jobTitleName, jobTitleSOC });
    const url = `jobs/${tenantId}/${jobTitleId}/similar/by-skill-overlap?${params.toString()}`;
    return this.session.request(url, {
      method: 'GET',
      headers,
    });
  }

  getSkillSubcategoriesForTitle(jobTitleId, tenantId, options = {}) {
    const { limit, minimumSkills, inflateSkills } = { limit: 6, minimumSkills: 5, inflateSkills: false, ...options };
    const url = createUrlWithParams(`jobs/skills/by-subcategory/${tenantId}/${jobTitleId}`, { limit, minimumSkills, inflateSkills });
    return this.session.request(url, {
      method: 'GET',
      headers,
    });
  }

  getSkills(filters) {
    return this.session.request('activities/search/skills', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ filters }),
    });
  }

  getSkillById(id) {
    return this.session.request(`skills/${id}`);
  }

  getSkillProfile(tenantId, userGuid) {
    return this.session.request(`users/skill-profile/${tenantId}/${userGuid}`, {
      method: 'GET',
      headers,
    });
  }

  querySkillsByName(queryText, limit) {
    const url = createUrlWithParams('skills/query', { name: queryText, limit });
    return this.session.request(url);
  }

  querySkillSubcategoriesByName(queryText, limit) {
    return this.session.request('skills/subcategories/query', {
      method: 'POST',
      headers,
      body: JSON.stringify({ name: queryText, limit }),
    });
  }

  getSkillsForCategory(categoryId) {
    return this.session.request(`skills/category/${categoryId}`);
  }

  async getUserSetting(key) {
    const value = await this.session.request(`users/settings/${key}`);
    this.session.user.setSetting(key, value);
    return value;
  }

  getVisibleProviders(tenantId) {
    const url = createUrlWithParams(`tenants/${tenantId}/visibility`, { subjectType: 'provider' });
    return this.session.request(url);
  }

  getVisibleSkills(tenantId) {
    const url = createUrlWithParams(`tenants/${tenantId}/visibility`, { subjectType: 'skillstream' });
    return this.session.request(url);
  }

  listTenants(type) {
    const url = createUrlWithParams('tenants', { type });
    return this.session.request(url);
  }

  logEvent(e, includeBaseAuditData) {
    try {
      if (includeBaseAuditData) {
        const baseAuditData = {
          date: new Date().toISOString(),
          initiator: this.session.user.guid,
          subject: this.session.user.guid,
        };
        e = { ...baseAuditData, ...e };
      }
      return this.session.request('event', {
        method: 'POST',
        headers,
        body: JSON.stringify(e),
      });
    } catch (error) {
      return Promise.resolve();
    }
  }

  patchActivity(id, updatedAttributes) {
    return this.session.request(`activities/${id}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(updatedAttributes),
    });
  }

  patchActivities(activities) {
    return this.session.request('activities', {
      method: 'PATCH',
      headers,
      body: JSON.stringify(activities),
    });
  }

  removeActivity(activityId) {
    return this.session.request(`activities/${activityId}`, {
      method: 'DELETE',
      headers,
    });
  }

  removeCourses(programId, removedCourses) {
    return this.session.request(`activities/relation/${programId}`, {
      method: 'DELETE',
      headers,
      body: JSON.stringify({ removedCourses }),
    });
  }

  saveFeatures(updatedFeatures) {
    return this.session.request('features', {
      method: 'PUT',
      headers,
      body: JSON.stringify(updatedFeatures),
    });
  }

  saveSkillProfile(profile) {
    return this.session.request('users/skill-profile', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ profile }),
    });
  }

  updateSkillProfile(addedCategories, removedCategories, addedSkills, removedSkills, titles) {
    return this.session.request('users/update-skill-profile', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ addedCategories, removedCategories, addedSkills, removedSkills, titles }),
    });
  }

  /**
   * Removes empty arrays and values from the filter object
   */
  _prepareFiltersForApiCal(filter) {
    const updatedFilter = { ...filter };
    Object.keys(updatedFilter).forEach(key => {
      if ((Array.isArray(updatedFilter[key]) && updatedFilter[key].length === 0) || (!updatedFilter[key] && updatedFilter[key] !== 0)) {
        delete updatedFilter[key];
      }
    });
    return updatedFilter;
  }

  searchActivities({ from, size, filters, randomizeOrder = false, category = undefined, sort = undefined, property = undefined }) {
    const url = createUrlWithParams('activities/search', { from, size, randomizeOrder, filters: this._prepareFiltersForApiCal(filters), category, sort, property });
    return this.session.request(url);
  }

  getStreamOrder({ from, size, filters, randomizeOrder = false, category = undefined, sort = undefined, property = undefined, defaultStreamIds = undefined }) {
    const url = createUrlWithParams('activities/stream-order', { from, size, randomizeOrder, filters: this._prepareFiltersForApiCal(filters), category, sort, property, defaultStreamIds });
    return this.session.request(url);
  }

  exportActivities({ filters, attributes }) {
    return this.session.request('activities/search/export', {
      method: 'POST',
      headers,
      body: JSON.stringify({ filters, attributes }),
    });
  }

  searchLightcast(query, type = 'company') {
    return this.session.request(`search/${type}/${query}`);
  }

  updateBulkEditCache(cache) {
    return this.session.request('users/bulk-edit-cache', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ cache }),
    });
  }

  getBulkEditCache(type, version = '1.0') {
    return this.session.request(`users/bulk-edit-cache/${type}/${version}`);
  }

  getIndustryFromCompanyID(companyId) {
    return this.session.request(`search/industry_name/${companyId}`);
  }

  getInvoiceItem(invoiceItemId) {
    return this.session.request(`invoiceItem/${invoiceItemId}`);
  }

  sendSuggestion(activityId, emails, message) {
    return this.session.request(`suggest/${activityId}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ message, recipients: emails }),
    });
  }

  setAccountConnection(accountConnectInfo) {
    return this.session.request('connection', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ ...accountConnectInfo }),
    });
  }

  setActivityActive(activityId, active) {
    return this.session.request(`activities/${activityId}/active`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ active }),
    });
  }

  setApproval(applicationUuid, approvedAmount, approvalReason, approvalState, isBudgetHolder) {
    return this.session.request(`requests/${applicationUuid}/pending/approvals`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ approvedAmount, approvalReason, approvalState, isBudgetHolder }),
    });
  }

  setCustomContent(tenantId, customContent) {
    return this.session.request(`tenants/${tenantId}/custom-content`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(customContent),
    });
  }

  setDiscountCode(uuid, discountCode) {
    return this.session.request(`requests/${uuid}/discount`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ discountCode }),
    });
  }

  setDocumentTitle(titleText) {
    document.title = titleText;
  }

  setMetaTag(tagName, tagContent) {
    const metaTag = document.querySelector(`meta[name="${tagName}"]`) || document.querySelector(`meta[property="${tagName}"]`);
    if (metaTag) {
      metaTag.content = tagContent;
    } else {
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = tagName;
      newMetaTag.content = tagContent;
      document.head.appendChild(newMetaTag);
    }
  }

  setEntitlements(tenantId, entitlements) {
    return this.session.request(`tenants/${tenantId}/entitlements`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ entitlements }),
    });
  }

  setPaymentDate(applicationUuid) {
    return this.session.request(`requests/${applicationUuid}/payment`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ applicationUuid }),
    });
  }

  async setSessionSetting(key, value) {
    const response = await this.session.request(`session/settings/${key}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ value }),
    });
    this.session.user.settings = response;
    return response;
  }

  async setUser(user) {
    const response = await this.session.request('users', {
      method: 'PUT',
      headers,
      body: JSON.stringify({ user }),
    });
    this.session.user = response;
    return response;
  }

  async getDisplayName(guid) {
    return await this.session.request(`admin/display_name/${guid}`);
  }

  async getRoles(tenantId) {
    return [{
      tenantId,
      id: 'some guid',
      name: 'Admin',
      description: 'You can do anything',
      assignedUsers: 5,
    },
    {
      tenantId,
      id: 'some other guid',
      name: 'User',
      description: 'You can do some things',
      assignedUsers: 0,
    },
    {
      tenantId,
      id: 'another guid',
      name: 'Guest',
      description: 'You can do nothing',
      assignedUsers:  100,
    }];
  }

  async deleteRole(roleId) {
    return roleId;
  }

  async getDeleteRoleImpact(roleId) {
    if (roleId === 'some guid') {
      return {
        usersWithRole: 5,
      };
    } else if (roleId === 'some other guid') {
      return {
        usersWithRole: 0,
      };
    } else {
      return {
        usersWithRole: 100,
        usersWithNoRoleAfterDelete: 4,
      };
    }
  }

  async setUserSetting(key, value) {
    const response = await this.session.request(`users/settings/${key}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ value }),
    });
    const { settings } = response;
    this.session.settings = settings;
    return response;
  }

  setVisibleState({ tenantId, ...visibilityData }) {
    return this.session.request(`tenants/${tenantId}/visibility`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(visibilityData),
    });
  }

  similarTitles(title) {
    return this.session.request(`jobs/title/${title}/similar`, {
      method: 'GET',
      headers,
    });
  }

  skillsByTitleId(id) {
    return this.session.request(`jobs/title/${id}/skills`, {
      method: 'GET',
      headers,
    });
  }

  async toggleMyListActivity(activityId) {
    const response = await this.session.request(`activities/my-list/${activityId}`, {
      method: 'POST',
      headers,
    });
    this.session.user.settings = response.settings;
    return response;
  }

  async updateTenantImage(tenantId, files) {
    return await this.session.request(`tenants/logo/${tenantId}`, {
      method: 'POST',
      headers: {},
      body: files,
    });
  }

  updateTenantPackage(tenantId, packageName, visibleStreamIds) {
    return this.session.request(`tenants/${tenantId}/visibility/package`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ packageName, visibleStreamIds }),
    });
  }

  updateBudget(tenant) {
    return this.session.request(`tenants/${tenant.id}/budget`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ tenant }),
    });
  }

  updateAttributeRequirements(tenant) {
    return this.session.request(`tenants/${tenant.id}/attribute-requirements`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(tenant.attributeRequirements),
    });
  }

  updateGeneral(tenant) {
    return this.session.request(`tenants/${tenant.id}/general`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ tenant }),
    });
  }

  updateCustomAttributes(tenantId, customAttributes) {
    return this.session.request(`tenants/${tenantId}/custom-attributes`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ customAttributes }),
    });
  }

  updateApplicationCustomAttributes(tenantId, applicationCustomAttributes) {
    return this.session.request(`tenants/${tenantId}/application-custom-attributes`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ applicationCustomAttributes }),
    });
  }

  updateApplicationAccountConnection(userApplication, accountConnection) {
    const applicationUuid = userApplication.uuid;
    return this.session.request(`requests/${applicationUuid}/account-connection`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ userApplication, accountConnection }),
    });
  }

  updateCareerExplorerInfo(tenantId, careerExplorerInfo) {
    this.session.request(`tenants/${tenantId}/career-explorer`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(careerExplorerInfo),
    });
  }

  upsertActivity(activity, newProgramCourses = []) {
    let promise;
    if (activity.id) {
      promise = this.session.request(`activities/${activity.id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(activity),
      });
    } else {
      promise = this.session.request('activities', {
        method: 'POST',
        headers,
        body: JSON.stringify({ activity, children: newProgramCourses }),
      });
    }
    return promise;
  }

  upsertTenantSSO(tenantSSO) {
    return this.session.request(`tenants/${tenantSSO.tenantId}/sso`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ tenantSSO }),
    });
  }

  async upsertUser(user) {
    const response = await this.session.request(`users/${user.guid}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ user }),
    });
    this.session.user = response;
    return response;
  }

  async updateAuth0User(auth0UserId, properties) {
    return await this.session.request(`users/auth0/patch/${auth0UserId}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ properties }),
    });
  }

  async resendVerificationEmail(auth0UserId) {
    return this.session.request('users/auth0/resend-verification-email', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ auth0UserId }),
    });
  }

  getAllUsersByTenantId(tenantId, options = { includeRoles: false }) {
    const { includeRoles } = options;
    const url = createUrlWithParams(`users/tenant/${tenantId}`, { includeRoles });
    return this.session.request(url);
  }

  getUserById(userId) {
    return this.session.request(`users/${userId}`);
  }

  async getAllRolesForUser(userId) {
    return await this.session.request(`users/${userId}/roles`, {
      method: 'GET',
      headers,
    });
  }

  async assignARoleToAUser(userId, roleId) {
    return await this.session.request(`users/${userId}/role/${roleId}`, {
      method: 'PUT',
      headers,
    });
  }

  /**
   * Assign and Unassign roles for a user
   * @param {string} userId
   * @param {Array<string>} roles - comma separated list of role ids
   */
  async bulkUpdateRoleUserAssignments(userId, roles) {
    return await this.session.request(`users/${userId}/roles`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ roles }),
    });
  }

  async unassignARoleFromAUser(userId, roleId) {
    return await this.session.request(`users/${userId}/role/${roleId}`, {
      method: 'DELETE',
      headers,
    });
  }
}
