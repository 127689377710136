export default {
  "activity.category.DEIdiversityInclusionAccessibility": "DEI",
  "activity.category.DEIdiversityInclusionAccessibility.subtitle": "Diversity, Inclusion, and Accessibility",
  "activity.category.accounting": "Accounting",
  "activity.category.accounting.subtitle": "Finance, Audit, and Risk Management",
  "activity.category.advancedManufacturing": "Advanced Manufacturing",
  "activity.category.agricultureTechnology": "Agriculture Technology",
  "activity.category.appliedLearning": "Applied Learning",
  "activity.category.appliedLearning.subtitle": "Pharmacy Technicians, Opticians, and more",
  "activity.category.biomanufacturing": "Biomanufacturing",
  "activity.category.businessOperations": "Business Operations",
  "activity.category.businessOperations.subtitle": "Process Improvement, Agile, and Scrum",
  "activity.category.businessStrategyInnovationAdministration": "Business",
  "activity.category.businessStrategyInnovationAdministration.subtitle": "Strategy, Innovation, and Business Administration",
  "activity.category.cleanTechnology": "Clean Technology",
  "activity.category.cloudComputingProgramming": "Cloud Computing",
  "activity.category.cloudComputingProgramming.subtitle": "Cloud Essentials, Cloud Operations, and Architecture",
  "activity.category.communicationConfidenceInfluenceResolution": "Communication",
  "activity.category.communicationConfidenceInfluenceResolution.subtitle": "Professional Confidence, Influence, and Conflict Resolution",
  "activity.category.constructionManagement": "Construction Management",
  "activity.category.constructionManagement.subtitle": "Construction Contracts, Estimating, and Strategy",
  "activity.category.cybersecurity": "Cybersecurity",
  "activity.category.dataAnalytics": "Data Analytics",
  "activity.category.dataAnalytics.subtitle": "PowerBI, Excel, Data Analysis, and Visualization",
  "activity.category.dataScience": "Data Science",
  "activity.category.dataScience.subtitle": "Data Modeling, Data Engineering, and Data Programming",
  "activity.category.design": "Design",
  "activity.category.design.subtitle": "Creative, Graphics, and UX/UI Design",
  "activity.category.digitalSkills": "Digital Skills",
  "activity.category.digitalSkills.subtitle": "Microsoft Office, PowerPoint, and MS365",
  "activity.category.digitalTechnology": "Digital Technology",
  "activity.category.emergentTechnologies": "Emergent Technologies",
  "activity.category.emergentTechnologies.subtitle": "Blockchain, Machine Learning, AI, and Metaverse",
  "activity.category.engineeringContinuingEducation": "Engineering",
  "activity.category.engineeringContinuingEducation.subtitle": "Continuing Education for Professional Engineers (P. Eng.)",
  "activity.category.growthAccelerators": "Growth Accelerators",
  "activity.category.growthAccelerators.subtitle": "ESL/FSL, Written Communications, and College Readiness",
  "activity.category.inProgress": "In Progress", // Label for the in progress header for a list of activities
  "activity.category.integratedTechnology": "IT",
  "activity.category.integratedTechnology.subtitle": "Cybersecurity, Information Security, and Information Privacy",
  "activity.category.leadership": "Leadership",
  "activity.category.leadership.subtitle": "Leader Essentials, Coaching, and Emotional Intelligence",
  "activity.category.lifestyle": "Lifestyle",
  "activity.category.lifestyle.subtitle": "Nutrition, Personal Enrichment, and Hobbies",
  "activity.category.management101": "Management 101",
  "activity.category.management101.subtitle": "Team Development, Performance Management, and Managing Change",
  "activity.category.marketing": "Marketing",
  "activity.category.marketing.subtitle": "Digital Marketing, Customer Service, and Product Management",
  "activity.category.myList": "My List",
  "activity.category.peopleCulture": "People and Culture",
  "activity.category.peopleCulture.subtitle": "Talent Acquisition, Compensation, and Employee Relations",
  "activity.category.preConstruction": "Preconstruction",
  "activity.category.preConstruction.subtitle": "BIM, Passive Housing, and Environmental Design",
  "activity.category.projectManagement": "Project Management",
  "activity.category.projectManagement.subtitle": "Exam Preparation, Continuing Education, and Project Management Tools",
  "activity.category.safetyFirst": "Safety First",
  "activity.category.safetyFirst.subtitle": "Occupational Health and Safety, WHMIS, and Workplace Safety",
  "activity.category.softwareDevelopment": "Software Development",
  "activity.category.softwareDevelopment.subtitle": "Databases, Web, and Full Stack Development",
  "activity.category.supplyChainManagement": "Supply Chain Management",
  "activity.category.supplyChainManagement.subtitle": "Logistics, Global Trade, and Lean Six Sigma Practices",
  "activity.category.sustainability": "Sustainability",
  "activity.category.sustainability.subtitle": "Sustainable Management, Practices, and Corporate Social Responsibility",
  "activity.category.wellness": "Wellness",
  "activity.category.wellness.subtitle": "Digital Wellness, Emotional Wellbeing, and Work-Life Balance",
  "activity.category.writtenCommunication": "Written Communication",
  "activity.category.writtenCommunication.subtitle": "Business Communication, Technical Writing, and Language Learning",
  "activity.certificateType.certificate": "Certificate",
  "activity.certificateType.course": "Course",
  "activity.certificateType.degree": "Degree",
  "activity.certificateType.diploma": "Diploma",
  "activity.certificateType.microcredential": "Microcredential",
  "activity.certificateType.online": "Online",
  "activity.contentFormats.readings": "Readings",
  "activity.contentFormats.slides": "Slides",
  "activity.contentFormats.videos": "Videos",
  "activity.delivery.blended": "Blended",
  "activity.delivery.inPerson": "In-person",
  "activity.delivery.inPersonLocationRestricted": "In-person (location radius)",
  "activity.delivery.online": "Online",
  "activity.duration.lessThanThreeMonths": "< 3 months",
  "activity.duration.oneToTwoYears": "1-2 years",
  "activity.duration.sixToTwelveMonths": "6-12 months",
  "activity.duration.threeToSixMonths": "3-6 months",
  "activity.duration.twoPlusYears": "2+ years",
  "activity.feedbackTypes.assignments": "Assignments",
  "activity.feedbackTypes.discussions": "Discussions",
  "activity.feedbackTypes.instructorReviews": "Instructor reviews",
  "activity.feedbackTypes.peerReviews": "Peer reviews",
  "activity.feedbackTypes.quizzes": "Quizzes",
  "activity.format.instructorLed": "Instructor-led",
  "activity.format.selfPaced": "Self-paced",
  "activity.instructionLang.en": "English",
  "activity.instructionLang.es": "Spanish",
  "activity.instructionLang.fr": "French (Canada)",
  "activity.project.false": "No",
  "activity.project.true": "Yes",
  "activity.skills.ariaLabel.exactlyOne": "Skill: {skillName}", // List of skills read by screen readers when there is exactly one skills associated with an activity
  "activity.skills.ariaLabel.exactlyTwo": "Skills: {skillName} and 1 more skill", // List of skills read by screen readers when there are exactly two skills associated with an activity
  "activity.skills.ariaLabel.threePlus": "Skills: {skillName} and {numMoreSkills} more skills", // List of skills read by screen readers when there are three or more skilsl associated with an activity
  "activity.startDate.anytime": "Anytime",
  "activity.startDate.comingSoon": "Coming soon",
  "activity.startDate.notApplicable": "N/A",
  "activity.startDate.notScheduled": "Not scheduled",
  "activity.startDateStatus.startsNextMonth": "Starts Next Month", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsNextWeek": "Starts Next Week", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsThisMonth": "Starts This Month", // Used to indicate when the next session of an activity starts
  "activity.startDateStatus.startsThisWeek": "Starts This Week", // Used to indicate when the next session of an activity starts
  "activity.status.newArrival": "New Arrival", // Used to indicate that an activity is a new arrival
  "activity.status.trending": "Trending", // Used to indicate that an activity is trending
  "activity.type.course": "Course",
  "activity.type.program": "Program",
  "activity.type.program.completionCount": "courses completed", // Text for describing number of courses completed (beside a fraction) ex: 5/8 courses completed
  "activity.weeklyTimeCommitment.fiveToTen": "5-10 hrs / week",
  "activity.weeklyTimeCommitment.lessThanFive": "< 5 hrs / week",
  "activity.weeklyTimeCommitment.tenToTwenty": "10-20 hrs / week",
  "activity.weeklyTimeCommitment.twentyPlus": "20+ hrs / week",

  "activity-features.certificateType": "Certificate type", // Alt-text describing the value for the program/course type of certificate earned
  "activity-features.coursesRequired": "Courses required", // Alt-text describing the value for the program/course number of required courses
  "activity-features.delivery": "Delivery", // Alt-text describing the value for the program/course delivery type (i.e online or in-person)
  "activity-features.duration": "Duration", // Alt-text describing the value for the program/course duration
  "activity-features.format": "Format", // Alt-text describing the value for the program/course format
  "activity-features.numberOfCourses": "{numberOfCourses} course(s)", // The number of courses reqruied for this program
  "activity-features.timeCommitment": "Time commitment", // Alt-text describing the value for the program/course time commitment

  "activity-fees.activityCost": "{type} price", // label for the cost of the program/course
  "activity-fees.approvedAmount": "Approved amount", // The label for the amount that has been approved for this program/course
  "activity-fees.balanceBeforeApproval": "Balance before approval", // The label for the benefit balance before approval
  "activity-fees.balanceWithApproval": "Balance with approval", // label for the balance of your benefit with the approval
  "activity-fees.billedDirectly": "If approved, the fees will be billed directly to {companyName}. You will only pay for any costs above your coverage.", // Supplementary note to explain when a user has to pay in a direct billing scenario
  "activity-fees.caseByCaseReimbursementMessage": "If you already expensed this through your company, contact {programSponsor} for next steps.", // The reimbursement message shown when a company has case-by-case budget setting enabled
  "activity-fees.coursePrices": "Course Price(s)", // Related number field displays price range of courses associated with program being displayed (pluralization option retained if possible)
  "activity-fees.coverage.employee": "Employer Coverage", // Label for line item showing how much financial coverage the employer will provide
  "activity-fees.coverage.jobseeker": "Available Coverage", // Label for line item showing how much financial coverage the organization will provide
  "activity-fees.coverage.member": "Available Coverage", // Label for line item showing how much financial coverage the association will provide
  "activity-fees.coverage.tooltip.description.employee": "This institution charges in {providerCurrency}. Your employer coverage will be applied at checkout.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.description.jobseeker": "This institution charges in {providerCurrency}. Your organization coverage will be applied at checkout.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.description.member": "This institution charges in {providerCurrency}. Your association coverage will be applied at checkout.", // Description for currency conversion tooltip in activity-fees
  "activity-fees.coverage.tooltip.target": "Converts to {coverage}", // Title for currency conversion tooltip in activity-fees
  "activity-fees.discountCodeAmount": "Discount Code Amount", // The label for the amount that the discount code is good for.
  "activity-fees.employee.cost": "Cost to Employee", // Label for the amount the employee will pay for a program/course
  "activity-fees.header": "Financial Summary", // The header for the activity fees card
  "activity-fees.howToPay.header": "How do I pay?", // Header for supplementary section where additional instructions on how to pay is provided
  "activity-fees.jobseeker.cost": "Cost to Jobseeker", // Label for the amount the jobseeker will pay for a program/course
  "activity-fees.member.cost": "Cost to Member", // Label for the amount the member will pay for a program/course (association tenants)
  "activity-fees.outOfPocket": "If approved, you will pay the total cost when you register, then submit the expense to {companyName}. To claim your subsidy, expense the fee as outlined in your employer policy.", // Supplementary information to explain how to pay in a "out of pocket" scenario
  "activity-fees.overallCost": "Overall Price", // label for the overall cost of the program/course
  "activity-fees.programOrCourseCost": "Program/Course Price", // label for the cost of the program/course
  "activity-fees.refundAmount": "{refundPercent}% of what {employerName} paid will be restored to the employer coverage.", // Refund amount message
  "activity-fees.reimbursmentMessage": "If you were already reimbursed by your company, take any necessary steps according to your company policies or contact {programSponsor}.", // The reimbursment message shown when a company required reimbursment
  "activity-fees.stripeRefundAmount": "Refund issued for {refundPercent}%.", // Stripe refund amount message
  "activity-fees.submitOwnCost": "Price", // label for cost for submit own requests
  "activity-fees.taxCalculatedNext": "Sales tax calculated at next step", // Message informing user the tax will be calcuated on the next page
  "activity-fees.taxIncluded": "Sales tax included", // Message informing user the tax is already included in the amount displayed
  "activity-fees.taxWillApply": "Sales tax will apply", // Message informing user they will have to pay taxes
  "activity-fees.yourProgramSponsor": "your program sponsor", // Displayed if the name of your program sponsnor is unkonwn

  "activity-filter.clearFilters": "Clear Filters", // Button label for clear filters button.
  "activity-filter.dialog.close": "Close", // The filter by skill button label
  "activity-filter.filterBy": "Filter by", // Label to proceed filter bar interactions
  "activity-filter.filterType.activeState.ariaLabel": "Active State", // Aria text for active state filter dropdown
  "activity-filter.filterType.activeState.displayText": "State", // Label for active state filter dropdown
  "activity-filter.filterType.careerType.ariaLabel": "Career", // Career type value for careers
  "activity-filter.filterType.careerType.displayText": "Career", // Career type value for careers
  "activity-filter.filterType.careerType.introText": "Choose an option that best describes the skillset you want to build", // Text displayed at the top of a career selector, introducing what it contains
  "activity-filter.filterType.certificationType.ariaLabel": "Certification Type", // Aria text for certification type filter dropdown
  "activity-filter.filterType.certificationType.certificate": "Certificate", // Certification type value for certificates
  "activity-filter.filterType.certificationType.course": "Course", // Certification type value for courses
  "activity-filter.filterType.certificationType.degree": "Degree", // Certification type value for degrees
  "activity-filter.filterType.certificationType.diploma": "Diploma", // Certification type value for diplomas
  "activity-filter.filterType.certificationType.displayText": "Type", // Label for certification type filter dropdown
  "activity-filter.filterType.certificationType.microcredential": "Microcredential", // Certification type value for microcredentials
  "activity-filter.filterType.delivery.ariaLabel": "Online or In-person", // Aria text for delivery filter dropdown
  "activity-filter.filterType.delivery.blended": "Blended", // Delivery type value for blended
  "activity-filter.filterType.delivery.displayText": "Delivery", // Label for delivery filter dropdown
  "activity-filter.filterType.delivery.inPerson": "In-person", // Delivery type value for in person
  "activity-filter.filterType.delivery.online": "Online", // Delivery type value for online
  "activity-filter.filterType.duration.ariaLabel": "Program Duration", // Aria text for program duration filter dropdown
  "activity-filter.filterType.duration.displayText": "Duration", // Label for program duration filter dropdown
  "activity-filter.filterType.duration.lessThanThreeMonths": "< 3 months", // Duration value for less than three months
  "activity-filter.filterType.duration.oneToTwoYears": "1-2 years", // Duration value for 1 to 2 years
  "activity-filter.filterType.duration.sixToTwelveMonths": "6-12 months", // Duration value for 6 to 12 months
  "activity-filter.filterType.duration.threeToSixMonths": "3-6 months", // Duration value for three to six months
  "activity-filter.filterType.duration.twoPlusYears": "2+ years", // Duration value for more than 2 years
  "activity-filter.filterType.format.ariaLabel": "Format of content delivery pacing", // Aria for format dropdown label
  "activity-filter.filterType.format.displayText": "Format", // Display text for content delivery format (is either instructor led or self-paced)
  "activity-filter.filterType.format.instructorLed": "Instructor-led", // Content pacing description when activity is led by an academic provider employee
  "activity-filter.filterType.format.selfPaced": "Self-paced", // Content pacing description when activity is led by the individual taking the course
  "activity-filter.filterType.instructionLang.ariaLabel": "Base language used by instructor and coursework", // Aria for instruction language dropdown label
  "activity-filter.filterType.instructionLang.displayText": "Language", // Display text for instruction language
  "activity-filter.filterType.instructionLang.en": "English", // Filter label to display courses provided in english
  "activity-filter.filterType.instructionLang.fr": "French (Canada)", // Filter label to display french canadian courses
  "activity-filter.filterType.skillDialog.ariaLabel": "Skill", // The header for the skills filter dialog
  "activity-filter.filterType.skillDialog.displayText": "Skill", // The header for the skills filter dialog
  "activity-filter.filterType.weeklyTimeCommitment.ariaLabel": "Weekly Time Commitment", // Aria text for weekly time commitment filter dropdown
  "activity-filter.filterType.weeklyTimeCommitment.displayText": "Hours/week", // Label for weekly time commitment filter dropdown
  "activity-filter.filterType.weeklyTimeCommitment.fiveToTen": "5-10 hrs / week", // Weekly time commitment value of five to ten hours a week
  "activity-filter.filterType.weeklyTimeCommitment.lessThanFive": "< 5 hrs / week", // Weekly time commitment value of less than five hours a week
  "activity-filter.filterType.weeklyTimeCommitment.tenToTwenty": "10-20 hrs / week", // Weekly time commitment value of ten to twenty a week
  "activity-filter.filterType.weeklyTimeCommitment.twentyPlus": "20+ hrs / week", // Weekly time commitment value of more than twenty hours a week
  "activity-filter.mobile.filterBySkill": "Filter by skill", // The filter by skill button label
  "activity-filter.mobile.filters": "Filters", // The filters mobile dialog header
  "activity-filter.mobile.otherFilters": "Other filters", // The other filters button label

  "activity-import.documentTitle.stream": "Stream Import",

  "activity-list.nextPage.ariaLabel": "Next Programs", // The aria label for the next page button
  "activity-list.previousPage.ariaLabel": "Previous Programs", // The aria label for the previous page button
  "activity-list.viewAll": "View all ({numberOfActivities})", // The text for the link to view all programs in a category (e.g. "Project Management, Scoping and Planning", "Diversity, Inclusion and Accessibility", etc.). Note that the "category" text is not shown, and only announced by screenreaders in the associated "activity-list.viewAll.ariaLabel".
  "activity-list.viewAll.ariaLabel": "View all {category} ({numberOfActivities})", // The accessibility text for the link to view all programs in a category (e.g. "Project Management, Scoping and Planning", "Diversity, Inclusion and Accessibility", etc.).

  "activity-relationships.completeElectives": "Complete {numberOfElectives} elective course(s)", // The text specifying how many electives need to be completed.
  "activity-relationships.relatedProgramsHeader": "Part of the Following Program(s)", // The text for related relationships header
  "activity-relationships.requiredCourses": "Required Courses", // The text for required courses header

  "activity-skill-tagger.mainLabel": "Skill Search", // Label displayed above search input
  "activity-skill-tagger.noResults": "No results", // Displayed after searching if there are no results found
  "activity-skill-tagger.resultsPlaceholder": "Results will appear here", // Displayed before uses searches for anything
  "activity-skill-tagger.searching": "Searching...", // Displayed when searching is in progress

  "activity-small.notScheduledDescription": "This course will become available when the next session is scheduled", // Alert and tooltip informing about an unscheduled course

  "activity-table.confirmation.cancelText": "It will also cancel {applicationsLength} application(s).", // Text for confirmation dialogue box when actitivity is disabled to show how many applications will be cancelled
  "activity-table.confirmation.disabledText": "Disabling this activity will hide it from all tenants. {cancelText}", // Text for confirmation dialogue box when actitivity is disabled
  "activity-table.confirmation.enableText": "Enabling this activity will make it visible to all tenants", // Text for confirmation dialogue box when actitivity is enabled
  "activity-table.confirmation.header": "Are you sure?", // Header for confirmation dialogue box

  "activity-table.exportDialog.exporting": "Exporting...", // Text for exporting animation
  "activity-table.exportDialog.text": "This will export the activities with the applied filter to a CSV file. Please select the attributes you would like to export below.", // Text for export dialog template
  "activity-table.exportDialog.title": "Export Activities to CSV", // Header for export dialog template

  "activity-table.header.active": "Active", // Label for table header for active toggle
  "activity-table.header.active.tooltip": "Whether this activity is shown in catalog", // Tooltip for table header for active toggle
  "activity-table.header.export": "Export", // Label for stream export button on activities page and in activity page dialogue, and export dialog in the admin section
  "activity-table.header.import": "Import", // Label for stream import button on activities page in the admin section
  "activity-table.header.provider": "Provider", // Label for table header for provider
  "activity-table.header.req": "Req", // Label for table header for whether the activity is requestable
  "activity-table.header.req.tooltip": "Whether the activity is requestable", // Tooltip for req table header
  "activity-table.header.tax": "Tax", // Label for table header for whether the activity is taxable
  "activity-table.header.tax.tooltip": "Whether the activity is taxable", // Tooltip for tax table header
  "activity-table.header.title": "Title", // Label for table header for title
  "activity-table.header.type": "Type", // Label for table header for type

  "add-role.description": "Create a new role here. Use broad and granular permissions to set up the role with the correct specific permissions for admin access.", // Description for the add-role page
  "add-role.title": "Add Role", // Title for the add-role page

  "admin.custom-terms-of-use.alert.disabled": "Custom checkbox disabled", // Alert text which appears when the custom term of use checkbox is disabled
  "admin.custom-terms-of-use.alert.enabled": "Custom checkbox enabled", // Alert text which appears when the custom term of use checkbox is enabled
  "admin.custom-terms-of-use.explanation": "When employees submit requests, they must agree to the terms of use by selecting a series of checkboxes. Here you can create a <bold>custom checkbox</bold> unique to {employerName} that will be presented to employees <bold>each time they submit a request</bold>.", // Introductory text explaining to admins how to use the page
  "admin.custom-terms-of-use.heading": "Add Custom Employee Terms of Use", // Heading for a page that allows admins to create custom terms of use for when people create requests to take courses or programs
  "admin.custom-terms-of-use.input.enable-label": "Enable custom checkbox. Changes will not appear unless saved.", // Label for a checkbox which enables custom terms of use
  "admin.custom-terms-of-use.input.instructions": "Enter custom checkbox text", // Label for an input box that allows users to input some custom language to display on the create request page
  "admin.custom-terms-of-use.input.instructions.limit": "(limit of 600 characters)", // Explains the character limit of the input box
  "admin.custom-terms-of-use.input.instructions.placeholder": "Enter your text", // Placeholder text that appears in the custom term of use before the user has input anything
  "admin.custom-terms-of-use.language.intro": "Select a language to add a localized version of your custom checkbox text.", // Introductory text explaining the purpose of the language selector
  "admin.custom-terms-of-use.optional-statement": "This is optional, and not intended to replace your company policy.", // Explanatory text stating that custom terms of use are optional
  "admin.custom-terms-of-use.preview.heading": "How this will appear to employees:", // Heading for the preview of what the user-entered custom text will look like
  "admin.custom-terms-of-use.preview.text": "Your custom checkbox text will appear here", // Placeholder text for the custom checkbox preview that appears if no custom text has been input

  "admin.tax-details.rates.copied": "Tax rates copied to your clipboard", // The label for copying tax rates for toast
  "admin.tax-nexus.add-registration.button": "Add Registration", // The label for the add registration button
  "admin.tax-nexus.other-registrations": "Select if there are other registrations", // Input label for province/state registration selection
  "admin.tax-nexus.registration-country.label": "Registration Country", // The label for the registration country dropdown
  "admin.tax-nexus.tax-registration.label": "Tax Registrations", // The label for the tax registration input,
  "admin.transaction-details.strip-session-id.copied": "Stripe session ID copied to your clipboard", // The Label for copied toast on stripe session id

  "admission.requirements.contactEmailHelpCard": "Have questions about admissions? Send an email to ", // alert user that they may reach out to the education facility's admission contact person for assistance
  "admission.requirements.hasCostAndDelay": "An additional fee of approximately {cost} may apply and this may take up to {delay, plural, one {# week} other {# weeks}} to process.", // lets the learner know a cost and delay is associated with this admission requirement
  "admission.requirements.hasCostOnly": "An additional fee of approximately {cost} may apply.", // lets the learner know that a cost is associated with this admission requirement
  "admission.requirements.hasDelayOnly": "This may take up to {delay, plural, one {# week} other {# weeks}} to process.", // lets the learner know that a delay (weeks) is associate with this admission requirement
  "admission.requirements.internationalAnnouncement.applicant": "You need to prepare additional documents if you have a transcript from an international high school:", // alerts the applicant that there are additional requirements if they have an international degree
  "admission.requirements.internationalAnnouncement.not.applicant": "The applicant will need to prepare additional documents if they have a transcript from an international high school:", // alerts reader that the applicant will have additional requirements if they have an international degree

  "admission-requirements.review.documentTitle": "Review Requirements - SkillsWave", // Browser-document title for view where program requirements are viewed by user
  "admission-requirements.review.introduction.applicant": "Just a reminder, {activity} has admission requirements. If you are approved to take this program, here’s what you’ll need to prepare.", // additional text that lets the user know that this specific program has requirements
  "admission-requirements.review.introduction.not.applicant": "Just a reminder, {activity} has admission requirements. If the applicant is approved to take this program, here’s what they’ll need to prepare.", // additional text that lets the user know that this specific program has requirements
  "admission-requirements.review.navigation.cancel": "Go back", // Text on navigation button that user will press if they'd like to navigate back one page in the workflow
  "admission-requirements.review.navigation.submit": "I understand, review my request", // Button text that user will select if they'd like to proceed to the next step in the workflow where they review their request for final submission
  "admission-requirements.review.title.applicant": "If you’re approved, here’s what you’ll need", // Title for "program requirements" page, letting applicants know they'll need to satisfy the below requirements
  "admission-requirements.review.title.not.applicant": "If the applicant is approved, here’s what they will need", // Lets approver/admin/etc. know that the applicant will need to satisfy the below requirements

  "app-footer.help.link": "Contact support", // Part 2 of sentence for 'contact-us' page - This portion is the actual hyperlink [internal link]
  "app-footer.help.text": "Need a hand?", // Part 1 of sentence for link to 'contact-us' page - User will be taken to support/contact-us page [internal link]
  "app-footer.policy.link": "program policy", // Part 2 of sentence for program policy link  - This portion is the actual hyperlink [external link]
  "app-footer.policy.text": "View the", // Part 1 of sentence for program policy link - User will be taken to their company's program policy page [external link]
  "app-footer.trademark.text": "© 2024 SkillsWave Corporation. All rights reserved", // Do not translate "SkillsWave Corporation".

  "app-header.about": "About", // Navigation bar link, user will be taken to the How it Works page, also ariaLabel for tenant-logo/homepage route
  "app-header.analytics": "Analytics", // Navigation bar link, user will be taken to analytics page (privileged users only)
  "app-header.bookmarks": "Bookmarks", // Navigation bar link, employee user will be able to view bookmarked programs/courses
  "app-header.changeLanguage": "Change Language", // Navigation bar link, user will be able to choose language the app will be in
  "app-header.editRole": "Edit Role", // Navigation bar link, user will be able relaunch onboarding
  "app-header.home": "Home", // Navigation bar link, user will be taken to the home page
  "app-header.howItWorks": "How it works", // Navigation bar link, user will be taken to the How it Works page, also ariaLabel for tenant-logo/homepage route
  "app-header.logo.altText": "{tenantName} logo", // Alt text for image of tenant's logo (tenant being an employer or provider)
  "app-header.logo.ariaLabel": "Go back to home", // ariaLabel for tenant logo link (brings user back to hompage of Wave)
  "app-header.logout": "Log Out", // Navigation bar link, user will be logged out
  "app-header.navigation.exit": "Exit Navigation Menu", // Exit navigation popout dialog on narrow width viewports
  "app-header.navigation.open": "Open Navigation Menu", // Opens navigation popout dialog on narrow width viewports
  "app-header.programs": "Catalog", // Navigation bar link, user will be taken to activity catalogue
  "app-header.recommended": "Recommended", // Navigation bar link, user will be taken to the home page
  "app-header.requests": "Requests", // Navigation bar link, user will be taken to requests-table view

  "app-nova.catchAllErrorMessage": "Please try again. If the problem persists, please contact support.", // The catch all error message for when an unknown error occurs
  "app-nova.documentTitle": "SkillsWave", // Do not translate "SkillsWave".
  "app-nova.marketing.callToAction.button": "Get started today!", // The label that goes on the marketing call to action button
  "app-nova.marketing.callToAction.header": "Discover a better way to unleash <nobreak>employee potential</nobreak>", // The message that goes at the top of all pages when marketing is turned on.
  "app-nova.marketing.dialog.step.intro.getStartedButton": "Get started today!", // The button that will trigger an email to marketing.
  "app-nova.marketing.dialog.step.intro.header": "Build the skills you need with the talent you already have.", // The header is shown in the marketing popup when an action is made.
  "app-nova.marketing.dialog.step.intro.message": "Contact us to schedule a demo or get started.", // The message is shown in the marketing popup when an action is made.
  "app-nova.marketing.dialog.step.submit.header": "We’ll be right with you!", // The header is shown in the marketing popup when a message has been sent ot the marketing team
  "app-nova.marketing.dialog.step.submit.message": "Thanks for submitting your request. A SkillsWave representative will contact you within 1-2 business days to help you get started.", // The message is shown in the marketing popup when a message has been sent to the marketing team. Do not translate "SkillsWave".
  "app-nova.skipToMain": "Skip to main content", // label for the skip to main content button used for accessibility

  "application.cancelReason.ACTIVITY_NOT_OFFERED": "This course/program is no longer available.", // Informs the user that this application is canceled because the activity is no longer offered

  "application.status.approved": "Approved",
  "application.status.cancelled": "Cancelled",
  "application.status.completed": "Completed",
  "application.status.declined": "Declined",
  "application.status.failed": "Failed",
  "application.status.oneOfTwoApproved": "1 of 2 Approved",
  "application.status.paid": "Paid",
  "application.status.pending": "Pending",
  "application.status.registered": "Registered",
  "application.status.registrationSubmitted": "Registration Started",
  "application.status.withdrew": "Withdrew",

  "application-call-to-action.dialog.cancelled.reason.label": "Reason", // Label for reason input field
  "application-call-to-action.dialog.cancelled.reasonInfo.1": "Reason:", // Info text above the reason input field for cancelled status confirmation dialog
  "application-call-to-action.dialog.cancelled.reasonInfo.2": "This will be provided to the learner.", // Info text above the reason input field for cancelled status confirmation dialog
  "application-call-to-action.dialog.refundBreakdown": "Refund breakdown:", // Text title for refund breakdown
  "application-call-to-action.dialog.refundMessageForProvider.1": "{refundPercentage}% ({refundAmountFormatted}) will be refunded to the learner", // Refund message for provider line 1
  "application-call-to-action.dialog.refundMessageForProvider.2": "{retainPercentage}% ({retainAmountFormatted}) will be retained by your institution", // Refund message provider line 2
  "application-call-to-action.discountCode.save.success": "Your changes have been saved", // alert message for successfully saving discount code
  "application-call-to-action.input.completionDate.label": "Completion Date", // Label for completion date input
  "application-call-to-action.input.completionStatus.label": "Completion Status", // Label for completion status input
  "application-call-to-action.input.discountCode.label": "Discount Code", // Label for discount code input
  "application-call-to-action.input.enrollmentDate.label": "Enrollment Date", // Label for enrollment date input
  "application-call-to-action.input.refund.label": "Refund", // Label for refund input
  "application-call-to-action.input.refund.tooltip": "Refund to Learner", // Tooltip for refund input
  "application-call-to-action.input.refund.validLabel": "a valid Refund", // Label for refund input
  "application-call-to-action.providerForm.error.completionBeforeEnrollment": "Completion date must be on or after enrollment date", // Error msg to display if completion date is before the enrollment date
  "application-call-to-action.providerForm.error.empty.completionDate": "You need a completion date", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.empty.discountCode": "You need a discount code",
  "application-call-to-action.providerForm.error.empty.enrollmentDate": "You need an enrollment date", // Error msg for missing enrollment date
  "application-call-to-action.providerForm.error.empty.invalid_refund": "You need a valid refund amount if the course is cancelled", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.empty.refund": "You need a refund amount if the course is cancelled", // Error msg for missing completion date
  "application-call-to-action.providerForm.error.template": "You need", // Error msg template"
  "application-call-to-action.providerForm.error.template.and": "and", // Error msg separator
  "application-call-to-action.status.cancelled": "Cancelled", // Cancelled application status
  "application-call-to-action.status.fail": "Fail", // Failed application status
  "application-call-to-action.status.pass": "Pass", // Passed application status
  "application-call-to-action.status.pending": "Pending", // Pending application status
  "application-call-to-action.status.withdrawn": "Withdrawn", // Withdrawn application status
  "application-call-to-action.template.approver.firstApprover": "{ role, select, sponsor {The sponsor} manager {The manager} other {Another approver} } still needs to review this request. We’ll keep you posted when we have more information.", // Status bar message to show first approver after first request approval.
  "application-call-to-action.template.approver.firstApprover.heading": "You have approved this request", // heading for first approver
  "application-call-to-action.template.approver.secondApprover": "{ role, select, sponsor {The sponsor} manager {The manager} other {Another approver} } approved this request", // Message to show second approver after first request approval, stating that the manager has already approved the request.
  "application-call-to-action.template.provider.buttonSave": "Save", // Button text for saving provider call-to-action input
  "application-call-to-action.template.requester.accountConnection.button": "Begin", // Button will navigate learner to workflow where they will create a profile
  "application-call-to-action.template.requester.accountConnection.heading": "You’re approved! Let's submit your registration.", // Approval celebration text displayed when application is approved - but learner still needs to make a profile
  "application-call-to-action.template.requester.accountConnection.heading.admissionBased": "You’re approved! Begin next steps for admission and registration.", // Approval celebration text displayed when application is approved - but learner still needs to make a profile on an admission based activity
  "application-call-to-action.template.requester.accountConnection.heading.isPaymentHandledByProvider": "You’re approved! Begin next steps to register with {providerName}.", // Approval celebration text displayed when application is approved - but learners activity is paid for by provider
  "application-call-to-action.template.requester.accountConnection.text": "Your part should take 10 minutes. Then {providerName} will take approximately {registrationTime} business days to register you.", // Supplimentary information regarding registration of learner account with provider
  "application-call-to-action.template.requester.accountConnection.text.admissionBased": "Once these steps are complete, {providerName} will take approximately {registrationTime} business days to contact you and begin the admission and registration process.",
  "application-call-to-action.template.requester.approved.registrationClosed.bodyText": "Once the next session is scheduled, you will be able to register.", // Supplementary info displayed when user is approved but registration to a course is closed
  "application-call-to-action.template.requester.approved.registrationClosed.heading": "You’re approved, but registration has closed.", // Heading of a call to action that is displayed when user is approved but registration to a course is closed
  "application-call-to-action.template.requester.approvedOrRegistered.discountCode.copy": "Copy Code", // Aria-label for copying discount code button
  "application-call-to-action.template.requester.approvedOrRegistered.discountCode.label": "Discount Code", // Label beside discount code
  "application-call-to-action.template.requester.approvedOrRegistered.heading": "You’re approved! Begin next steps to register.", // Heading to show requesters when application is approved or registered
  "application-call-to-action.template.requester.approvedOrRegistered.heading.admissionBased": "You’re approved! Begin next steps for admission and registration.", // Heading to show requesters when application is approved or registered on an admission based activity
  "application-call-to-action.template.requester.approvedOrRegistered.heading.discountCode": "Congrats, you’re approved! But don’t forget your discount code.", // Heading to show requesters when application is approved or registered (supports discount codes)
  "application-call-to-action.template.requester.approvedOrRegistered.pendingDiscountCode": "Waiting for discount code to register", // Message to show requesters when application is pending discount code (approved or registered status)
  "application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label": "Register", // Button text for register button
  "application-call-to-action.template.requester.approvedOrRegistered.registerButton.stripe.label.admissionBased": "Begin Next Steps", // Button text for register button
  "application-call-to-action.template.requester.approvedOrRegistered.text": "Proceed to the {providerName} registration page to register!", // Text to show requesters when application is approved or registered
  "application-call-to-action.template.requester.approvedOrRegistered.text.discountCode": "Use this discount code at checkout when you register for your course with {providerName}.", // Text to show requesters when application is approved or registered (supports discount codes)
  "application-call-to-action.template.requester.approvedOrRegistered.text.stripe": "Once your steps are complete, {providerName} will take approximately {registrationTime} business days to register you.", // Text to show requesters when application is approved or registered (stripe)
  "application-call-to-action.template.requester.approvedOrRegistered.text.stripe.admissionBased": "Once these steps are complete, {providerName} will take approximately {registrationTime} business days to contact you and begin the admission and registration process.", // Text to show requesters when application is approved or registered for admission based activity(stripe)
  "application-call-to-action.template.requester.cancelled": "This program was cancelled by {providerName}, effective {formattedCompletionDate}", // Message to show requesters when application has been cancelled
  "application-call-to-action.template.requester.failed": "Did not complete the requirements needed to pass this program", // Message to show requesters when they failed the program/course they applied for
  "application-call-to-action.template.requester.oneOfTwoApproved.role.manager": "The manager still needs to review this request. We’ll keep you posted when we have more information.", // Message to show requester when request is approved by the manager in hybrid approval model
  "application-call-to-action.template.requester.oneOfTwoApproved.role.sponsor": "The sponsor still needs to review this request. We’ll keep you posted when we have more information.", // Message to show requester when request is approved by the sponsor in hybrid approval model
  "application-call-to-action.template.requester.paid.heading": "{providerName} is registering you", // Heading to show requesters when application is paid
  "application-call-to-action.template.requester.paid.heading.isPaymentHandledByProvider": "Apply and register for this program on the <redirectLink>{providerName} website</redirectLink>", // Heading to show requesters when application is paid for by provider
  "application-call-to-action.template.requester.paid.text": "Once the registration window opens, you’ll be registered in approximately {registrationTime} business days. {institution} will contact you to confirm your registration at: {email}. Contact <link>SkillsWave Support</link> if you have any questions.", // Text to show requesters when application is paid. Do not translate "SkillsWave".
  "application-call-to-action.template.requester.paid.text.isPaymentHandledByProvider": "For questions about your application or the registration process, reach out to <link>{studentSupportEmail}</link>.", // Text to show requesters when application is paid. Do not translate "SkillsWave".
  "application-call-to-action.template.requester.pending": "This request is currently pending approval. We’ll keep you posted when we have more information.", // Message to show requesters when application is pending
  "application-call-to-action.template.requester.withdrawn": "Withdrew from {activityTitle}, effective {formattedCompletionDate}", // Message to show requesters when the application has been withdrawn

  "application-overview.approvalAmount.checkbox.label": "I manage the training budget for {employeeName}", // Label for a confirmation checkbox when authorizing the inputted approval amount
  "application-overview.approvalComment": "Comment", // Label for text field where approver can leave a comment when approving a request
  "application-overview.approvalInfo.amount": "Approved Amount", // Key for the approved amount
  "application-overview.approvalInfo.amount.edit": "Edit", // Button text for editing approval amount
  "application-overview.approvalInfo.amount.edit.cancel": "Cancel", // Button text for cancel editing approval amount
  "application-overview.approvalInfo.amount.edit.label": "Approved amount edit", // Key for editing approval amount
  "application-overview.approvalInfo.amount.pending": "Pending budget holder approval", // Key for pending approval amount
  "application-overview.approvalInfo.amount.save": "Save", // Button text for editing approval amount
  "application-overview.approvalInfo.amount.updated": "Approved amount has been updated", // toast message for updating approval amount
  "application-overview.approvalInfo.description.hybridBudgetHolder": "This request needs to be approved by {employeeName}’s manager and the sponsor. The {budgetHolder} will determine the training budget for this request.", // approval information description for Hybrid Case by Case
  "application-overview.approvalInfo.description.hybridNonBudgetHolder": "This request needs to be approved by {employeeName}’s manager and the sponsor", // approval information description
  "application-overview.approvalInfo.heading": "Approval Information", // Title for approval information
  "application-overview.approvalInfo.hybridCBC.manager": "Manager (budget holder)", // Key for manager name
  "application-overview.approvalInfo.hybridCBC.sponsor": "Sponsor (budget holder)", // Key for sponsor name
  "application-overview.approvalInfo.manager": "Approving Manager", // Key for the name of the approving manager
  "application-overview.approvalInfo.managerComments": "Manager Comments", // Key for the comments of the approving manager
  "application-overview.approvalInfo.maxAllowedCoverage.tooltip": "You cannot approve for more than the total cost", // Tooltip text indicating to the user when the inserted amount is greater than the total cost
  "application-overview.approvalInfo.minAllowedCoverage.tooltip": "You cannot approve for less than $0",
  "application-overview.approvalInfo.percentCoverage.tooltip": "Your organization’s default coverage is {percentCoverage}% of the cost", // Tooltip text indicating to the user what the default number in the 'approved amount' input box is.
  "application-overview.approvalInfo.sponsor": "Approving Sponsor", // Key for the name of the approving sponsor
  "application-overview.approvalInfo.sponsorComments": "Sponsor Comments", // Key for the comments of the approving sponsor
  "application-overview.approveInfo.confirmBudgetHolder": "Confirm you manage the budget for {employeeName}",
  "application-overview.cancelReason": "Cancellation Reason", // Label for text field where requester can give a reason for cancelling a request
  "application-overview.declineReason": "Reason", // Label for text field where approver can give a reason for declining a request
  "application-overview.enrollmentInfo.cancellationReason": "Cancellation Reason", // Key for the program or course's cancellation reason
  "application-overview.enrollmentInfo.completedOn": "Completed on", // Key for the date the user completed the program or course
  "application-overview.enrollmentInfo.completionStatus": "Completion Status", // Key for the user's completion status
  "application-overview.enrollmentInfo.enrolledOn": "Enrolled on", // Key for the date the user enrolled for the program or course
  "application-overview.enrollmentInfo.heading": "Enrollment Information", // Title for enrollment information
  "application-overview.enrollmentInfo.status.admin.revoke": "Cancelled by Administrator", // Cancelled application status for admin initiated cancel
  "application-overview.enrollmentInfo.status.cancelled": "Cancelled by Provider", // Cancelled application status
  "application-overview.enrollmentInfo.status.employee.revoke": "Cancelled by Employee", // Cancelled application status for employee initiated cancel
  "application-overview.enrollmentInfo.status.fail": "Fail", // Failed application status
  "application-overview.enrollmentInfo.status.jobseeker.revoke": "Cancelled by Jobseeker", // Cancelled application status for jobseeker initiated cancel
  "application-overview.enrollmentInfo.status.member.revoke": "Cancelled by Member", // Cancelled application status for member initiated cancel
  "application-overview.enrollmentInfo.status.pass": "Pass", // Passed application status
  "application-overview.enrollmentInfo.status.pending": "Pending", // Pending application status
  "application-overview.enrollmentInfo.status.withdrawn": "Withdrawn", // Withdrawn application status
  "application-overview.existingApplication.header": "{displayName} requested this in the past.", // Title for the re-request alert banner
  "application-overview.existingApplication.status.cancelled": "{displayName} cancelled the request.", // subtext for when the previous application for a user was cancelled
  "application-overview.existingApplication.status.declined": "{displayName} declined the request.", // subtext for when the previous application for a user was declined
  "application-overview.existingApplication.status.declined.noDecliner": "The request was declined.", // subtext for when the previous application for a user was declined and the decliner can't be determined
  "application-overview.existingApplication.status.failed": "{displayName} failed this {type}.", // subtext for when the previous application for a user was failed
  "application-overview.existingApplication.status.withdrawn": "{displayName} withdrew from this {type}.", // subtext for when the previous application for a user was withdrawn
  "application-overview.existingApplication.subtext.link": "View the previous request",
  "application-overview.general.cost": "Cost", // Key for the cost of the program or course requested
  "application-overview.general.country": "Country", // Key for the country of the program or course requested
  "application-overview.general.department": "Department", // key for the department of the user that is requesting the program or course
  "application-overview.general.email": "Email", // Key for the state of province of the user who submitted the request and when they submitted it
  "application-overview.general.nextSession": "Next Session", // Key for the next session of the program or course
  "application-overview.general.requestId": "Request ID", // Key for the request's ID
  "application-overview.general.requestReason": "Request Reason", // Key for the reason given by the user for requesting the program or course
  "application-overview.general.role": "Role", // Key for the user's role within their company
  "application-overview.general.stateOrProvince": "State/Province", // Key for the state of province of the user who submitted the request and when they submitted it
  "application-overview.general.submitted": "Submitted", // Key for the user who submitted the request and when they submitted it
  "application-overview.general.submittedOn": "{displayName} on {applicationDate}", // A connector used within the value for 'Submitted' to join the user who submitted the request and the submission date
  "application-overview.heading": "Request Overview", // Top-level heading for the application overview details
  "application-overview.requestConfirmation.approve": "Approve", // Label for approve button
  "application-overview.requestConfirmation.decline": "Decline", // Label for decline button
  "application-overview.requesterAction.revoke": "Cancel Request", // Label for decline button
  "application-overview.revokeInfo.cancelled": "Your request for {activity} has been cancelled",
  "application-overview.revokeInfo.tooltip": "Your request has already been sent to {provider} for registration. Contact SkillsWave Support for assistance.", // do not translate "SkillsWave"
  "application-overview.status": "Status", // The key used in off-screen text to indicate the request's progress to screen reader users
  "application-overview.wizardLabels.course.step.4": "Course completed", // Step 4 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.employee.step.3": "Employee registered", // Step 3 of 4 in the wizard/progress tracker for an employee request
  "application-overview.wizardLabels.jobseeker.step.3": "Jobseeker registered", // Step 3 of 4 in the wizard/progress tracker for a jobseeker request
  "application-overview.wizardLabels.member.step.3": "Member registered", // Step 3 of 4 in the wizard/progress tracker for a member request
  "application-overview.wizardLabels.program.step.4": "Program completed", // Step 4 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.step.1": "Request received", // Step 1 of 4 in the wizard/progress tracker for an individual request
  "application-overview.wizardLabels.step.2": "Request approved", // Step 2 of 4 in the wizard/progress tracker for an individual request

  "application-table.animationText": "Export Processing", // Label for export button when it is animating
  "application-table.csv.title": "Requests_List_", // Title of csv export; today's date is appended to this title along with the '.csv' file extension
  "application-table.employee.search.label": "Find an ID, employee, program, or course", // The label for the application table search bar
  "application-table.export": "Export Requests", // Label for export button
  "application-table.export.complete": "Export complete", // toast message for export complete
  "application-table.export.error": "Something went wrong. Please try again.", // toast message for export error
  "application-table.export.processing": "Export processing", // toast message for export processing
  "application-table.filter.course.ariaLabel": "Filter by course", // Aria label for course filter
  "application-table.filter.course.label": "Course", // Text for course filter
  "application-table.filter.course.tooltipText": "Filter requests by course or program name", // Tooltip for course filter
  "application-table.filter.employer.ariaLabel": "Filter by employer", // Aria label for employer filter
  "application-table.filter.employer.tooltipText": "Filter requests by employer name", // Tooltip for employer filter
  "application-table.filter.filterBy": "Filter by:", // Label for requests filter
  "application-table.filter.provider.ariaLabel": "Filter by provider", // Aria label for provider filter
  "application-table.filter.provider.smo": "External Request", // Filter name for applications that come from outside the system
  "application-table.filter.provider.tooltipText": "Filter requests by provider name", // Tooltip for provider filter
  "application-table.filter.status.ariaLabel": "Filter by status", // Aria label for status filter
  "application-table.filter.status.label": "Status", // Text for status filter
  "application-table.filter.status.tooltipText": "Filter by the request status, e.g. Approved, Completed.", // Tooltip for status filter
  "application-table.jobseeker.search.label": "Find an ID, jobseeker, program, or course", // The label for the application table search bar
  "application-table.member.search.label": "Find an ID, member, program, or course", // The label for the application table search bar
  "application-table.search.placeholder": "Search requests...", // The plaveholder for the application table search bar
  "application-table.submitOwn": "Submit My Own Request", // Label for submit own request button
  "application-table.table.activity": "Program/Course", // Key for the program or course the user requested
  "application-table.table.employee": "Employee", // Key for the employee's name
  "application-table.table.empty": "There are currently no requests", // Text displayed when there are no requests in the table
  "application-table.table.id": "ID", // Key for the request ID
  "application-table.table.jobseeker": "Jobseeker", // Key for the jobseeker's name
  "application-table.table.member": "Member", // Key for the member's name
  "application-table.table.noResults": "Please check your spelling or try a new search", // No results message when filtered to no results
  "application-table.table.payment": "Payment", // Key for user payment in provider view
  "application-table.table.price": "Price", // Key for program/course price
  "application-table.table.session": "Session", // Key for program or course's next session
  "application-table.table.sortingButtonTitle": "Sort by {header}", // Sorting button by header items
  "application-table.table.status": "Status", // Key for request status
  "application-table.table.submitted": "Submitted", // Key for the date the user submitted the request

  "application-table-row.approvedAmount": "Approved Amount", // The key used in off-screen text to indicate the approved amount to screen reader users
  "application-table-row.aria-label": "Request from {employeeName} for {activityTitle}. Submitted {applicationDate}, Next Session: {nextSessionDate}, Price: {activityCost}. Request Status: {status}. Request Id: {slug} ", // The aria-label for the application table row
  "application-table-row.days.past.plural": "{numDays} days ago", // Describes how many days ago an application was submitted if more than 1 day
  "application-table-row.days.past.singular": "1 day ago", // Describes how many days ago an application was submitted if it was 1 day ago
  "application-table-row.days.today": "Today", // Displays when submitted on same day as viewer
  "application-table-row.paid": "Paid", // Label text to indicate the amount the user paid for the program or course

  "application-transaction-overview.header": "Request Transaction Overview", // header for the transaction overview card

  "apply-activity.OR": "OR",
  "apply-activity.agree.title": "Terms of Use", // Section where user has to agree to certain policies
  "apply-activity.breadcrumb": "Back to program", // Link text to navigate to the program view page
  "apply-activity.buttonClose": "Close",
  "apply-activity.course.prompt": "You are requesting the following course:", // Course request prompt
  "apply-activity.course.request.title": "Course", // Course request section title
  "apply-activity.courses.prompt": "Which course would you like to request approval for?", // Course selection prompt when multiple courses are available
  "apply-activity.customCondition": "Below is an additional condition as per program policy:", // If additional condition is enabled by employer, prompt user that they need to accept it
  "apply-activity.documentTitle": "My Request - SkillsWave", // Document title when viewing the apply-activity page. Do not translate "SkillsWave".
  "apply-activity.employer": "my employer", // Placeholder text for the user's employer
  "apply-activity.error.blank.departmentName": "Department name cannot be blank", // Error prompt shown to magic link users when applying for program in the event thery entered a department name equivalent to blank spaces.
  "apply-activity.error.blank.name": "First and/or last name cannot be blank", // Error prompt shown to magic link users when applying for program in the event they entered a first and/or last name equivalent to blank spaces.
  "apply-activity.error.cantApply": "You cannot make any program/course requests unless you are an employee", // Error prompt shown to SkillsWave admins or academic partners when attempting to request a program/course
  "apply-activity.error.missing.approver": "Missing requested approver", // Error prompt that the requested approver is missing
  "apply-activity.error.missing.required": "Missing required field", // Error prompt that some required field is missing
  "apply-activity.error.noselection": "You must select a program/course to request approval", // Error prompt that a program or course must be selected
  "apply-activity.existingApp.header": "You requested this in the past.",
  "apply-activity.existingApp.subtext": "Don’t worry, you can still request it now.",
  "apply-activity.existingApp.subtext.link": "You can also review your previous request",
  "apply-activity.introduction.double": "This request will be approved by: <b>{approverOne}</b> and <b>{approverTwo}</b>", // Text to inform user of both approvers for their application
  "apply-activity.introduction.single": "This request will be approved by: <b>{approver}</b>", // Text to inform user of approver for their application
  "apply-activity.jobseeker.employer": "my organization", // Placeholder text for the user's organization
  "apply-activity.loading": "Loading program/course...", // Placeholder loading text
  "apply-activity.manager.label": "My manager is", // Label to show manager
  "apply-activity.manager.name.label": "Manager’s Name", // Label to show manager's name
  "apply-activity.manager.toggle.link": "This is not my manager", // Link to remove manager
  "apply-activity.member.employer": "my assocation", // Placeholder text for the user's association
  "apply-activity.motivation.title": "My Motivation", // Title for section where user explains their reason for taking a course
  "apply-activity.name.double": "{approverOne} and {approverTwo}", // Name formatter for condition where there's two approvers
  "apply-activity.noManager.label": "Your request will be directed to your learning program manager", // Label to show that no manager is selected
  "apply-activity.noManager.toast": "You don’t have a manager or program sponsor listed. Please contact support.", // Error prompt that user's account has no manager or sponsor associated with it
  "apply-activity.noManager.toggle.link": "Direct my request to", // Link to add manager
  "apply-activity.personalInfo.country": "Country of Residence", // Prompt for user to enter their country of residence
  "apply-activity.personalInfo.country.notSupported": "Other countries are not supported", // Error when someone is applying to a location-restricted from a non-supported country
  "apply-activity.personalInfo.customFields.costCode": "Cost Code", // Prompt to enter Job code
  "apply-activity.personalInfo.customFields.employeeNumber": "Employee Number", // Prompt to enter employee Number
  "apply-activity.personalInfo.customFields.jobCode": "Job Code", // Prompt to enter cost code
  "apply-activity.personalInfo.department": "Department", // Prompt to enter Department name
  "apply-activity.personalInfo.description": "If your request is approved, your information will be shared with the institution for registration purposes.", // Notify to the user that their name and job title (name being manually entered in a subsequent field) will be shared with the educational institute.
  "apply-activity.personalInfo.email": "Email Address", // Prompt to enter Department name
  "apply-activity.personalInfo.firstName": "First Name", // In event where the user logged in with the magic link, we do not know the user's first name, and hence it must be requested here.
  "apply-activity.personalInfo.jobTitle": "Job Title", //  Prompt for user to enter job title
  "apply-activity.personalInfo.lastName": "Last Name", // In event where the user logged in with the magic link, we do not know the user's last name, and hence it must be requested here.
  "apply-activity.personalInfo.phone": "Phone Number", // Prompt for user to enter their phone number
  "apply-activity.personalInfo.phone.invalid": "Phone number is invalid", // Error message for when phone number is invalid
  "apply-activity.personalInfo.postalCode": "Postal Code", // Prompt for user to enter their postal code
  "apply-activity.personalInfo.postalCode.inPersonLocationRestricted": "{postalCode} (within {range} km of the institution)",
  "apply-activity.personalInfo.postalCode.invalid": "Postal code is invalid", // Message that appears to inform the user that the postal code they entered is invalid
  "apply-activity.personalInfo.postalCode.invalidOptions": "Must supply both the radius and origin postal code to validate distance", // Message that appears to inform the user that there is a configuration error with the activity postal code validation
  "apply-activity.personalInfo.postalCode.tooFar": "This program requires you to attend in person. Your location is too far away from where the program takes place.", // Message that appears to inform the user that the postal code they entered is too far from the learning centre
  "apply-activity.personalInfo.postalCode.tooltip": "Your postal code is used to calculate sales tax", // Tooltip to explain why postal code is collected
  "apply-activity.personalInfo.preferredEmail": "Preferred Email Address",
  "apply-activity.personalInfo.preferredEmail.tooltip": "Email will be used for communications. Enter an email address that you check on a regular basis.",
  "apply-activity.personalInfo.province": "Province", // User location province (if user in Canada)
  "apply-activity.personalInfo.state": "State", // User location state
  "apply-activity.personalInfo.stateOrProvince": "Province/State", // User location state/province
  "apply-activity.personalInfo.title": "A Few Things About Myself", // Title for section. This section prompts the user to enter additional personal information because their login method (magic link) does not provide certain required information (e.g. first & last name) for proper registration.
  "apply-activity.personalInfo.transcript": "Transcript Origin", // User transcript
  "apply-activity.personalInfo.transcriptHeader": "Where did you receive your transcript?",
  "apply-activity.personalInfo.zipCode": "ZIP Code", // Prompt for user to enter their ZIP code
  "apply-activity.personalInfo.zipCode.invalid": "ZIP code is invalid", // Message that appears to inform the user that the ZIP code they entered is invalid
  "apply-activity.personalInfo.zipCode.tooltip": "Your ZIP code is used to calculate sales tax", // Tooltip to explain why ZIP code is collected
  "apply-activity.program.prompt": "You are requesting the following program:", // Program request prompt
  "apply-activity.program.request.title": "Program", // Program request section title
  "apply-activity.programcourses.prompt": "Are you requesting the entire program or a specific course?", // Activity selection prompt when user can choose between a whole program or a specific course
  "apply-activity.provider": "the education provider", // Placeholder text for the education provider that created this activity
  "apply-activity.reason.prompt": "This will help my professional development by...", // Prompt for user to explain reason for taking the program
  "apply-activity.registration.title": "Registration", // Request registration section title
  "apply-activity.review.button": "Review My Request", // Form button to review draft application to activity
  "apply-activity.review.introduction": "I am requesting: <b>{activity}</b>", // Explanation of what the request submit page is for
  "apply-activity.review-submit-own.introduction": "You are requesting: <b>{activity}</b>", // Explanation of what the request submit page is for
  "apply-activity.reviewAdmissionRequirements.button": "Review Admission Requirements",
  "apply-activity.select.title": "What I Want to Learn", // Section title to select activity to apply to
  "apply-activity.submit.button": "Submit Request", // Submit request button text
  "apply-activity.terms.agree": "I agree to SkillsWave’s", // Prompt text for Terms of Use for the user to agree to. (Part 1)
  "apply-activity.terms.link": "Terms of Use.", // Link text for Terms of Use for the user to agree to. (Part 2)
  "apply-activity.termsOfUse": "I understand that education records and information related to my engagement in the selected Program, including my completion status, grades, and enrollment (”Completion Data”) may be protected by applicable privacy law. I hereby authorize {provider} to disclose Completion Data to SkillsWave and/or to {employer} for the purposes of providing the Services. I further authorize SkillsWave to provide information about me to {provider} necessary for my registration in the selected course or program.",
  "apply-activity.title": "My Request", // Request submission page title
  "apply-activity.tos": "Terms of Use",

  "audit.action.activity_activeStateChange": "Activity active state changed", // Display name for audit type activeStateChange
  "audit.action.activity_costUpdated": "Activity cost updated", // Display name for audit type activeStateChange
  "audit.action.activity_created": "Activity created", // Display name for audit type activityCreated
  "audit.action.activity_deleted": "Activity deleted", // Display name for audit type activityDeleted
  "audit.action.application_approved": "Request approved", // Display name for audit type requestApproved
  "audit.action.application_approved_manager": "Manager approved request", // Display name for audit type managerApproved
  "audit.action.application_approved_sponsor": "Sponsor approved request", // Display name for audit type sponsorApproved
  "audit.action.application_cancelReasonSubmitted": "Cancel reason submitted", // Display name for audit type cancelReasonSubmitted
  "audit.action.application_completedDateSubmitted": "Completed date submitted", // Display name for audit type completedDateSubmitted
  "audit.action.application_completionStatusSubmitted": "Completion status submitted", // Display name for audit type completionDateSubmitted
  "audit.action.application_enrollDateSubmitted": "Enrollment date submitted", // Display name for audit type enrollmentDateSubmitted
  "audit.action.application_refundPctSubmitted": "Refund percentage submitted", // Display name for audit type refundPercentageSubmitted
  "audit.action.application_rejected": "Request rejected", // Display name for audit type requestRejected
  "audit.action.application_rejected_manager": "Manager rejected request", // Display name for audit type managerRejected
  "audit.action.application_rejected_sponsor": "Sponsor rejected request", // Display name for audit type sponsorRejected
  "audit.action.application_started": "Request started", // Display name for audit type requestStarted
  "audit.action.audit_record_change": "Audit records changed",
  "audit.action.collect_details_page_submitted": "Collect details page submitted", // Display name for audit record showing user submitted the form on the Collect Email splash page
  "audit.action.collect_details_page_viewed": "Collect details page viewed", // Display name for audit record showing user viewed the Collect Email splash page

  "audit.action.login": "Login", // Display name for audit type login
  "audit.action.login_linkRequested": "Magic link requested", // Display name for audit type magicLinkRequested
  "audit.action.login_linkUsed": "Magic link used", // Display name for audit type magicLinkUsed
  "audit.action.logout": "Logout", // Display name for audit type logout
  "audit.action.requestPaid": "Request paid", // Display name for audit type requestPaid
  "audit.action.role_add_user": "User added to role catalogue", // Display name for audit type add_user
  "audit.action.role_added": "Role added to role catalogue", // Display name for audit type role_added
  "audit.action.role_assign_user_to_role": "User assigned to role", // Display name for audit type assign_user_to_role
  "audit.action.role_deleted": "Role deleted from role catalogue", // Display name for audit type role_deleted
  "audit.action.role_permission_assigned_to_role": "Permission assigned to role", // Display name for audit type permission_assigned_to_role
  "audit.action.role_permission_unassigned_from_role": "Permission unassigned from role", // Display name for audit type permission_unassigned_from_role
  "audit.action.role_remove_user": "User removed from role catalogue", // Display name for audit type delete_user
  "audit.action.role_unassign_user_from_role": "User unassigned from role", // Display name for audit type unassign_user_from_role
  "audit.action.tenant_logo_email_uploaded": "Tenant email logo uploaded", // Display name for audit type tenantEmailAssetsUploaded
  "audit.action.tenant_logo_image_url_changed": "Tenant logo URL changed", // Display name for audit type tenantLogoImageUrlChanged
  "audit.action.tenant_logo_image_url_email_changed": "Tenant email logo URL changed", // Display name for audit type tenantLogoImageUrlEmailChanged
  "audit.action.tenant_logo_uploaded": "Tenant logo uploaded", // Display name for audit type tenantLogoAssetsUploaded
  "audit.action.user_created": "User created", // Display name for audit type userCreated
  "audit.action.user_failed_freshdesk": "Freshdesk contact creation failed", // Display name for audit type user_failed_freshdesk
  "audit.action.user_updated": "User updated", // Display name for audit type userUpdated

  "auth-error.body": "You tried logging in with a different authentication method than the one you used during signup. Please try again using your original authentication method.", // Error text when public portal users try to create another account with existing email
  "auth-error.button.label": "Try again", // Label for the button on the auth error page
  "auth-error.title": "Oops!", // Error title when public portal users try to create another account with existing email

  "career-explorer.enable": "Enable Career Explorer",
  "career-explorer.toggleSuccess": "Career explorer toggled",
  "career-stream.headingIntro": "Recommended for {careerName}",
  "career-stream.topSkills": "Top matching skills: {listOfSkills}", // Text introducing top skills associated with a stream followed by a comma-separated list of the top skills

  "common.startDate": "Start Date", // The course/programs start date label - reused in multiple contexts
  "common.startDateType": "Date Type", // The course/programs start date type label - reused in multiple contexts

  "components.form-element.permissions.valueMissing": "At least one permission must be selected for this role.",
  "components.form-element.valueMissing": "{label} is required.",

  "confirmation-dialog.actionMessage.cantBeUndone": "This action cannot be undone.", // Action message displayed in dialog before confirming
  "confirmation-dialog.approveRequest.button": "Approve", // Primary button label to approve request
  "confirmation-dialog.approveRequest.description": "Please confirm that you’d like to approve the request:", // Description for approve request dialog
  "confirmation-dialog.approveRequest.title": "Approve Request", // Title for approve request dialog
  "confirmation-dialog.cancel": "Cancel", // Abort confirmation dialog
  "confirmation-dialog.cancelledOrWithdrawn.employee.footerDescription": "Upon confirmation, the employee and company stakeholders will be notified immediately. The employee’s education assistance balance will be restored by the appropriate refund amount.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledOrWithdrawn.jobseeker.footerDescription": "Upon confirmation, the jobseeker and company stakeholders will be notified immediately. The jobseeker’s education assistance balance will be restored by the appropriate refund amount.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledOrWithdrawn.member.footerDescription": "Upon confirmation, the member and company stakeholders will be notified immediately. The member’s education assistance balance will be restored by the appropriate refund amount.", // Footer for dialog to confirm cancelled or withdrawn status
  "confirmation-dialog.cancelledStatus.description.1": "Please confirm that", // Part 1: Description for cancelled status dialog. Ends with 'confirm that {activityTitle}'
  "confirmation-dialog.cancelledStatus.description.2": "was cancelled on {formattedCompletionDate}, and {username} is owed a refund of {refundPct}%.", // Part 2: Description for cancelled status dialog
  "confirmation-dialog.completionStatus.title": "Confirm Completion Status:", // Title of dialog for completion status changes
  "confirmation-dialog.confirm": "Confirm", // Confirm contents in confirmation dialog
  "confirmation-dialog.content.approvedAmount": "Approved Amount", // Key for the approved Amount
  "confirmation-dialog.content.cost": "Cost", // Key for program/course cost
  "confirmation-dialog.content.date": "Date", // Key for the date the request status changed
  "confirmation-dialog.content.pending": "Pending", // Approved amount pending
  "confirmation-dialog.content.refundPct": "Refund %", // Key for refund percentage for cancelled or withdrawn courses/programs
  "confirmation-dialog.content.status": "Status", // Key for request status
  "confirmation-dialog.declineRequest.button": "Decline", // Primary button label to decline request
  "confirmation-dialog.declineRequest.description": "Please confirm that you’d like to decline the request:", // Description for decline request dialog
  "confirmation-dialog.declineRequest.title": "Decline Request", // Title for decline request dialog
  "confirmation-dialog.doNotShowAgain": "Do not show me this again until my next login", // Label for checkbox to not show confirmation dialog again
  "confirmation-dialog.enrollmentDate.description": "Please confirm that {username} registered for <bold>{activityTitle}</bold> on {formattedEnrollDate}.", // Description for dialog to confirm enrollment date registered for {activityTitle}
  "confirmation-dialog.enrollmentDate.title": "Confirm Enrollment Date:", // Title for dialog to confirm enrollment date
  "confirmation-dialog.failedStatus.description": "Please confirm that {username} failed <bold>{activityTitle}</bold> on {formattedCompletionDate}.", // Description for dialog to confirm failed status failed {activityTitle}
  "confirmation-dialog.failedStatus.employee.footerDescription": "Upon confirmation, the employee will be notified immediately.", // Footer for dialog to confirm failed status
  "confirmation-dialog.failedStatus.jobseeker.footerDescription": "Upon confirmation, the jobseeker will be notified immediately.", // Footer for dialog to confirm failed status
  "confirmation-dialog.failedStatus.member.footerDescription": "Upon confirmation, the member will be notified immediately.", // Footer for dialog to confirm failed status
  "confirmation-dialog.inactivateUser.confirmButton": "Make Inactive", // text for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.inactivateUser.description": "Do you want to remove <bold>{fullName}'s</bold> access to Skillswave Administrative Settings and make their user inactive? You can change their user status at any time by accessing the inactive user records.", // text for confirmation dialog on the Des.
  "confirmation-dialog.inactivateUser.title": "Confirm User Inactivation", // header for confirmation dialog on the Delete User Roles.
  "confirmation-dialog.passedStatus.description.1": "Please confirm that {username} passed", // Part 1: Description for passed status dialog. Ends with 'passed {activityTitle},'
  "confirmation-dialog.passedStatus.description.2": "on {formattedCompletionDate}.", // Part 2: Description for passed status dialog
  "confirmation-dialog.passedStatus.employee.footerDescription": "Upon confirmation, the employee and company stakeholders will be notified immediately.", // Footer for dialog to confirm passed status
  "confirmation-dialog.passedStatus.jobseeker.footerDescription": "Upon confirmation, the jobseeker and company stakeholders will be notified immediately.", // Footer for dialog to confirm passed status
  "confirmation-dialog.passedStatus.member.footerDescription": "Upon confirmation, the member and company stakeholders will be notified immediately.", // Footer for dialog to confirm passed status
  "confirmation-dialog.removeRole.description.assigned": "Do you want to delete the role, <bold>{roleName}</bold>?", // Description for dialog to remove role
  "confirmation-dialog.removeRole.description.numRemoved": "When this role is deleted the role will be removed from the <bold>{usersWithRole} users</bold>.", // Description for dialog to remove role
  "confirmation-dialog.removeRole.description.numWithoutRole": "<bold>{usersWithNoRoleAfterDelete} users</bold> will have no role associated with them. Make sure to go to the User Permissions tab to give them the permissions they need.", // Description for dialog to remove role
  "confirmation-dialog.removeRole.description.unassigned": "Do you want to delete the role, <bold>{roleName}</bold>? This role is not associated with any of the users on SkillsWave.", // Description for dialog to remove role
  "confirmation-dialog.removeRole.title": "Confirm Role Deletion", // Title for dialog to remove role
  "confirmation-dialog.revoke.approver-joining": "and",
  "confirmation-dialog.revoke.approvers": "Approver(s)",
  "confirmation-dialog.revoke.cancelButton": "Never Mind",
  "confirmation-dialog.revoke.confirmButton": "Yes, Cancel",
  "confirmation-dialog.revoke.postContentMessage": "If you cancel your request the approver(s) will be notified. Don’t worry, you can request this again in the future.",
  "confirmation-dialog.revoke.title": "Cancel Request",
  "confirmation-dialog.status.cancelled": "Cancelled", // Cancelled application status
  "confirmation-dialog.status.fail": "Failed", // Failed application status
  "confirmation-dialog.status.pass": "Passed", // Passed application status
  "confirmation-dialog.status.pending": "Pending", // Pending application status
  "confirmation-dialog.status.withdrawn": "Withdrawn", // Withdrawn application status
  "confirmation-dialog.withdrawnStatus.description.1": "Please confirm that {username} withdrew from", // Part 1: Description for withdrawn status dialog. Ends with 'withdrew from {activityTitle},'
  "confirmation-dialog.withdrawnStatus.description.2": "on {formattedCompletionDate}, and is owed a refund of {refundPct}%.", // Part 2: Description for withdrawn status dialog

  "connect-account.backlink": "Back to application", // Describing placeholder
  "connect-account.dialog.button.proceed": "Go to Step 2", // placeholder
  "connect-account.dialog.description": "Once you’ve created a new account, come back to SkillsWave and go to Step 2.", // placeholder. Do not translate "SkillsWave".
  "connect-account.dialog.title": "Create a new account", // Placeholder
  "connect-account.introduction": "Create an account with {providerName} and connect it to SkillsWave. Don’t worry, you’ll only have to do this once with {providerName}. This part should take 10 minutes. Then it will take up to {registrationTime} business days for {providerName} to contact you. ", // Describing placeholder. Do not translate "SkillsWave".
  "connect-account.stepOne.button.create": "Create a new account", // placeholder
  "connect-account.stepOne.button.skip": "I already have an account", // placeholder
  "connect-account.stepOne.instructions.admissionsBased": "Admission fees may apply and will be charged by the institution. Pay these fees on the institution’s website.",
  "connect-account.stepOne.instructions.header": "Instructions", // Describing placeholder
  "connect-account.stepOne.instructions.one": "Create an account using {userEmail}", // Describing placeholder
  "connect-account.stepOne.instructions.three": "Once you’ve created your account, come back to SkillsWave and continue to Step 2.", // Describing placeholder. Do not translate "SkillsWave".
  "connect-account.stepOne.instructions.two.employee": "Do not pay on the institution’s website, otherwise your employer coverage will not be applied.", // Describing placeholder
  "connect-account.stepOne.instructions.two.jobseeker": "Do not pay on the institution’s website, otherwise your organization coverage will not be applied.", // Describing placeholder
  "connect-account.stepOne.instructions.two.member": "Do not pay on the institution’s website, otherwise your association coverage will not be applied.", // Describing placeholder
  "connect-account.stepOne.summary": "You’ll only have to do this the first time you register with {providerName}", // Placeholder
  "connect-account.stepOne.support.link": "Connect with support", // placeholder
  "connect-account.stepOne.support.text": "Need a hand?", // placeholder
  "connect-account.stepOne.title": "Step 1: Create an account with {providerName}", // Describing placeholder

  "connect-account.stepThree.completion.done": "Done", // placeholder
  "connect-account.stepThree.completion.instructions.employee": "Your employer is covering the tuition costs. Click “Done” to finish connecting your account and {providerName} will contact you.", // placeholder
  "connect-account.stepThree.completion.instructions.jobseeker": "Your organization is covering the tuition costs. Click “Done” to finish connecting your account and {providerName} will contact you.", // placeholder
  "connect-account.stepThree.completion.instructions.member": "Your association is covering the tuition costs. Click “Done” to finish connecting your account and {providerName} will contact you.", // placeholder
  "connect-account.stepThree.completion.summary.employee": "Your employer is covering the tuition for this {activityType}.", // placeholder
  "connect-account.stepThree.completion.summary.jobseeker": "Your organization is covering the tuition for this {activityType}.", // placeholder
  "connect-account.stepThree.completion.summary.member": "Your association is covering the tuition for this {activityType}.", // placeholder
  "connect-account.stepThree.completion.title": "Step 3: Complete account connection and {providerName} will contact you", // placeholder
  "connect-account.stepThree.payment.instructions": "Click “next” to pay your outstanding amount.", // placeholder
  "connect-account.stepThree.payment.next": "Next", // placeholder
  "connect-account.stepThree.payment.summary.employee": "You’ll always pay on SkillsWave so that your employer coverage can be applied (if applicable).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.summary.jobseeker": "You’ll always pay on SkillsWave so that your organization coverage can be applied (if applicable).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.summary.member": "You’ll always pay on SkillsWave so that your association coverage can be applied (if applicable).", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepThree.payment.title": "Step 3: Pay on SkillsWave", // placeholder. Do not translate "SkillsWave".
  "connect-account.stepTwo.disableTooltip": "Please enter a valid email", // placeholder
  "connect-account.stepTwo.goNext": "Go to step 3", // placeholder
  "connect-account.stepTwo.other": "Other", // placeholder
  "connect-account.stepTwo.question": "What email did you use to create your account with {providerName}?", // placeholder
  "connect-account.stepTwo.studentID": "What is your {providerName} student ID? your student ID will look like this: <bold>{idFormat}</bold>",
  "connect-account.stepTwo.summary": "You’ll only have to do this the first time you register with {providerName}.", // placeholder
  "connect-account.stepTwo.textInput.label": "Email", // placeholder
  "connect-account.stepTwo.textInput.studentID": "Student ID",
  "connect-account.stepTwo.title": "Step 2: Connect your {providerName} account to SkillsWave", // placeholder. Do not translate "SkillsWave".
  "connect-account.title": "Connect Your Account", // Describing placeholder

  "contact-support.button": "Contact Support", // Label for form submission button
  "contact-support.description": "Our team is standing by to support you and answer your questions.", // Description displayed under page title
  "contact-support.documentTitle": "Contact Support - SkillsWave", // Text which appears in the browser's page title for the Contact Support page. Do not translate "SkillsWave".
  "contact-support.error": "Missing required field(s)", // Error message if form field is missing
  "contact-support.form.email": "Your Email Address:", // Label for email address input field
  "contact-support.form.name": "Your Name:", // Label for name input field
  "contact-support.form.questionType": "Type of Question", // Label for question type dropdown
  "contact-support.form.questionType.academicContent": "I have a question about the content of an academic program",
  "contact-support.form.questionType.employerPolicy": "I have a question about my employer policy",
  "contact-support.form.questionType.productFeedback": "Product feedback",
  "contact-support.form.questionType.technicalSupport": "I have a technical support issue",
  "contact-support.form.textField": "How can we help you?", // Label for text input field for user to enter support description
  "contact-support.thanksForMessage": "Thank you for the message. We’ll reply to you within 2-3 business days.", // Message displayed after support form is submitted
  "contact-support.title": "Contact Support", // Title of contact support page

  "countries.australia": "Australia", // Australia as option for employee location selection
  "countries.canada": "Canada", // Canada as option for employee location selection
  "countries.other": "Other", // Other as option for employee location selection
  "countries.united-states": "United States", // United States as option for employee location selection

  "course.error.imagesMustBeHTTPS": "Course images must use HTTPS", // error image isn't https
  "course.error.missingContentFormat": "Course must have at least 1 content format", // error when course has 0 content formats
  "course.error.missingFeedbackType": "Course must have at least 1 feedback type", // error when course has 0 feedback types
  "course.error.requiredFieldMissing": "Course missing required field(s)", // error when missing required fields

  "defaultFAQs.a1": "It’s simple—find a course or program in the catalog and click “Start request”. Fill out the request form and submit it for approval. Once your request is approved, you’ll receive an email that will direct you to complete the registration process.", // Answer to Frequently Asked Question 1
  "defaultFAQs.a2.employee": "All courses and programs are eligible for direct billing to your employer, which means you’ll only pay for costs above your employer’s coverage. Once your request is approved, you’ll be able to pay for any cost that exceeds your coverage by credit card during the registration process.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a2.jobseeker": "All courses and programs are eligible for direct billing to your organization, which means you’ll only pay for costs above your organization’s coverage. Once your request is approved, you’ll be able to pay for any cost that exceeds your coverage by credit card during the registration process.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a2.member": "All courses and programs are eligible for direct billing to your association, which means you’ll only pay for costs above your association’s coverage. Once your request is approved, you’ll be able to pay for any cost that exceeds your coverage by credit card during the registration process.", // Answer to Frequently Asked Question 2
  "defaultFAQs.a3": "Most programs’ courses can be taken individually without enrollment in the full credential. However, there are some exceptions. A course that cannot be taken separate from the program won’t give you the option to request it.", // Answer to Frequently Asked Question 3
  "defaultFAQs.a4.employee": "If you’d like to take a course from an education provider not listed in the catalog, talk to your manager to see how it could be funded by your employer. Additionally, we’d love to hear about it so we can add it to the catalog! Submit the name of the course and education provider through the chatbot or by completing the “Contact Support” form.", // Answer to Frequently Asked Question 4
  "defaultFAQs.a4.jobseeker": "If you’d like to take a course from an education provider not listed in the catalog, talk to your manager to see how it could be funded by your organization. Additionally, we’d love to hear about it so we can add it to the catalog! Submit the name of the course and education provider through the chatbot or by completing the “Contact Support” form.", // Answer to Frequently Asked Question 4
  "defaultFAQs.a4.member": "If you’d like to take a course from an education provider not listed in the catalog, talk to your manager to see how it could be funded by your association. Additionally, we’d love to hear about it so we can add it to the catalog! Submit the name of the course and education provider through the chatbot or by completing the “Contact Support” form.", // Answer to Frequently Asked Question 4
  "defaultFAQs.a5.employee": "Requests can be cancelled on the Requests page. When you click “Cancel Request”, you’ll be prompted for a reason. Once submitted, the request will be marked as cancelled. Your employer and/or the education provider may reach out for more information.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a5.jobseeker": "Requests can be cancelled on the Requests page. When you click “Cancel Request”, you’ll be prompted for a reason. Once submitted, the request will be marked as cancelled. Your organization and/or the education provider may reach out for more information.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a5.member": "Requests can be cancelled on the Requests page. When you click “Cancel Request”, you’ll be prompted for a reason. Once submitted, the request will be marked as cancelled. Your association and/or the education provider may reach out for more information.", // Answer to Frequently Asked Question 5
  "defaultFAQs.a6": "Don’t worry! Your learning request will be automatically cancelled in the system, enabling you to submit another request for a comparable course. Alternatively, if you’d like to know when this course will be offered again, please contact support.", // Answer to Frequently Asked Question 6
  "defaultFAQs.a7": "Contact our support team using the chatbot in the bottom right corner. With the chatbot, you can notify us of issues or ask questions using a series of prompts. Alternatively, use the “Contact Support” form that can be accessed using the link in the footer of every page.", // Answer to Frequently Asked Question 7
  "defaultFAQs.a8": "Please review SkillsWave’s Privacy Policy found at <privacy-link>https://skillswave.com/privacy-policy/</privacy-link>.", // Answer to Frequently Asked Question 8
  "defaultFAQs.q1": "How do I request funding to take a course or program?", // Default Frequently Asked Question 1
  "defaultFAQs.q2": "How do I pay for my course or program?", // Default Frequently Asked Question 2
  "defaultFAQs.q3": "Can I take a course that is part of a program without taking the entire program?", // Default Frequently Asked Question 3
  "defaultFAQs.q4": "I’m interested in an education provider or course not currently in the catalog. How do I request it be added?", // Default Frequently Asked Question 4
  "defaultFAQs.q5": "I requested a program but then changed my mind. How do I cancel my course or program request?", // Default Frequently Asked Question 5
  "defaultFAQs.q6": "I was approved for a course but the education partner cancelled it. What are my options?", // Default Frequently Asked Question 6
  "defaultFAQs.q7": "How do I get support for a technical issue or report a problem?", // Default Frequently Asked Question 7
  "defaultFAQs.q8": "How does SkillsWave process data?", // Default Frequently Asked Question 8

  "edit-activity.active.checkbox": "Active", // Label for activity active switch
  "edit-activity.addCourse.label": "Add Course", // Text for button to add course to program
  "edit-activity.addCourses.prompt": "Which course would you like to add to the program:", // Prompt for dropdown for selecting which course to add to the program
  "edit-activity.admissionBased.addInternationalRequirement": "Add International Requirement",
  "edit-activity.admissionBased.addRequirement": "Add Requirement",
  "edit-activity.admissionBased.checkbox": "Admission-based",
  "edit-activity.admissionBased.checkboxFlagUnchecked": "All admission requirements will be removed from this activity after saving.",
  "edit-activity.admissionBased.contactEmail": "Admission contact email",
  "edit-activity.admissionBased.delay": "Delay (in weeks)",
  "edit-activity.admissionBased.emptyRequirementsSaveError": "You must have at least 1 admission requirement",
  "edit-activity.admissionBased.internationalRequirementsCount": "international requirement(s)",
  "edit-activity.admissionBased.internationalSummary": "These requirements only apply to learners who do not have a high-school transcript from a Canadian institution.",
  "edit-activity.admissionBased.internationalTitle": "International Transcript Requirements",
  "edit-activity.admissionBased.label": "Admission Based Settings",
  "edit-activity.admissionBased.potentialFee": "Potential fee",
  "edit-activity.admissionBased.requirementDeletionWarning": "You must have at least 1 Admission Requirement",
  "edit-activity.admissionBased.requirements": "Requirements",
  "edit-activity.admissionBased.requirementsAfterSave": "Requirements will be published to the activity page when saved",
  "edit-activity.admissionBased.requirementsCount": "requirement(s)",
  "edit-activity.admissionBased.standardSummary": "These requirements apply to all learners, regardless of location or type of transcript.",
  "edit-activity.admissionBased.standardTitle": "Admission Requirements",
  "edit-activity.admissionBased.title": "Title",
  "edit-activity.allowRequesting.checkbox": "Allow requesting", // Label for activity requesting ability check box
  "edit-activity.bannerImageLink.label": "Banner Image URL", // Label for activity banner image URL input field
  "edit-activity.cancel.button": "Cancel", // Text for button to cancel adding a course to the activity
  "edit-activity.cardImageLink.label": "Card Image URL", // Label for activity card image URL input field
  "edit-activity.category.label": "Skill Streams", // Label for activity category dropdown
  "edit-activity.certificateType.label": "Certificate Type", // Label for activity certificate type dropdown
  "edit-activity.code.label": "Program/Course Code", // Label for activity code input field
  "edit-activity.contentFormat.checkbox": "Content Format", // Label for course content format checkboxes section
  "edit-activity.core": "Core", // Text label for core course radio button
  "edit-activity.coreOrElective.label": "Core or Elective", // Label for radio buttons to select whether the course being added is required or an elective
  "edit-activity.courses": "Courses", // Activities of the type course
  "edit-activity.createActivity.button": "Create Activity", // Text for button that creates an activity when clicked
  "edit-activity.credits.label": "Credits", // Label for activity credits input field
  "edit-activity.delivery.label": "Delivery", // Label for activity delivery dropdown
  "edit-activity.description.label": "Description", // Label for activity description input field
  "edit-activity.documentTitle": "Edit Activity - Admin - SkillsWave", // Document title when editing an activity. Do not translate "SkillsWave".
  "edit-activity.documentTitle.addActivity": "Add Activity - Admin - SkillsWave", // Document title when creating a new activity. Do not translate "SkillsWave".
  "edit-activity.duration.label": "Duration", // Label for activity duration dropdown
  "edit-activity.elective": "Elective", // Text label for elective course radio button
  "edit-activity.error.selectAtLeastOneSkillStream": "Please select at least one skill stream", // Error message for when no skill stream is selected
  "edit-activity.extractSkills.button": "Extract Skills from Description", // Text for extract skills button
  "edit-activity.extractSkills.pleaseWait": "Please wait while skills are extracted, this may take up to a minute.", // Text displayed while skills are being extracted
  "edit-activity.fastToComplete.checkbox": "Fast to Complete", // Label to indicate if the associated course is fast to complete (think, less than a week, etc.)
  "edit-activity.feedbackTypes.checkbox": "Feedback Types", // Label for course feedback types checkboxes section
  "edit-activity.format.label": "Format", // Label for activity format dropdown
  "edit-activity.inPerson.maxLearnerDistance": "Distance radius (in kilometres)",
  "edit-activity.instructionLang.label": "Language of Instruction", // Label for activity instruction language dropdown
  "edit-activity.keyDifferentiator.label": "Key Differentiator", // Label for activity key differentiator input field
  "edit-activity.numCoursesRequired.label": "Number of Courses Required", // Label for activity required courses count input field
  "edit-activity.numberElectives.label": "Number of Electives", // Label for activity electives count input field
  "edit-activity.order.label": "Order", // Label for add course order input field
  "edit-activity.order.placeholder": "No order", // Placeholder status indicator text for a course without an order
  "edit-activity.overallCost.label": "Overall Cost", // Label for activity overall cost input field
  "edit-activity.prerequisites.label": "Prerequisites", // Label for activity prerequisites input field
  "edit-activity.registrationLink.label": "Registration Link", // Label for activity registration link input field
  "edit-activity.removeCourse": "Remove Course", // ariaLabel for button to remove course from program
  "edit-activity.removedSkills.header": "Skills Omitted from Extraction", // Header for removed skills section
  "edit-activity.save.button": "Save Activity", // Text for button to save activity
  "edit-activity.saveAndClose.button": "Save and Close Activity", // Text for button to save and close activity
  "edit-activity.select": "Select an Option", // Dropdown menu prompt
  "edit-activity.selectCourse": "Select a Course", // Dropdown menu prompt for adding a new course to the program
  "edit-activity.skillExtractionError": "Error when trying to extract skills from activity data. The activity has not been updated.", // The failure  message for when failing to extract skills
  "edit-activity.skills.extractedSkillsEmpty": "Either no skills have been extracted from the description or they have all been omitted from extraction", // Displayed when there are no extracted skills or all are omitted
  "edit-activity.skills.header.extractedSkills": "Extracted Skills", // Header for extracted skills sub-section
  "edit-activity.skills.header.manageExtracted": "Manage Extracted Skills", // Header for extracted skills management sub-section
  "edit-activity.skills.header.manageOtherSkills": "Manage Other Skills", // Header for sub-section that lets people manage non-extracted skills
  "edit-activity.skills.header.omittedFromExtraction": "Skills Omitted from Extraction", // Header for the omitted extracted skills sub-section
  "edit-activity.skills.header.preview": "Activity Skills Preview", // Header for sub-section that lets people preview skills as they would appear on a view-activity page
  "edit-activity.skills.omittedExtractedSkillsEmpty": "No skills have been omitted from extraction", // Displayed when there are no extracted skills omitted
  "edit-activity.skills.previewEmpty": "No skills visible for this activity", // Displayed when there are no visible skills associated with the activity

  "edit-activity.specialInstructions.label": "Special Instructions", // Label for activity special instructions input field
  "edit-activity.startDateType.anytime": "Anytime", // The course/program starts anytime
  "edit-activity.startDateType.comingSoon": "Coming soon", // The course/program start date is coming soon
  "edit-activity.startDateType.date": "Date", // The course/program starts on a specific date
  "edit-activity.startDateType.unknown": "Unknown", // The course/program start date is unknown
  "edit-activity.successfulSkillExtractionMessage": "Successfully extracted skills", // The success message for when the skills are successfully extracted
  "edit-activity.switch.tooltip.category": "Switch disabled until a skill stream is selected", // Tooltip message for disabled switch - category issue
  "edit-activity.switch.tooltip.disabled": "Switch disabled, open course edit page to resolve issue", // Tooltip message for disabled switch - general
  "edit-activity.switch.tooltip.inactive": "This course has no active programs", // Tooltip message for disabled switch - inactive parents
  "edit-activity.tags.label": "Tags",
  "edit-activity.taxable.checkbox": "Taxable", // Label for whether an activity is taxable check box
  "edit-activity.timeCommitment.label": "Time Commitment", // Label for activity time commitment dropdown
  "edit-activity.title": "Edit Activity", // Edit Activity page title
  "edit-activity.title.label": "Title", // Label for activity title input field
  "edit-activity.type.label": "Type", // Label for activity type dropdown
  "edit-activity.updateCourse.label": "Update Course", // Text for button to edit course added to program
  "edit-activity.weeklyTimeCommitment": "Weekly Time Commitment", // Dropdown menu prompt for weekly time commitment
  "edit-activity.zeroDollarProgramActivity.checkbox": "$0 declaration program", // Label for the zero dollar program checkbox

  "edit-permissions.permissions-table.deleteUserRolesConfirmation.toast": "User deleted successfully", // text for confirmation dialog on the Des.
  "edit-permissions.permissions-table.header.actions": "Actions", // label for  header on the User Permissions table.
  "edit-permissions.permissions-table.header.deleteUser": "Delete User", // label for  delete admin button on the User Permissions table.
  "edit-permissions.permissions-table.header.editAdmin": "Edit Admin", // label for edit admin button on the User Permissions table.
  "edit-permissions.permissions-table.header.email": "Email Address", // label fo email header on the User Permissions table.
  "edit-permissions.permissions-table.header.jobTitle": "Job Title", // label for job title header on the User Permissions table.
  "edit-permissions.permissions-table.header.name": "Name", // label for name header on the User Permissions table.
  "edit-permissions.permissions-table.header.noRoles": "No role associated with this user", // no Roles text on the User Permissions table.
  "edit-permissions.permissions-table.header.permissions": "Role Assignments", // label for header on the User Permissions table.
  "edit-permissions.roles": "Roles", // Label for the Roles tab on the Permissions page.
  "edit-permissions.userPermissions": "User Permissions", // Label for the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.activeTab": "Active Users", // Label for Active Users tab on the Permissions page.
  "edit-permissions.userPermissions.addUser": "Add User", // Label for the Add User button on the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.description": "Allocate users to roles here. Each user can have multiple roles associated to them and their permissions will be additive.  If there are no permissions assoicated with a user when they log into SkillsWave admin, they will not be able to see or edit anything. ", // Description for the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.error.description": "There are no users to display.", // description for empty state illustration
  "edit-permissions.userPermissions.error.noUsers": "No users", // Message displayed when there are no users in the User Permissions tab on the Permissions page.
  "edit-permissions.userPermissions.inactiveTab": "Inactive Users", // Label for Active Users tab on the Permissions page.

  "edit-role.description": "Edit the role here. Use broad and granular permissions to set up the role with the correct specific permissions for admin access.", // Description for the edit-role page
  "edit-role.title": "Edit Role", // Title for the edit-role page

  "edit-roles.button.deleteRole": "Delete Role", // text on the delete role button
  "edit-roles.button.editRole": "Edit Role", // text on the edit role button
  "edit-roles.createRole": "Create Role", // text on the create role button
  "edit-roles.description": "Create and edit roles here. Assign specific permissions to each role as it pertains to specific job functions. Make sure to include a description to make it easier for others to understand the responsibilities of the role. ", // Description for edit roles page
  "edit-roles.table.action.delete": "Delete Role", // alt text for delete role button
  "edit-roles.table.action.edit": "Edit Role", // alt text for edit role button
  "edit-roles.table.empty.description": "Create a new role to start setting up permissions", // Empty state description for roles table
  "edit-roles.table.empty.title": "No Roles", // Empty state title for roles table
  "edit-roles.table.header.actions": "Actions", // Actions column header in the roles table
  "edit-roles.table.header.allocatedUsers": "Allocated Users", // Allocated Users column header in the roles table
  "edit-roles.table.header.description": "Description", // Description column header in the roles table
  "edit-roles.table.header.roleName": "Role Name", // role Name column header in the roles table

  "edit-roles.title": "Roles", // Title for edit roles page

  "edit-tenant.documentTitle": "Edit Tenant - Admin - SkillsWave", // Web page title when editing a tenant. Do not translate "SkillsWave".
  "edit-tenant.documentTitle.addTenant": "Add Tenant - Admin - SkillsWave", // Web page title when creating a new tenant. Do not translate "SkillsWave".
  "edit-tenant.hybrid.disabled.tooltipText": "The hybrid approval model cannot be enabled when the case-by-case budget is selected", // A tooltip description explaining why the radio button is disabled
  "edit-tenant.visibility.display.error": "Error: Currently visible", // Error displaying an activity that should not be visible
  "edit-tenant.visibility.display.note": "Note: Currently visible in a different program", // Note activity is still visible for good reason
  "edit-tenant.visibility.display.warning": "To make this course visible, turn the program off and back on", // A course in program is not visible
  "edit-tenant.visibility.search.error": "An error occurred with the search. Please try again after some time", // Error occurred with search
  "edit-tenant.visibility.search.noMatch": "No match found. Please select from the dropdown or type the exact name of the activity", // No activity matches found for search criteria

  "emails.approval-requested.calloutText.1": "Please note: {name} did not select their manager when submitting this request. It’s possible that there’s a problem with the manager data in the system.", // First sentence of a notice when the applicant selects "Not my manager"
  "emails.approval-requested.calloutText.link": "If this is the case, please <link>contact us</link> so that we can help.", // Last sentence in the notice, reminding the reader to contact support if there is a problem. Please do NOT translate <link> tags
  "emails.approval-requested.customContent.1": "{name} would like to take {title} through {provider}.", // First sentence of the request application approval email
  "emails.approval-requested.customContent.2": "Please review the request quickly so that {name} has time to register.", // Second sentence in the email
  "emails.approval-requested.external.customContent.1": "{learnerName} would like approval for {activityTitle}.", // A learner would like approval from their boss to expense or participate in an external training opportunity
  "emails.approval-requested.external.customContent.2": "Please review the request quickly.", // Prompting the approving manager to review the request quickly
  "emails.approval-requested.headerOptions.text": "Your approval has been requested", // Email main title header
  "emails.approval-requested.subject": "Approval required: please review {name}’s learning request", // Subject line of approval requested email

  "emails.buttons.view-request.text": "View Request", // Text for the main button in the email

  "emails.cancelled-withdrawn.customContent.apology.employee": "We’re really sorry this didn’t work out. <link>Explore other offerings that are supported by your employer</link>.", // Apology for the cancellation and link to other offerings/activities
  "emails.cancelled-withdrawn.customContent.apology.jobseeker": "We’re really sorry this didn’t work out. <link>Explore other offerings that are supported by your organization</link>.", // Apology for the cancellation and link to other offerings/activities
  "emails.cancelled-withdrawn.customContent.apology.member": "We’re really sorry this didn’t work out. <link>Explore other offerings that are supported by your association</link>.", // Apology for the cancellation and link to other offerings/activities, if tenant is an association
  "emails.cancelled-withdrawn.customContent.expenseContact": "If you already expensed the cost through your company, please contact <link>{programSponsorName}</link> for next steps.", // Telling the learner to contact their sponsor/manager if they've already expensed the cost of the activity with their company. Do not translate the <link> tags
  "emails.cancelled-withdrawn.customContent.refund.integrated": "If you paid, a refund for {refundPercent, number, percent} will be issued via your original method of payment.", // Notifying the learner the percentage that will be refunded to them
  "emails.cancelled-withdrawn.customContent.reimbursement": "If reimbursement was already provided for this course or program, please take any necessary next steps according to your company policies or contact <link>{programSponsorName}</link>.", // Reminding the learner to take any necessary next steps after being reimbursed

  "emails.login-requested.button.text": "Log In Now", // Text for the main button in the email, logs user in to Wave app when clicked
  "emails.login-requested.customContent.greeting": "Hi,", // Greeting to email recipient at top of email
  "emails.login-requested.customContent.loginRequest": "Your request to log in to SkillsWave with {requesterEmail} has been completed!", // Sentence in body of email, stating that the user's request to log in to SkillsWave has been completed through the receipt of this email. Do not translate "SkillsWave".
  "emails.login-requested.header.text": "Your SkillsWave account is ready", // Email main title header stating that login access/an account is ready. Do not translate "SkillsWave".
  "emails.login-requested.subject": "Your SkillsWave account is ready", // Subject line of email stating that login access/an account is ready. Do not translate "SkillsWave".

  "emails.monthly-invoice.customContent.1": "Your total transactions for {month} {year} amounted to {salesAmount}.", // Mentioning the total sales transaction that occurred during {month} {year}
  "emails.monthly-invoice.customContent.2": "<bold>Payable to {providerName}</bold>", // Payable to provider
  "emails.monthly-invoice.customContent.3": "We have issued {salesInvoiceCount, plural, one {a} other {{salesInvoiceCount}}} <boldUnderline>voided</boldUnderline> {salesInvoiceCount, plural, one {invoice} other {invoices}} as a way for you to review the details of your transactions, available for download for a period of 60 days:", // Notifying the provider to save their invoice
  "emails.monthly-invoice.customContent.4": "Please save the report for your records. DO NOT PROCESS {salesInvoiceCount, plural, one {THIS INVOICE} other {THESE INVOICES}} FOR PAYMENT. Instead, please issue an invoice to {d2lFinanceEmail} for {salesAmount} and include the reference code “<bold>SKILLSWAVE {salesReferenceInvoiceNumber}</bold>” on your invoice to receive your payout.", // Notifying the provider to issue it to SkillsWave for a payout. Do not translate "SKILLSWAVE".
  "emails.monthly-invoice.customContent.5": "<bold>Payable to SkillsWave</bold>", // Payable to SkillsWave
  "emails.monthly-invoice.customContent.6": "In accordance with total transactions for {month} {year}, we have issued {revShareInvoiceCount, plural, one {a revenue share invoice} other {{revShareInvoiceCount} revenue share invoices}} in the total of {revShareAmount}, available for download for a period of 60 days:", // Notifying the provider to process the revenue share invoice for payment to SkillsWave.
  "emails.monthly-invoice.customContent.7": "Please process this invoice for payment by the due date and include the reference number “<bold>SKILLSWAVE {revShareReferenceInvoiceNumber}</bold>”.", // Notifying the provider to process the revenue share invoice for payment to SkillsWave. Do not translate "SKILLSWAVE".
  "emails.monthly-invoice.customContent.8": "<bold>Reminder:</bold> You have 5 business days to dispute any transaction at {d2lFinanceEmail}.", // Reminding the provider to dispute any transactions to SkillsWave's finance team if any
  "emails.monthly-invoice.header.text": "Your monthly transaction report is ready to review", // Email main title header
  "emails.monthly-invoice.negativeInvoiceInfo": "In the event your invoice report amount is negative, the credit amount will be applied to the following month’s invoice report.", // Explains our use of negative invoice items to the tenant
  "emails.monthly-invoice.number": "Invoice number: {invoiceNumber}", // Text that goes before the invoice number in the email
  "emails.monthly-invoice.subject": "[New SkillsWave Transaction Report] {month} {year} Ready For Review", // Email subject line. Do not translate "SkillsWave".

  "emails.register-learner.customContent.discountCode": "Please provide a code for this learner to register for the program.", // Request to academic admin to provide a discount/rebate code to the learner
  "emails.register-learner.customContent.learnerApproved": "{learnerName} has been approved by {employerName} for {activityTitle} at a coverage of up to {approvedAmount}.", // Notifying the academic admin that a new learner has been approved by their employer, and is covered up to a certain currency value
  "emails.register-learner.header.text.codeRequested": "SkillsWave code requested", // Main header in the email, notifying the academic admin that a discount/rebate code has been requested. Do not translate "SkillsWave".
  "emails.register-learner.header.text.newLearner": "New SkillsWave learner", // Main header in the email, notifying the academic admin that a new learner has been approved. Do not translate "SkillsWave".
  "emails.register-learner.subject": "New SkillsWave learner", // Email subject line, notifying the academic admin that a new learner has been approved. Do not translate "SkillsWave".

  "emails.registration-request.customContent.registrationInstruction": "Use the email above to find {learnerName} in your registration system.", // Instruction to reader to use a user's email (written above this sentence) to find the user in their registration system
  "emails.registration-request.headerOptions.text": "You have a new registration request", // Main header title of the email, indicating that the academic institution has a new request from a user to register for a program or course
  "emails.request-approved.account-connect.follow-steps": " Follow the steps below to create your {providerName} account and submit your registration before your {activityType} begins. We recommend acting quickly as some {activityType}s have limited capacity.",
  "emails.request-approved.account-connect.step-1.body": "Create an account with {providerName} so they can register you into {activityTitle}.",
  "emails.request-approved.account-connect.step-1.button": "Create Account",
  "emails.request-approved.account-connect.step-1.header": "Step 1: Create your student account",
  "emails.request-approved.account-connect.step-2.body": "After you create your account with {providerName}, connect your account with your email and submit your registration.",
  "emails.request-approved.account-connect.step-2.button": "Submit Registration",
  "emails.request-approved.account-connect.step-2.header": "Step 2: Submit your registration",
  "emails.request-approved.button.text.reimbursement": "Submit Registration", // Text for the main button in the email, view request and register for course/program
  "emails.request-approved.customContent.approvedCost": "{employerName} will pay {approvedAmount} for you to take {activityTitle} through {providerName}. Your remaining cost is {employeeCost}.", // Notifying the requester of the amount that has been approved and the remaining cost
  "emails.request-approved.customContent.approvedCost.zeroApproved": "Your learning request for {activityTitle} through {providerName} has been approved. Your cost is {employeeCost}. View your request to complete your next steps to register. We recommend acting quickly as some {activityType}'s have limited capacity",
  "emails.request-approved.customContent.approvedCost.zeroApproved.ZeroCost": "Your learning request for {activityTitle} through {providerName} has been approved. View your request to complete your next steps to register. We recommend acting quickly as some {activityType}s have limited capacity",
  "emails.request-approved.customContent.cancellationNote": "Courses and programs are subject to cancellation. In the event of cancellation, you will be notified immediately.", // Warning that some courses are subject to cancellation
  "emails.request-approved.customContent.registerSoon": "Don’t miss out on the registration date. {activityTitle} is starting soon.", // Reminder that that the course/program is starting soon
  "emails.request-approved.customContent.registerToday": "Register today to secure your spot as some courses have limited capacity.", // Reminder to register for the course as soon as possible because some courses may fill up
  "emails.request-approved.customContent.registrationDate": "{activityTitle} starts on {activityStartDate}.", // Reminder to register before the start date. Start date takes the format of this example: June 30, 2030.
  "emails.request-approved.customContent.submitRegistration": "<bold>Submit your registration</bold> before your {activityType} begins. We recommend acting quickly as some {activityType}s have limited capacity.", // Reminder to register for the course as soon as possible because some courses may fill up
  "emails.request-approved.external.customContent.approvedCost": "{employerName} will reimburse {approvedAmount} of the requested {activityCost} for {activityTitle}. To claim your employer subsidy, expense the fee as outlined in your <link>employer’s policy</link>.", // The applicant's company will reimburse them for the cost of the requested activity, they will then expense the approved amount
  "emails.request-approved.external.customContent.registrationDate": "{activityTitle} starts on {activityStartDate}", // Reminder about the activity's start date. Start date takes the format of this example: June 30, 2030
  "emails.request-approved.header.above.noName": "Good news,", // Email main title header
  "emails.request-approved.header.above.withName": "Good news {name},", // Email main title header with name
  "emails.request-approved.header.text2": "Let's submit your registration", // The email recipient's request has been approved
  "emails.request-approved.subject": "Your learning request has been approved", // The email recipient's request has been approved
  "emails.request-approved-activity-cost-changed.customContent.action": "We recommend requesting approval for the new price. To do so, cancel this request and submit a new one.", // Action a user can take
  "emails.request-approved-activity-cost-changed.customContent.priceChange": "The price for a {activityType} you requested, {activityTitle}, has {changeType} from {oldCost} to {newCost}.", // Price for an activty type/title has decreased/increased
  "emails.request-approved-activity-cost-changed.header": "The price for {activityTitle} has changed", // Email header
  "emails.request-approved-activity-cost-changed.subject": "A price update for your request", // Email subject

  "emails.request-cancelled.button.text": "View Details", // Text for the main button in the email, navigates user to their request page
  "emails.request-cancelled.customContent.cancelReason": "{activityTitle} has been cancelled by {providerName}, effective {cancellationDate}. The following reason was provided:", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled.header.above.apologyNoName": "Sorry,", // Email main header, if there is no learner name available
  "emails.request-cancelled.header.above.apologyWithName": "Sorry {learnerFirstName},", // Email main title header, if there is a learner name
  "emails.request-cancelled.header.text": "The program you registered for has been cancelled", // Email main title header
  "emails.request-cancelled.subject": "SkillsWave update: the program {learnerName} registered for has been cancelled", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-cancelled-by-employee.button.text": "View Request", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee.customContent.apology": "We’re sorry this didn’t work out. Explore other offerings that are supported by your employer.", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".
  "emails.request-cancelled-by-employee.customContent.cancelReason": "You have cancelled your request for {activityTitle}, effective {cancellationDate}. You provided the following reason:", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee.customContent.mistake": "If this was a mistake, <link>contact SkillsWave Support</link>", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".
  "emails.request-cancelled-by-employee.header.text": "You have successfully cancelled your request", // Email main header
  "emails.request-cancelled-by-employee.subject": "SkillsWave update: You successfully cancelled your request", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-cancelled-by-employee-to-approver.customContent.cancelReason": "We’re letting you know that {learnerName} cancelled their request for {activityTitle}, effective {cancellationDate}. The following reason was provided:", // Sentence describing the cancellation, with the reason for the cancellation following the colon (:)
  "emails.request-cancelled-by-employee-to-approver.customContent.noAction": "No action is required.", // The line of text informing the approver that they don't have to take any action for this email
  "emails.request-cancelled-by-employee-to-approver.subject": "SkillsWave update: {learnerName} cancelled their request", // Subject line of email that is sent to the applicant after their request has been cancelled. Do not translate "SkillsWave".

  "emails.request-failed.customContent.continuedSupport.employee": "We would love to continue to support you on your educational journey. <link>Explore other offerings that are supported by your employer.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.continuedSupport.jobseeker": "We would love to continue to support you on your educational journey. <link>Explore other offerings that are supported by your organization.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.continuedSupport.member": "We would love to continue to support you on your educational journey. <link>Explore other offerings that are supported by your association.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses supported by the reader's association. Please do not translate the <link> tags, just the inner text.
  "emails.request-failed.customContent.sorry": "We’re really sorry this program didn’t work out.", // Sentence expressing sympathy for the learner because their program was unsuccessful
  "emails.request-failed.customContent.thankYou": "Thank you for using SkillsWave. We hope you found {activityTitle} valuable in your own professional development.", // Sentence expressing gratitude to the learner for using "Wave". Do not translate "SkillsWave".
  "emails.request-failed.header.above.greetingNoName": "Hi,", // Email main title header, greetings to learner, if there is  no first name available
  "emails.request-failed.header.above.greetingWithName": "Hi {learnerFirstName},", // Email main title header, greetings to learner, if there is a learner name
  "emails.request-failed.subject": "SkillsWave update: your program status has been updated to failed", // Subject line of email that is sent to the applicant after their request has been marked as "failed". Do not translate "SkillsWave".

  "emails.request-paid.customContent.contact": "Contact", // Label in the email table for learner's email
  "emails.request-paid.customContent.name": "Name", // Label in the email table for learner's name
  "emails.request-paid.customContent.payment": "Payment", // Label in the email table for activity payment status
  "emails.request-paid.customContent.payment.text": "Paid {cost}", //  Value format in the email table for activity payment status
  "emails.request-paid.headerOptions.below.text": "Once registration opens, register this learner within {registrationTime} business days", // Main title subtext for email to university admin
  "emails.request-paid.headerOptions.text": "You have a new request", // Main title header for email to university admin
  "emails.request-paid.subject": "[New SkillsWave Learner] Register {name} into {activityTitle}", // Subject line for email to university admin. Do not translate "SkillsWave".

  "emails.request-passed.button.text": "View Details", // Text for the main button in the email, navigates user to their request page
  "emails.request-passed.customContent.continuedSupport.employee": "We would love to continue to support you on your educational journey. <link>Explore other offerings that are supported by your employer.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.continuedSupport.jobseeker": "We would love to continue to support you on your educational journey. <link>Explore other offerings that are supported by your organization.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.continuedSupport.member": "We would love to continue to support you on your educational journey. <link>Explore other offerings that are supported by your association.</link>", // Sentence expressing that support will continue to be provided, followed by a link to view more programs/courses supported by an association. Please do not translate the <link> tags, just the inner text.
  "emails.request-passed.customContent.skillsDeveloped": "Through completing this program, you developed skills in {numberOfSkills, select, greaterThanThree {{0}, {1}, {2}, {3} and more} two {{0} and {1}} three {{0}, {1}, and {2}} other {{0}}}.", // Sentence describing the skills that the learner developed by taking the program. A nested select is used to differentiate between the phrases when listing the skills in placeholders, as the number of skills can vary from 1 - 4+.
  "emails.request-passed.customContent.successful": "You have successfully completed {activityTitle} from {providerName}.", // Sentence describing the successful completion of the program from the academic provider/institution
  "emails.request-passed.header.above.congratulationNoName": "Congrats,", // Email main title header, congratulations to learner on completing their program, if there no first name available
  "emails.request-passed.header.above.congratulationWithName": "Congrats {learnerFirstName},", // Email main title header, congratulations to learner on completing their program, if there is a learner name
  "emails.request-passed.header.text": "You have completed {activityTitle}!", // Email title header, stating that the learner has successfully completed a program/course
  "emails.request-passed.subject": "SkillsWave update: {learnerName} has successfully completed {activityTitle}", // Subject line of email that is sent to the applicant after their request has been successfully completed. Do not translate "SkillsWave".

  "emails.request-received.customContent.1": "Your request has been received for {title} through {providerName}.", // First sentence of the email that gets sent to the applicant after their request has been made
  "emails.request-received.customContent.2": "It has been sent to your employer for review.", // Second sentence in the email
  "emails.request-received.customContent.2.association": "It has been sent to your association for review.", // Second sentence in the email if employer tenant is an association
  "emails.request-received.customContent.3.association": "If you’re approved, we’ll let you know how much your association will cover for the {courseType}.", // Second sentence in the email if employer tenant is an association
  "emails.request-received.external.customContent.1": "Your request has been received for {activityTitle}.", // First sentence of the email that gets sent to the applicant after their request has been made
  "emails.request-received.external.customContent.3": "If you’re approved, we’ll let you know how much your employer will cover.", // Part of last sentence of the email that gets sent to the applicant for an external request
  "emails.request-received.headerOptions.text": "Your learning request has been received", // Email main title header
  "emails.request-received.subject": "Your learning request has been received", // Subject line of email that is sent to the applicant after their request has been received

  "emails.request-registered.customContent.congratulations": "Congratulations! Your registration into {activityTitle} has been confirmed by {providerName}. They will contact you with {activityType} details at this email address.", // Notifying the requester of the confirmed registration and the activity start date
  "emails.request-registered.customContent.startDate": "Your {activityType} starts on {activityStartDate}.", // Reminder of the activity's start date. Start date takes the format of this example: June 30, 2030.
  "emails.request-registered.customContent.startSoon": "Your {activityType} is starting soon.", // Reminder that that the course/program is starting soon
  "emails.request-registered.customContent.viewRequest": "View your request for more information.", // Sentence to encourage recipient to view their request
  "emails.request-registered.header.text": "{providerName} has confirmed your registration", // Email title header, stating that the provider has confirmed the recipient's registration
  "emails.request-registered.subject": "{providerName} has registered you into {activityTitle}", // The email recipient's request has been registered

  "emails.request-rejected.customContent.declinedReason": "The request was declined because: {declinedReason}", // Reason why the approval request was declined
  "emails.request-rejected.customContent.toApprover": "The request for {learnerName} to take {activityTitle} has been declined by {declinerName}.", // Sentence in email that is sent to the approver
  "emails.request-rejected.customContent.toRequester": "Sorry {learnerFirstName}, your request for {activityTitle} has been declined.", // Sentence in email that is sent to the requester/learner
  "emails.request-rejected.headerOptions.text.toApprover": "{learnerName}’s learning request has been declined", // Email main title header
  "emails.request-rejected.headerOptions.text.toRequester": "Your learning request has been declined", // Email main title header
  "emails.request-rejected.subject.toApprover": "{learnerName}’s request has been declined", // Subject line of email that is sent to the applicant after their request has been rejected
  "emails.request-rejected.subject.toRequester": "Your learning request has been declined", // Subject line of email that is sent to the applicant after their request has been rejected

  "emails.request-withdrawn.button.text": "View Details", // Text for the main button in the email, navigates user to their request page
  "emails.request-withdrawn.customContent.withdrawal": "{providerName} has indicated that effective {cancellationDate}, you withdrew from {activityTitle}.", // Sentence describing the details of a learner's withdrawal from a course/program.
  "emails.request-withdrawn.header.above.apologyNoName": "Sorry to see you go,", // Email main header, if there is no learner name available. Expressing sympathy/apologies that the learner withdrew from the program.
  "emails.request-withdrawn.header.above.apologyWithName": "Sorry to see you go {learnerFirstName},", // Email main title header, if there is a learner name. Expressing sympathy/apologies that the learner withdrew from the program.
  "emails.request-withdrawn.header.text": "You withdrew from the program you registered for", // Email main title header
  "emails.request-withdrawn.subject": "SkillsWave update: {learnerName} withdrew from a program", // Subject line of email that is sent to the applicant after they have withdrawn from a course/program. Do not translate "SkillsWave".

  "emails.suggest.course.alternativeAction.text": "Can’t view this course? Try this link", // text for the alternative action link
  "emails.suggest.course.buttons.text": "View Course", // text for the action button
  "emails.suggest.course.headerOptions.text": "has suggested a course to you", // Email main title header
  "emails.suggest.course.subject": "{firstName} has suggested a course to you", // Subject line of email that is sent to the recipient when a user suggests a course for them.
  "emails.suggest.program.alternativeAction.text": "Can’t view this program? Try this link", // text for the alternative action link
  "emails.suggest.program.buttons.text": "View Program", // text for the action button
  "emails.suggest.program.headerOptions.text": "has suggested a program to you", // Email main title header
  "emails.suggest.program.subject": "{firstName} has suggested a program to you", // Subject line of email that is sent to the recipient when a user suggests a program for them.

  "error.404": "404 - Not found", // Error text when a client error 404 occurs (page not found)
  "error.error-loading": "There was an error when attempting to load SkillsWave", // Error text when there was a problem loading Wave. Do not translate "SkillsWave".
  "error.invalid-attribute": "User does not meet attribute requirements for {attributeName}",

  "faqs.title": "Frequently Asked Questions", // Title for FAQ page

  "form.submit": "Submit", // Submit button for forms

  "general.addActivity": "Add Activity", // Label for adding new activity button and page title.
  "general.admin": "Admin", // Navigation link to Wave administration experience (privileged user only), entitlement for university admins, heading for admin list.

  "general.button-text.apply": "Apply", // The label for apply buttons
  "general.button-text.cancel": "Cancel", // The label for cancel buttons
  "general.button-text.collapseAll": "Collapse all", // Label for an action that collapses collapsible interactive elements
  "general.button-text.expandAll": "Expand all", // Label for an action that expands all collapsible interactive elements
  "general.button-text.export": "Export", // The label for export buttons
  "general.button-text.no": "No", // The label for yes buttons
  "general.button-text.save": "Save", // The label for apply buttons
  "general.button-text.yes": "Yes", // The label for yes buttons

  "general.catalog-package.construction": "Construction",
  "general.catalog-package.custom-health-sciences": "Custom - Health Sciences",
  "general.catalog-package.custom-upskill-canada": "Custom - Upskill Canada",
  "general.catalog-package.general": "General",
  "general.catalog-package.new-general": "General New",
  "general.catalog-package.tech": "Development",

  "general.costTo.companyName": "Cost to {companyName}", // The label for the cost to the company
  "general.employee": "Employee",
  "general.employer": "Employer", // Text for employer filter, aria text and label for program employer filter dropdown, employer option for tenant type selection dropdown.
  "general.enabled": "Enabled", // Text for things like toggles or labels that would indicate that a setting is enabled
  "general.error": "Something went wrong", // Error message that appears when something goes wrong
  "general.jobseeker": "Jobseeker",
  "general.language": "Language", // Just the word 'language' for labeling things like language selectors or language settings
  "general.language.english": "English",
  "general.language.french": "French",
  "general.loading": "Loading...",
  "general.login": "Log In",
  "general.member": "Member",
  "general.provider": "Provider", // Aria text and label for program provider filter dropdown, provider option for tenant type selection dropdown, label for activity provider dropdown, label for course provider, label for provider in submit own request overview, heading for the provider name column.
  "general.recommended": "Recommended", // Text for recommended items
  "general.signup": "Sign Up",
  "general.skills": "Skills", // Label for Skills tab, header for skills section.

  "general.stream.DEIdiversityInclusionAccessibility": "DEI: Diversity, Inclusion, and Accessibility",
  "general.stream.accounting": "Accounting: Finance, Audit, and Risk Management",
  "general.stream.advancedManufacturing": "Advanced Manufacturing",
  "general.stream.agricultureTechnology": "Agriculture Technology",
  "general.stream.appliedLearning": "Applied Learning: Pharmacy Technicians, Opticians, and more",
  "general.stream.biomanufacturing": "Biomanufacturing",
  "general.stream.businessOperations": "Business Operations: Process Improvement, Agile, and Scrum",
  "general.stream.businessStrategyInnovationAdministration": "Business: Strategy, Innovation, and Business Administration",
  "general.stream.cleanTechnology": "Clean Technology",
  "general.stream.cloudComputingProgramming": "Cloud Computing: Cloud Essentials, Cloud Operations, and Architecture",
  "general.stream.communicationConfidenceInfluenceResolution": "Communication: Professional Confidence, Influence, and Conflict Resolution",
  "general.stream.constructionManagement": "Construction Management: Construction Contracts, Estimating, and Strategy",
  "general.stream.cybersecurity": "Cybersecurity",
  "general.stream.dataAnalytics": "Data Analytics: PowerBI, Excel, Data Analysis, and Visualization",
  "general.stream.dataScience": "Data Science: Data Modeling, Data Engineering, and Data Programming",
  "general.stream.design": "Design: Creative, Graphics, and UX/UI Design",
  "general.stream.digitalSkills": "Design: Creative, Graphics, and UX/UI Design",
  "general.stream.digitalTechnology": "Digital Technology",
  "general.stream.emergentTechnologies": "Emergent Technologies: Blockchain, Machine Learning, AI, and Metaverse",
  "general.stream.engineeringContinuingEducation": "Engineering: Continuing Education for Professional Engineers (P. Eng.)",
  "general.stream.growthAccelerators": "Growth Accelerators: ESL/FSL, Written Communications, and College Readiness",
  "general.stream.integratedTechnology": "IT: Cybersecurity, Information Security, and Information Privacy",
  "general.stream.leadership": "Leadership: Leader Essentials, Coaching, and Emotional Intelligence",
  "general.stream.lifestyle": "Lifestyle: Nutrition, Personal Enrichment, and Hobbies",
  "general.stream.management101": "Management 101: Team Development, Performance Management, and Managing Change",
  "general.stream.marketing": "Marketing: Digital Marketing, Customer Service, and Product Management",
  "general.stream.myList": "My List",
  "general.stream.onDemand": "On Demand",
  "general.stream.peopleCulture": "People and Culture: Talent Acquisition, Compensation, and Employee Relations",
  "general.stream.preConstruction": "Preconstruction: BIM, Passive Housing, and Environmental Design",
  "general.stream.projectManagement": "Project Management: Exam Preparation, Continuing Education, and Project Management Tools",
  "general.stream.safetyFirst": "Safety First: Occupational Health and Safety, WHMIS, and Workplace Safety",
  "general.stream.softwareDevelopment": "Software Development: Databases, Web, and Full Stack Development",
  "general.stream.supplyChainManagement": "Supply Chain Management: Logistics, Global Trade, and Lean Six Sigma Practices",
  "general.stream.sustainability": "Sustainability: Sustainable Management, Practices, and Corporate Social Responsibility",
  "general.stream.wellness": "Wellness: Digital Wellness, Emotional Wellbeing, and Work-Life Balance",
  "general.stream.writtenCommunication": "Written Communication: Business Communication, Technical Writing, and Language Learning",

  "general.value": "Value", // Label for generic inputs that require a value
  "general.view": "View", // Navigation link to view an activity, text for view activity button

  "home.academic.publicPortal.copy": "Learn new skills and supercharge your career with courses, professional certificates and degrees from world-class education providers", // Explains how academic partnerships help customers (found on public portal homepage)
  "home.academic.title": "Quality Education from Trusted Providers", // Header text for academic partnership section of homepage
  "home.brightspace.button": "Explore {BrightspaceName}",
  "home.help.button": "Contact Us", // Navigates user to support page
  "home.help.text": "We’re here to help answer your questions. Let us support you in your learning journey.", // Text for contact support section of homepage
  "home.help.title": "Need a hand?", // Title for contact support section of homepage
  "home.welcome.button": "Browse Programs", // Homepage button that will navigate user to course catalog
  "home.welcome.copy": "Find, request, and register for quality education opportunities that will help you reach your full potential.", // Explains how Wave works to customer (found on homepage)
  "home.welcome.header": "Develop new skills and grow your career", // Welcome banner text on homepage
  "home.welcome.publicPortal.copy": "Find, request, and register for learning and upskilling opportunities that will help advance your career", // Explains how Wave works to customer (found on public portal homepage)

  "image.alt.helpingHand": "Helping hand", // Alt text for an image which is of a helping hand (used in 'contact us' area)

  "in-place-dropdown.buttonSave": "Save",

  "info-copy.action-button.aria-label": "Copy to clipboard", // aria-label for copy button - clicking button copies associated data to user's clipboard

  "landing-stream.headingIntro": "Recommended for your goals",

  "login.collectEmail.description": "Find, request, and register for quality education opportunities that will help you reach your full potential.", // Description of D2L Wave displayed on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.errorUpdatingUser": "There was an issue updating the user account. Please try again. If the problem persists, please contact support.", // Error toast shown in case saving the email collection data fails
  "login.collectEmail.greeting.displayName": "Hi, {displayName}!", // Greeting displayed to user of SkillsWave displayed on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.greeting.generic": "Hi!", // Greeting displayed to user on email collection page which is shown before login completes, if user has no email yet
  "login.collectEmail.primaryButton": "Browse Programs", // Label/text shown on button on email collection page which is shown before login completes, if user has no email yet

  "login.general.privacyPolicy": "Personal information may be collected, used and disclosed in accordance with our <link>privacy policy</link>", // Label for privacy policy link. <link> tags are a placeholder for a hyperlink and should not be translated.

  "login.magiclink.documentTitle": "Loading... - SkillsWave", // Displayed during sign-in process while generating a magic link for login redirection. Do not translate "SkillsWave".
  "login.magiclink.emailInput": "Email", // Email input label
  "login.magiclink.invalidEmail": "Invalid email address", // Error message when the email address is invalid
  "login.magiclink.loading": "Loading magic link", // Displayed during sign-in process while generating a magic link for login redirection
  "login.magiclink.loginHeader": "Login", // Login header
  "login.magiclink.sendLoginLink": "Send Login Link", // Text on button to submit login link form
  "login.magiclink.submitted.1": "The login email has been sent to {email}.", // Text displayed during login redirect
  "login.magiclink.submitted.2": "Check your inbox for a link to log in.", // Text displayed during login redirect
  "login.marketing.companyName": "Company name", // Label for company name input
  "login.marketing.form1.introText": "Enter your email address to get started today!", // The branded text on the first form
  "login.marketing.form1.introTextHeader": "Ahoy! Upskilling ahead.", // The header for the flavour text on the first form
  "login.marketing.form1.submit": "Let’s get started", // Label for first marketing form submit button
  "login.marketing.form1.title": "Log In", // The title for the first form in the marketing page
  "login.marketing.form2.country": "Country", // Label for the country input
  "login.marketing.form2.introText": "Tell us a little bit more about yourself", // The the branded text on the first form
  "login.marketing.form2.introTextHeader": "Almost there!", // The header for the flavour text on the first form
  "login.marketing.form2.state": "State/Province", // Label for the state input
  "login.marketing.form2.submit": "Let’s go!", // Label for second marketing form submit button
  "login.marketing.form2.title": "Register", // The title for the second form in the marketing page
  "login.marketing.form3.introText.1": "Enter your email to see how you can empower your people to learn and grow by giving access to a curated catalog of courses, programs and micro-credentials.", // The first <p> on the showcase landing page
  "login.marketing.form3.introText.2": "Not ready to commit yet? No problem. Check out <link>SkillsWave</link> and how our holistic solution can help shape your workforce to accelerate growth.", // The second <p> on the showcase with the video link. Do not translate "SkillsWave" or "SkillsWave".
  "login.marketing.form3.introTextHeader": "Hello! Welcome to our Product Showcase experience.", // The header for the flavour text on the showcase landing page
  "login.marketing.form3.submit": "Explore now", // Label for first showcase landing page form submit button
  "login.marketing.form3.title": "Log In", // The title for the first form in the showcase landing page
  "login.marketing.fullName": "Full Name", // Label for full name input
  "login.marketing.marketingAgreement": "Yes, I would like to receive communications from the SkillsWave family of companies", // Label for marketing agreement checkbox
  "login.marketing.marketingAgreement.required": "Marketing agreement", // Label for marketing agreement required error
  "login.marketing.marketingAgreement.tooltip": "In order to try SkillsWave, you need to agree to marketing communications", // Tooltip to explain why continue button is disabled. Do not translate "SkillsWave".
  "login.marketing.title": "Job Title", // Label for job title input
  "login.marketing.workEmail": "Work Email", // Label for work email input

  "login.multi.header": "Log In", // The header for the multi login screen
  "login.multi.subHeader": "Choose your organization", // The sub header in the login box for the multi login screen

  "login.saml.redirect": "You are being redirected to log in", // Text displayed during login redirect

  "manage-budget-approvals.approvals.title.label": "Approval Model", // Title for Approval section on the page
  "manage-budget-approvals.budget.title.label": "Budget", // Title for Budget section on the page
  "manage-budget-approvals.caseByCase": "Case-by-case", // Label for radio button for case-by-case budget setting
  "manage-budget-approvals.caseByCase.confirmation": "The amount of coverage employees receive will be determined by the approver on a case-by-case basis.", // Confirmation message shown when budget configuration is set as case-by-case
  "manage-budget-approvals.caseByCase.disabled.tooltipText": "The case-by-case budget cannot be enabled when the hybrid approval model is selected.", // A tooltip description explaining why the radio button is disabled
  "manage-budget-approvals.corporatePolicy.employee": "Fixed amount (per employee)", // Label for radio button for corporate policy
  "manage-budget-approvals.corporatePolicy.jobseeker": "Fixed amount (per jobseeker)", // Label for radio button for corporate policy
  "manage-budget-approvals.corporatePolicy.member": "Fixed amount (per member)", // Label for radio button for corporate policy
  "manage-budget-approvals.employee.annualCredit.label": "Annual Employee Credit", // Label for annual employee credit input text box
  "manage-budget-approvals.fiscalStartDate.label": "Fiscal Year Start Date", // Label for fiscal year start date input
  "manage-budget-approvals.jobseeker.annualCredit.label": "Annual Jobseeker Credit", // Label for annual jobseeker credit input text box
  "manage-budget-approvals.member.annualCredit.label": "Annual Member Credit", // Label for annual member credit input text box
  "manage-budget-approvals.percentCoverage": "Percent coverage", // Label for radio button for percent coverage
  "manage-budget-approvals.percentCoverage.label": "Percent Coverage Per Request", // Label for percent coverage percentage input
  "manage-budget-approvals.saveButton": "Save", // Save button text
  "manage-budget-approvals.saveButton.saved": "Saved", // Button text when budget settings has been saved
  "manage-budget-approvals.savedToast": "Tenant budget info saved", // Text for toast that confirms that budget settings have been saved
  "manage-budget-approvals.subtitle": "Can be changed later but will impact where funds are drawn from for education requests", // Subtitle for budget tab explaining the impact of budget configuration
  "manage-budget-approvals.title": "Budget and Approvals", // Title for budget & approval tab

  "manage-custom-attributes.application.description": "Values of these custom attributes will be stored on application records.", // Informational text about where attributes will be saved
  "manage-custom-attributes.application.heading": "Edit application custom attributes", // Heading for application custom attributes section of the page
  "manage-custom-attributes.application.label": "Application custom attributes", // Label for the editor section where users can edit custom attributes
  "manage-custom-attributes.defaultNameValue": "New custom attribute", // Default name if user adds a new custom attribute
  "manage-custom-attributes.helpText": "Detailed documentation about Custom Attributes can be found in Confluence", // Text which is a link to some documentation
  "manage-custom-attributes.save.applicationAttributesInvalid.json": "Invalid JSON in application custom attributes", // Message which appears on save if there is an issue with the JSON formatting for application attributes
  "manage-custom-attributes.save.applicationAttributesInvalid.schema": "Invalid application custom attributes", // Message which appears on save if there is an issue with the schema formatting for application attributes
  "manage-custom-attributes.save.bothAttributesInvalid.json": "Invalid JSON in user custom attributes and application custom attributes", // Message if there is a problem with the JSON on both custom attribute types
  "manage-custom-attributes.save.bothAttributesInvalid.schema": "Invalid user custom attributes and application custom attributes", // Message if there is a problem with the schema on both custom attribute types
  "manage-custom-attributes.save.success": "Custom attributes saved", // Message which appears when attributes were saved successfully
  "manage-custom-attributes.save.userAttributesInvalid.json": "Invalid JSON in user custom attributes", // Message which appears on save if there's an issue with the JSON formatting
  "manage-custom-attributes.save.userAttributesInvalid.schema": "Invalid user custom attributes", // Message which appears on save if there is an issue with the data matching the schema
  "manage-custom-attributes.title": "Custom attributes", // Title for custom attributes tab in the admin section
  "manage-custom-attributes.user.description": "Values of these custom attributes will be stored on user records.", // Informational text about where attributes will be saved
  "manage-custom-attributes.user.heading": "Edit user custom attributes", // Heading for user custom attributes section of the page
  "manage-custom-attributes.user.label": "User custom attributes", // Label for the editor section where users can edit custom attributes

  "manage-general.accountConnect.studentID": "Require Student ID",
  "manage-general.accountConnect.studentID.format": "student ID format",
  "manage-general.adminRoles": "Enable permission based role", // Tooltip for check if business is Canada based
  "manage-general.brightspace.name": "Brightspace Name",
  "manage-general.brightspace.url": "Brightspace URL",
  "manage-general.buttonSave": "Save",
  "manage-general.isCanadianBusiness": "This is a Canadian business", // Text for check if business is Canada based
  "manage-general.label.accentColour": "Accent Colour", // Text for accent colour label
  "manage-general.label.daysUntilInvoiceDue": "Days Until Invoice is Due", // Text for accent colour label
  "manage-general.label.financeEmail": "Finance Email", // Text for finance email label
  "manage-general.label.programPolicy": "Program Policy (include url scheme)", // Text for program policty label
  "manage-general.label.studentSupportEmail": "Student Support Email", // Text for student support email label
  "manage-general.learnerTerminology": "Learner Terminology", // Text for learner terminology options
  "manage-general.notes": "Admin notes", // Label for Admin notes
  "manage-general.revShare.fixed": "Fixed Amount", // Text for revenue share option: fixed amount
  "manage-general.revShare.percent": "Portion (in %) of Activity Cost", // Text for revenue share option: portion of activity cost
  "manage-general.tags.allowEditableEmail": "Allow user email to be editable",
  "manage-general.tags.automaticCatalogOptOut": "Opt-out of automatic catalog updates for new activities",
  "manage-general.tags.collectEmailOnLogin": "Collect emails upon login if user doesn’t have one", // Text displayed beside checkbox option to determine if we collect emails on login or not
  "manage-general.tags.enableLinkToBrightspace": "Enable “Link to Brightspace”",
  "manage-general.tags.hideFastToCompleteStream": "Hide the “{streamName}” stream from the Programs page", // Text label for a checkbox that will hide the 'fastToComplete' dynamic carousel of courses
  "manage-general.tags.ownRequestSubmit": "Enable “Submit Your Own Request”", // Text for own submit request option
  "manage-general.tags.ownRequestSubmitTooltip": "This setting should only be enabled for employers who have purchased the “Submit Your Own Request” add-on feature", // Tooltip text for own submit request option
  "manage-general.tags.paymentHandledByProvider": "Payment and registration handled by provider", // Text labe for a checkbox handled by provider
  "manage-general.userDataSystem": "User Data Origin System", // Text for User Data System

  "manage-provider-finance.title": "Finance", // Title for finance tab

  "manage-role.field.roleDescription.description": "Use this section to describe what permissions this role is associated with. ", // description for the role description input
  "manage-role.field.roleDescription.label": "Role Description", // Label for the role description input
  "manage-role.field.roleName.label": "Name", // Label for the role name input
  "manage-role.heading.permissions": "Permissions", // Heading for permissions section
  "manage-role.submit": "Submit", // Submit button text for the manage-role page

  "manage-user-permissions.documentTitle": "User Permissions - SkillsWave", // Document title for user permissions page,
  "manage-user-permissions.emailInputLabel": "Email", // Label for the user email input,
  "manage-user-permissions.heading": "Update User Permissions", // Heading for the manage user permissions page
  "manage-user-permissions.pageDescription": "Update the roles that this user is associated with, so they can access the parts of SkillsWave admin they need for their day to day activities.", // Description text for the manage user permissions page
  "manage-user-permissions.rolesHeading": "Roles", // Header text for the Roles heading
  "manage-user-permissions.titleInputLabel": "Title", // Label for the user title input
  "manage-user-permissions.updateUserButton": "Update User", // Label for the update user button
  "manage-user-permissions.userActiveSwitchLabel": "User Active", // Label for the user active switch
  "manage-user-permissions.userNameInputLabel": "User Name", // Label for the user name input

  "no-results.message": "No results for your currently selected filters", // Text displayed when there are no activities that fit the current filters

  "nova-json-input.addElement.buttonText": "Add element", // text appearing on button that lets user add a new element to an array in the JSON object editor
  "nova-json-input.addField.buttonText": "Add field", // text appearing on button that lets user add a new JSON key value pair in the JSON object editor
  "nova-json-input.addField.menuLabel": "Type of value", // label for "add field" sub-menu, visible to screenreaders
  "nova-json-input.arrayIndex": "index [{index}]", // counting indices of arrays for the label beside array values
  "nova-json-input.deleteField.buttonText": "Delete this key-value pair", // text for the delete field button, visible to screenreaders only
  "nova-json-input.headers.key": "Key", // Table heading for JSON Key inputs which will appear in this column
  "nova-json-input.headers.remove": "Remove", // Aria label for table heading which has no visible text, this is for screenreaders. The column has an action button in it, e.g. delete (maybe more in future)
  "nova-json-input.headers.type": "Type", // Table heading for JSON Type values which will appear in this column
  "nova-json-input.headers.value": "Value", // Table heading for JSON Value inputs which will appear in this column
  "nova-json-input.mainEditor.label": "Form view", // label on the main editing tab of a JSON editor component
  "nova-json-input.rawJsonPreview.invalid": "The editor cannot be rendered because the JSON is invalid. You can edit it in the JSON view or reload the page to return to the original state.", // Message displayed when the JSON is made invalid
  "nova-json-input.rawJsonPreview.label": "JSON view", // label on the raw json preview tab of a JSON editor component
  "nova-json-input.value.label": "Value for {key} key", // hidden label of value field, visible to screenreaders

  "nova-json-input-array.addObject": "Add object", // Text for button that adds a JSON object to the array
  "nova-json-input-array.contextMenu.deleteObject": "Delete object", // Title of an object section, the index will be a positive integer
  "nova-json-input-array.contextMenu.duplicateObject": "Duplicate object", // Title of an object section, the index will be a positive integer
  "nova-json-input-array.contextMenu.label": "Actions", // Label for a context menu that enables some action on a form input
  "nova-json-input-array.empty": "There are no objects in this list. Use the “Add object” button to add one.", // If the array is empty, tell the user they can add an object use the add object button
  "nova-json-input-array.objectTitle": "Object {index}", // Title of an object section, the index will be a positive integer

  "onboarding-personalization-flow.skillSelection.backButton": "Go Back",
  "onboarding-personalization-flow.skillSelection.backButtonMobile": "Back",
  "onboarding-personalization-flow.skillSelection.header": "Suggested Skill Sets for {title}",
  "onboarding-personalization-flow.skillSelection.nextButton": "Set Career Goals",
  "onboarding-personalization-flow.skillSelection.search": "Search for skill sets",
  "onboarding-personalization-flow.skillSelection.skipButton": "Skip For Now",
  "onboarding-personalization-flow.skillSelection.skipButtonMobile": "Skip",
  "onboarding-personalization-flow.skillSelection.subheader": "For best results, pick at least 3 skill sets",
  "onboarding-personalization-flow.skillSelection.subtitle": "Pick skill sets you want to build and we'll find courses for you. You can change this later.",
  "onboarding-personalization-flow.skillSelection.title": "Sharpen your skills",

  "onboarding-personalization-flow.titleSelection.empty": "Search for a career to begin",
  "onboarding-personalization-flow.titleSelection.growthOpportunities": "Growth Opportunities",
  "onboarding-personalization-flow.titleSelection.loadMore": "Load More",
  "onboarding-personalization-flow.titleSelection.meterText": "Skills Match",
  "onboarding-personalization-flow.titleSelection.nextButton": "Get Recommendations",
  "onboarding-personalization-flow.titleSelection.noResults": "No results for: {search}",
  "onboarding-personalization-flow.titleSelection.relatedCareers": "Related Careers",
  "onboarding-personalization-flow.titleSelection.relatedCareersToJobTitle": "Related careers to {jobTitle}",
  "onboarding-personalization-flow.titleSelection.relevantSkills": "Relevant Skills",
  "onboarding-personalization-flow.titleSelection.search": "Search careers",
  "onboarding-personalization-flow.titleSelection.subtitle": "Pick careers you're interested in for more course recommendations. You can change this later.",
  "onboarding-personalization-flow.titleSelection.title": "Set career goals",
  "onboarding-personalization-flow.titleSelection.transferrableSkills": "Transferrable Skills",

  "permissions.category.activity.description": "Create, edit and make changes to the activities (courses and programs) in the catalog", // Description for the activity settings category
  "permissions.category.activity.label": "Activity Settings", // Label for the activity settings category
  "permissions.category.analytics.description": "Analytics for activities, and employers ", // Description for the analytics category
  "permissions.category.analytics.label": "Analytics Tab", // Label for the analytics category
  "permissions.category.employer.description": "Create employer tenants for our corporate clients and make changes to current employer tenant settings", // Description for the employer settings category
  "permissions.category.employer.label": "Employer Settings", // Label for the employer settings category
  "permissions.category.employer:update.description": "Edit the tabs within the employer tenant settings", // Description for the employer edit permission
  "permissions.category.employer:update.label": "Edit Employer", // Label for the employer edit permission
  "permissions.category.employer:view.description": "View the tabs within the employer tenant settings", // Description for the employer view permission
  "permissions.category.employer:view.label": "View Employer", // Label for the employer view permission
  "permissions.category.features.description": "Turn on or off feature flags for provider or employer tenants", // Description for the features category
  "permissions.category.features.label": "Feature Tab", // Label for the feature settings category
  "permissions.category.permissions.description": "Create provider tenants for ed provider partners and make changes to current provider tenant settings", // Description for the permissions category
  "permissions.category.permissions.label": "Permissions Tab", // Label for the permissions category
  "permissions.category.provider.description": "Settings for provider tenants", // Description for the provider settings category
  "permissions.category.provider.label": "Provider Settings", // Label for the provider settings category
  "permissions.category.provider:update.description": "Edit the tabs within the provider tenant settings", // Description for the provider edit permission
  "permissions.category.provider:update.label": "Edit Provider", // Label for the provider edit permission
  "permissions.category.provider:view.description": "View the tabs within the provider tenant settings", // Description for the provider view permission
  "permissions.category.provider:view.label": "View Provider", // Label for the provider view permission
  "permissions.category.requests.description": "Requests for activities from all clients", // Description for the requests category
  "permissions.category.requests.label": "Requests Tab", // Label for the requests category

  "permissions.permission.activity:archive.description": "Archive an activity so it is not visible on the education providers catalog", // Description for the archive activity permission
  "permissions.permission.activity:archive.label": "Archive Activity", // Label for the archive activity permission
  "permissions.permission.activity:create.description": "Add new activities to the catalog.", // Description for the create activity permission
  "permissions.permission.activity:create.label": "Add Activity", // Label for the create activity permission
  "permissions.permission.activity:export.description": "Export a CSV of activities with custom columns.", // Description for the activity export permission
  "permissions.permission.activity:export.label": "Export Activities", // Label for the activity export permission
  "permissions.permission.activity:status:update.description": "Turn activities active or inactive within the catalog.", // Description for the activity activation permission
  "permissions.permission.activity:status:update.label": "Activity Activation", // Label for the activity activation permission
  "permissions.permission.activity:streams:import.description": "Import activities to re-categorize what activities are within a stream.", // Description for the activity import permission
  "permissions.permission.activity:streams:import.label": "Import Activities into Streams", // Label for the activity import permission
  "permissions.permission.activity:update.description": "Make changes to all existing activities in the catalog.", // Description for the update activity permission
  "permissions.permission.activity:update.label": "Edit Activity", // Label for the update activity permission
  "permissions.permission.activity:view.description": "See the list of activities in the catalog and view activity settings.", // Description for the view activity permission
  "permissions.permission.activity:view.label": "View Activity", // Label for the view activity permission
  "permissions.permission.analytics:view.description": "See and filter analytics for all tenants.", // Description for the view analytics permission
  "permissions.permission.analytics:view.label": "View Analytics", // Label for the view analytics permission

  "permissions.permission.employer:audit:update.label": "Audit tab", // Label for the audit tab permission
  "permissions.permission.employer:audit:view.label": "Audit tab", // Label for the audit tab permission"
  "permissions.permission.employer:careerexplorer:update.label": "Career Explorer tab", // Label for the career explorer tab permission
  "permissions.permission.employer:careerexplorer:view.label": "Career Explorer tab", // Label for the career explorer tab permission
  "permissions.permission.employer:create.description": "Add an employer tenant", // Description for the create employer permission
  "permissions.permission.employer:create.label": "Add Employer", // Label for the create employer permission
  "permissions.permission.employer:customattributes:update.label": "Custom Attributes tab", // Label for the custom attributes tab permission
  "permissions.permission.employer:customattributes:view.label": "Custom Attributes tab", // Label for the custom attributes tab permission
  "permissions.permission.employer:enabledstreams:update.label": "Enabled Streams tab", // Label for the Enabled Streams tab permission
  "permissions.permission.employer:enabledstreams:view.label": "Enabled Streams tab", // Label for the Enabled Streams tab permission
  "permissions.permission.employer:entitlements:update.label": "Entitlements tab", // Label for the entitlements tab permission
  "permissions.permission.employer:entitlements:view.label": "Entitlements tab", // Label for the entitlements tab permission
  "permissions.permission.employer:faq:update.label": "FAQ tab", // Label for the FAQ tab permission
  "permissions.permission.employer:faq:view.label": "FAQ tab", // Label for the FAQ tab permission
  "permissions.permission.employer:finance:update.label": "Budgets & Approval tab", // Label for the finance tab permission
  "permissions.permission.employer:finance:view.label": "Budgets & Approval tab", // Label for the finance tab permission
  "permissions.permission.employer:general:update.label": "General tab", // Label for the general tab permission
  "permissions.permission.employer:general:view.label": "General tab", // Label for the general tab permission
  "permissions.permission.employer:howitworks:update.label": "How it Works tab", // Label for the how it works tab permission
  "permissions.permission.employer:howitworks:view.label": "How it Works tab", // Label for the how it works tab permission
  "permissions.permission.employer:login:update.label": "Login tab", // Label for the login tab permission
  "permissions.permission.employer:login:view.label": "Login tab", // Label for the login tab permission
  "permissions.permission.employer:termsofuse:update.label": "Employer Terms of Use tab", // Label for the terms of use tab permission
  "permissions.permission.employer:termsofuse:view.label": "Employer Terms of Use tab", // Label for the terms of use tab permission
  "permissions.permission.employer:visibility:update.label": "Visibility tab", // Label for the login tab permission
  "permissions.permission.employer:visibility:view.label": "Visibility tab", // Label for the login tab permission

  "permissions.permission.features:update.description": "Make changes to the feature flags (turn off or on) for tenants.", // Description for the edit features permission
  "permissions.permission.features:update.label": "Edit Features", // Label for the edit features permission

  "permissions.permission.features:view.description": "See the list of feature flags that ar available for tenants.", // Description for the view and edit features permission
  "permissions.permission.features:view.label": "View Features", // Label for the view and edit features permission

  "permissions.permission.permissions.description": "View and make changes to all the sections within the permissions tab.", // Description for the view and edit permissions permission
  "permissions.permission.permissions.label": "View & Edit Permissions", // Label for the view and edit permissions permission

  "permissions.permission.provider:activityinstructions:update.label": "Activity Instructions tab", // Label for the terms of use tab permission
  "permissions.permission.provider:activityinstructions:view.label": "Activity Instructions tab", // Label for the terms of use tab permission
  "permissions.permission.provider:audit:update.label": "Audit tab", // Label for the audit tab permission
  "permissions.permission.provider:audit:view.label": "Audit tab", // Label for the audit tab permission
  "permissions.permission.provider:careerexplorer:update.label": "Career Explorer tab", // Label for the career explorer tab permission
  "permissions.permission.provider:careerexplorer:view.label": "Career Explorer tab", // Label for the career explorer tab permission
  "permissions.permission.provider:create.description": "Add a provider tenant", // Description for the create provider permission
  "permissions.permission.provider:create.label": "Add Provider", // Label for the create provider permission
  "permissions.permission.provider:customattributes:update.label": "Custom Attributes tab", // Label for the custom attributes tab permission
  "permissions.permission.provider:customattributes:view.label": "Custom Attributes tab", // Label for the custom attributes tab permission
  "permissions.permission.provider:entitlements:update.label": "Entitlements tab", // Label for the entitlements tab permission
  "permissions.permission.provider:entitlements:view.label": "Entitlements tab", // Label for the entitlements tab permission
  "permissions.permission.provider:faq:update.label": "FAQ tab", // Label for the FAQ tab permission
  "permissions.permission.provider:faq:view.label": "FAQ tab", // Label for the FAQ tab permission
  "permissions.permission.provider:finance:update.label": "Finance tab", // Label for the finance tab permission
  "permissions.permission.provider:finance:view.label": "Finance tab", // Label for the finance tab permission
  "permissions.permission.provider:general:update.label": "General tab", // Label for the general tab permission
  "permissions.permission.provider:general:view.label": "General tab", // Label for the general tab permission
  "permissions.permission.provider:howitworks:update.label": "How it Works tab", // Label for the how it works tab permission
  "permissions.permission.provider:howitworks:view.label": "How it Works tab", // Label for the how it works tab permission
  "permissions.permission.provider:login:update.label": "Login tab", // Label for the login tab permission
  "permissions.permission.provider:login:view.label": "Login tab", // Label for the login tab permission

  "permissions.permission.requests:update.description": "Make changes to request statuses, prior to registration", // Description for the edit requests permission
  "permissions.permission.requests:update.label": "Edit Requests", // Label for the edit requests permission

  "permissions.permission.requests:view.description": "See and filter the list of requests for all end-users of SkillsWave", // Description for the view requests permission
  "permissions.permission.requests:view.label": "View Requests", // Label for the view requests permission

  "readonly-dialog.marketingMode.message": "We can do something different here if marketing is enabled.", // Text displayed notifying user is in marketing mode
  "readonly-dialog.readOnlyMode.message": "This tenant is in read-only mode. Nothing can be submitted when in read-only mode.", // Text displayed notifying user is in read only mode

  "redirect.skillswave.documentTitle": "Redirecting to SkillsWave",
  "redirect.skillswave.linkText": "go to Skillswave now",
  "redirect.skillswave.message": "This will only take a moment, or ",
  "redirect.skillswave.title": "Redirecting you to",

  "registration-call-to-action.alertButtonText": "View", // The label that goes on the registration call to action button
  "registration-call-to-action.viewApplication": "<strong>Action required:</strong> Complete your registration for <strong>{activityTitle}</strong>",
  "registration-call-to-action.viewApplications": "<strong>Action required:</strong> Complete your registrations",

  "report.adoption.approved": "Approved", // The label for the approved requests
  "report.adoption.cancelledBy.employee": "Cancelled by employee", // The label for the cancelled by employee requests
  "report.adoption.cancelledBy.jobseeker": "Cancelled by jobseeker", // The label for the cancelled by jobseeker requests
  "report.adoption.cancelledBy.member": "Cancelled by member", // The label for the cancelled by member requests
  "report.adoption.cancelledByLearner": "Cancelled by learner", // The label for the cancelled by learner requests
  "report.adoption.declined": "Declined", // The label for the declined requests
  "report.adoption.header": "Requests", // The header for the adoption report widget
  "report.adoption.pending": "Pending", // The label for the pending requests
  "report.adoption.title": "Adoption", // The title for the adoption report widget
  "report.awareness.dailyAverage": "Daily Average", // The label for the daily average logins
  "report.awareness.header": "Unique Logins", // The header for the awareness report widget
  "report.awareness.title": "Awareness", // The header for the awareness report widget
  "report.awareness.totalLogins": "Total Logins", // The label for the total logins
  "report.emptyStateMsg": "No data is available based on the filters you have selected", // The message displayed when a report has no data without filters
  "report.investment.costToCompany": "Cost to Company", // The label for the cost to the company
  "report.investment.costToEmployees": "Cost to Employees", // The label for the cost to employees
  "report.investment.employee.costs": "Cost to Employees", // The label for the cost to employees
  "report.investment.header": "Total Approved", // The header for the investment report widget
  "report.investment.jobseeker.costs": "Cost to Jobseekers", // The label for the cost to jobseeker
  "report.investment.member.costs": "Cost to Members", // The label for the cost to members
  "report.investment.pending": "Pending", // The label for the pending costs
  "report.investment.title": "Investment", // The header for the investment report widget
  "report.progress.completed": "Completed", // The label for the number of completed requests
  "report.progress.header": "Total Approved", // The header for the investment report widget
  "report.progress.other": "Unsuccessful/other", // The label for the number of unsuccessful requests or requests with another state
  "report.progress.registered": "Registered", // The label for the number of registered requests
  "report.progress.registrationStarted": "Registration Started", // The label for the number of registration started requests
  "report.progress.title": "Progress", // The header for the investment report widget
  "report.skillsDemand.emptyStateMsg": "Skills with the most requests will appear here", // The message displayed when a skillsDemand report has no data
  "report.skillsDemand.heading1": "Skill", // The heading for the skill name column
  "report.skillsDemand.heading2": "Requests", // The heading for the number of requests column
  "report.skillsDemand.title": "Skills Demand", // header for the top departments report widget
  "report.topActivities.emptyStateMsg": "Programs with the most requests will appear here", // The message displayed when a topActivities report has no data
  "report.topActivities.numRequest": "{numRequests} request", // The number of requests in the top activities report (Case 1: Exactly 1 request)
  "report.topActivities.numRequests": "{numRequests} requests", // The number of requests in the top activities report (Case 2: More than 1 request)
  "report.topActivities.title": "Top Programs", // Title for the topActivities report widget
  "report.topDepartments.emptyStateMsg": "Departments with the most requests will appear here", // The message displayed when a topDepartments report has no data
  "report.topDepartments.heading1": "Department", // The heading for the department name column
  "report.topDepartments.heading2": "Requests", // The heading for the number of requests column
  "report.topDepartments.title": "Top Departments", // header for the top departments report widget
  "report.topProviders.emptyStateMsg": "Program providers with the most requests will appear here", // The message displayed when a topProviders report has no data
  "report.topProviders.heading2": "Requests", // The heading for the number of requests column
  "report.topProviders.title": "Top Providers", // header for the top departments report widget

  "review-application.back.button": "Go Back", // Form button to go back to previous page
  "review-application.documentTitle": "Review My Request - SkillsWave", // Document title when viewing the second page of the application process (review application). Do not translate "SkillsWave".
  "review-application.overview.approverNames": "Approver Name(s)", // Label for approver names in review application overview
  "review-application.title": "Review My Request", // Title for draft application review page

  "skill-chip.remove-button.text": "Remove skill", // accessibility text for skill chip removal button, shown to admin users
  "skill-chip-list.numSkills": "{number, plural, one {# skill} other {# skills}}",

  "sso.attribute-requirement.description": "When a user is logging in to Wave, the attributes in their user object must pass ALL of these regex checks before they will be granted access.", // Text describing how attribute requirements are used
  "sso.attribute-requirement.save": "Save requirements", // Text for attribute requirements save button
  "sso.attribute-requirement.save.fail": "Error saving attribute requirements", // Text for attribute requirements save failure toast
  "sso.attribute-requirement.save.success": "Attribute requirements saved", // Text for attribute requirements save success toast
  "sso.attribute-requirement.title": "Attribute requirements", // Title text for attribute requirements
  "sso.checkDomain": "Check domain", // The check domain flag test
  "sso.checkDomain.help": "When disabled, this magic link login domain will allow ANY email address to log in", // The tooltip message describing what the checkDomain flag is used for
  "sso.configuration.title": "SSO Configuration", // Title for the attribute configuration
  "sso.copy.url": "Copy URL", // Alt text for copy url button
  "sso.copy.url.toast": "Copied callback URL {callbackUrl} to clipboard", // Toast message for when url is copied
  "sso.create": "Create", // Alt text for create button
  "sso.default": "Default domain", // Whether this domain is the default domain to be used when the domain for a user can't be determined
  "sso.default.help": "This domain will be used when a users domain can’t be determined. NOTE: This field will be disabled if another domain is listed as the default", // Whether this domain is the default domain to be used when the domain for a user can't be determined
  "sso.delete": "Delete", // Alt text for delete button
  "sso.delete.error": "Error deleting SSO object {name}. Error: {message}.", // Error toast for when it fails to delete the SSO object
  "sso.delete.success": "Successfully deleted SSO object {name}", // Success toast for when it deletes the SSO object
  "sso.dialog.create": "Create Login", // Title for create login dialog
  "sso.dialog.edit": "Edit Login", // Title for edit login dialog
  "sso.domain": "Domain", // The domain attribute display name
  "sso.domain.help": "This is the domain the user can navigate to log in to SkillsWave. This cannot be modified after creation", // The tooltip message describing what the domain is used for. Do not translate "SkillsWave"
  "sso.edit": "Edit SSO info", // Alt text for edit SSO object button
  "sso.imageUrl": "Image URL", // The image url display name
  "sso.loginType": "Login Type", // The loginType attribute display name
  "sso.ml.emailDomain": "Email Domain", // The emailDomain attribute display name
  "sso.name": "Name", // The name attribute display name
  "sso.not-configured": "SSO is not configured for this tenant. Login is not possible when SSO is not configured.", // Warning message for when sso is not configured
  "sso.saml.cert": "Certificate", // The SAML cert attribute display name
  "sso.saml.enablePublicPortal": "Enable Public Portal", // The SAML enable public portal display name
  "sso.saml.entryPoint": "Entry Point", // The saml entry point display name
  "sso.saml.identifierFormat": "Identifier Format", // The format of the name provided by the identity provider
  "sso.saml.issuer": "Issuer", // The saml issuer attribute display name
  "sso.saml.logoutUrl": "Logout URL", // URL that is redirected to for SSO logout
  "sso.saml.mapping": "Mapping", // The attribute mapping display name
  "sso.save.error": "Error saving SSO object {name}. Error: {message}", // The error toast when it fails to save an SSO object
  "sso.save.success": "Successfully saved SSO object {name}", // The success toast when it successfully saves an SSO object

  "streams.ordered.recentlyAdded": "Recently Added", // Title for group of activites that have been recently added
  "streams.ordered.startingSoon": "Starting Soon", // Title for group of activites that have a start date in the near future
  "streams.property.fastToComplete": "Complete in 8 Hours or Less", // Title for stream of activities that a user can complete in a short amount of time.
  "streams.property.trending": "Trending at {tenantName}", // Title for stream of activities that are trending at the users company.  {tenantName} is a proper noun.

  "submit-own-request.breadcrumb": "Back to requests", // breadcrumb label for submit my own request page
  "submit-own-request.documentTitle": "Submit My Own Request - SkillsWave", // Document title when viewing the smunit-own-request page. Do not translate "SkillsWave".
  "submit-own-request.form.course.cost": "Cost", // label for course cost
  "submit-own-request.form.course.cost.tooltip": "Make sure to include all taxes and extra fees", // tooltip text for course cost input box
  "submit-own-request.form.course.date.enddate": "End Date", // label for course end date
  "submit-own-request.form.course.date.startdate": "Start Date", // label for course start date
  "submit-own-request.form.course.description": "Description", // label for course description
  "submit-own-request.form.course.title": "Title", // label for course title
  "submit-own-request.form.course.type": "What kind of training is this?", // label for course type dropdown
  "submit-own-request.form.course.type.option.conference": "Conference", // label for type conference
  "submit-own-request.form.course.type.option.course": "Course", // label for type course
  "submit-own-request.form.course.type.option.other": "Other", // label for type other
  "submit-own-request.form.course.type.option.program": "Program", // label for type program
  "submit-own-request.form.course.type.option.textbook": "Textbook", // label for type textbook
  "submit-own-request.form.course.type.option.webinar": "Webinar", // label for type webinar
  "submit-own-request.form.course.type.option.workshop": "Workshop", // label for type workshop
  "submit-own-request.form.website.label": "Website URL", // label for Website input textbox
  "submit-own-request.introduction.double": "You can request approval for professional development outside of SkillsWave.<linebreak></linebreak> This request will be approved by: {approverOne} and {approverTwo}.", // Text to inform user of both approvers for their application
  "submit-own-request.introduction.single": "You can request approval for professional development outside of SkillsWave.<linebreak></linebreak> This request will be approved by: {approver}.", // Text to inform user of approver for their application
  "submit-own-request.loading": "Loading...",
  "submit-own-request.request-overview.date": "Date(s)", // label for date range in submit own request overview
  "submit-own-request.request-overview.description": "Description", // label for description in submit own request overview
  "submit-own-request.request-overview.title": "Title", // label for title in submit own request overview
  "submit-own-request.request-overview.type": "Type", // label for course type in overview
  "submit-own-request.request-overview.website": "Website URL:", // label for website url in submit own request overview
  "submit-own-request.select.title": "What I Want to Learn",
  "submit-own-request.title": "Submit My Own Request", // Submit my own request page title

  "suggest-panel.copyUrl.course.label": "Copy Link to Course",
  "suggest-panel.copyUrl.program.label": "Copy Link to Program",
  "suggest-panel.copyUrl.success.course": "Course link copied 🎉", // Suggest panel copy url success message. activtyType is either 'course' or 'program'
  "suggest-panel.copyUrl.success.program": "Program link copied 🎉", // Suggest panel copy url success message. activtyType is either 'course' or 'program'
  "suggest-panel.form.button.course": "Suggest Course", // Label for button if activity is a course
  "suggest-panel.form.button.program": "Suggest Program", // Label for button if activity is a program
  "suggest-panel.form.date.nextSession": "Next Session", // Label if the course/program doesn't have a start date
  "suggest-panel.form.date.starts": "Starts", // Label if the course/program has a start date
  "suggest-panel.form.error.invalid-email": "Please enter a valid email address",
  "suggest-panel.form.error.required": "Enter at least one valid email address",
  "suggest-panel.form.label.email": "Add Email Addresses", // Label for email form field
  "suggest-panel.form.label.textarea": "Personal Message", // Label for personal message form field
  "suggest-panel.form.message.found.course": "I found a great course. Check it out!", // Populated string for personal message if activity is a course on request page, activity page or default
  "suggest-panel.form.message.found.program": "I found a great program. Check it out!", // Populated string for personal message if activity is a program on request page, activity page or default
  "suggest-panel.form.message.taking.course": "I’m taking this course that you might be interested in. Check it out!", // Populated string for personal message if activity is a course on all set page
  "suggest-panel.form.message.taking.program": "I’m taking this program that you might be interested in. Check it out!", // Populated string for personal message if activity is a program on all set page
  "suggest-panel.non-request-page.description.course": "Suggest this course to your colleagues", // Suggest panel title if activity is a course on a non-request-page
  "suggest-panel.non-request-page.description.program": "Suggest this program to your colleagues", // Suggest panel title if activity is a program on a non-request-page
  "suggest-panel.request-page.description.course": "Is this course a good fit for your team? Suggest it to others.", // Suggest panel title if activity is a course on request page
  "suggest-panel.request-page.description.program": "Is this program a good fit for your team? Suggest it to others.", // Suggest panel title if activity is a program on request page
  "suggest-panel.sent.popup.header": "Suggestion sent!", // the text to slide up when the suggestion emails are triggered
  "suggest-panel.sent.popup.subText.course": "Thanks for suggesting this course", // the text to slide up when the suggestion emails are triggered on a course activity
  "suggest-panel.sent.popup.subText.program": "Thanks for suggesting this program", // the text to slide up when the suggestion emails are triggered on a program activity
  "suggest-panel.title.course": "Suggest this course", // Suggest panel title if activity is a course
  "suggest-panel.title.program": "Suggest this program", // Suggest panel title if activity is a program

  "take-action-today-carousel.continueYourProgramTab": "Continue your program", // The tab for the continue your program section of the take action today carousel
  "take-action-today-carousel.heading": "Take action today", // The header for the take action today carousel
  "take-action-today-carousel.myListTab": "My List", // The tab for the my list section of the take action today carousel
  "take-action-today-carousel.onDemandTab": "On Demand", // The tab for the upcoming section of the take action today carousel
  "take-action-today-carousel.startingSoonTab": "Starting Soon", // The tab for the starting soon section of the take action today carousel

  "tenant.approvalModel.centralized": "Centralized", // The display name for the centralized approval model
  "tenant.approvalModel.distributed": "Distributed", // The display name for the distributed approval model
  "tenant.approvalModel.hybrid": "Hybrid", // The display name for the hybrid approval model
  "tenant.entitlement.sponsor": "Sponsor", // Sponsor entitlement for employer tenants
  "tenant.entitlement.watcher": "Watcher", // Watcher entitlement for employer tenants

  "tenantSSO.loginType.magic-link": "Magic link", // The display name for the magic link login type
  "tenantSSO.loginType.saml": "SAML", // The display name for the magic link login type

  "thankyou-page.documentTitle": "Thank You - SkillsWave", // Document title when viewing the thank you page. Do not translate "SkillsWave".

  "thankyou-signup.body": "We've sent a verification email to {userEmail}.<linebreak></linebreak> Please click the link in the email to activate your account.",
  "thankyou-signup.emailNotRecieved": "Didn't receive an email? <resendLink>Resend</resendLink> or <contactLink>contact us</contactLink>",
  "thankyou-signup.title": "Thanks for signing up!",
  "thankyou-signup-email-resent": "Verification email has been sent to {email}",

  "transcript.international": "International",

  "user-summary.approvedAmount.cancelled": "Cancelled", // The value to display for the approved amount when the request is cancelled
  "user-summary.approvedAmount.declined": "Declined", // The value to display for the approved amount when the request has been declined
  "user-summary.approvedAmount.pending": "Pending", // The value to display for the approved amount when the request is still pending
  "user-summary.approvedAmount.withdrawn": "Withdrawn", // The value to display for the approved amount when the learner has withdrawn from the class
  "user-summary.currentFiscalYear.header": "Current Fiscal Year", // The header for the user summary card
  "user-summary.currentFiscalYear.noRequests": "{userDisplayName} doesn’t have any other requests in the current fiscal year", // The header for the user summary card
  "user-summary.header": "Other Requests", // The header for the user summary card
  "user-summary.noRequests": "{userDisplayName} doesn’t have any other requests", // The header for the user summary card
  "user-summary.previousFiscalYears.header": "Previous Fiscal Years", // The header for the user summary card
  "user-summary.previousFiscalYears.noRequests": "{userDisplayName} doesn’t have any other requests in previous fiscal years", // The header for the user summary card

  "view-activities.documentTitle": "Programs - SkillsWave", // Browser page title for the activities page. Do not translate "SkillsWave".
  "view-activities.documentTitle.search": "{searchCriteria} - Programs - SkillsWave", // Browser page title for the activities page when you search. Do not translate "SkillsWave".
  "view-activities.search.placeholder": "Find a skill, course or program", // Placeholder text for activities search bar

  "view-activity.activityMetadata.footer.nextSession": "Next Session", // The request approval button label
  "view-activity.activityMetadata.footer.notRequestableAlert": "You must register for the program to take this course", // Informs the user the course is not requestable
  "view-activity.activityMetadata.footer.notRequestableComingSoonAlert": "Registration will open soon. Add this {activityType} to your list and we’ll email you when registration opens.", // Informs the user the course is not requestable
  "view-activity.activityMetadata.footer.requestApproval": "Start Request", // The request approval button label
  "view-activity.activityMetadata.footer.startDateType": "Start Date Type", // Label Start date type dropdown
  "view-activity.activityMetadata.footer.unscheduledAlert": "This course will become available when the next session is scheduled", // Informs the user that the program/course does not have a scheduled start date yet
  "view-activity.activityOverview.activityLink": "For exact start dates, registration deadlines, refund policy, and other details, view <link>{activityTitle}</link> at {providerName}.", // program/course link text, <link> tag should NOT be translated
  "view-activity.activityOverview.header": "Overview", // Overview section header
  "view-activity.activityOverview.skillsDescription": "Skills associated with this activity", // description for element that contains list of skills
  "view-activity.addToMyList": "Add to My List", // Label for Add to My List button icon
  "view-activity.admissionRequirements.header": "Admission Requirements", // title for admission requirements section
  "view-activity.backToTop": "Back to top", // Anchor displayed at the end of seeing all avaliable programs.
  "view-activity.backToTop.ariaLabel": "Back to top. You will arrive at top of the current page.",
  "view-activity.documentTitle.afterLoading": "{activityTitle} - SkillsWave", // Text which appears in browser page title after someone navigates to the view-activity page after activity data has loaded. Do not translate "SkillsWave".
  "view-activity.documentTitle.whileLoading": "Loading... - SkillsWave", // Text which appears in browser page title after someone navigates to the view-activity page but before the activity title is known. Do not translate "SkillsWave".
  "view-activity.editSkills.button": "Edit Skills", // button text allowing users to edit skills on an activity
  "view-activity.editSkills.save": "Changes to skills have been saved", // message that shows when skill shave been saved on the activity
  "view-activity.editStartDate.button": "Edit Start Date", // button text allowing users to edit start date on an activity
  "view-activity.editStartDate.date.list": "Add session date(s):",
  "view-activity.inactive": "Inactive", // Status indicator for inactive programs
  "view-activity.myListError": "My List could not be updated", // Error when attempting to add or remove activity to My List
  "view-activity.noResults.prompt.1": "No results for:", // Part 1 of sentence informing the user that no activities were found based on selected search parameters
  "view-activity.noResults.prompt.2": "{searchCriteria} and your currently selected filters.", // Part 2 of sentence informing the user that no activities were found based on selected search parameters
  "view-activity.removeFromMyList": "Remove from My List", // Label for Remove from My List button icon
  "view-activity.requirements.header": "Requirements", // Requirements section header
  "view-activity.startDate": "Choose Date", // The course/programs start date input label
  "view-activity.startDateType.anytime": "Anytime", // The course/program starts anytime
  "view-activity.startDateType.comingSoon": "Coming soon", // The course/program start date is coming soon
  "view-activity.startDateType.date": "Date", // The course/program starts on a specific date
  "view-activity.startDateType.unknown": "Unknown", // The course/program start date is unknown
  "view-activity.title": "What skills will you build next?", // Title for activities view page
  "view-activity.toast.added": "Added to My List", // Toast text when adding activity to My List
  "view-activity.toast.removed": "Removed from My List", // Toast text when removing activity from My List

  "view-activity-category.career-skills.company-intro": "Matching skills commonly required by this career at {companyName}", // Skill intro text when viewing all activities for a career while viewing a company tenant
  "view-activity-category.career-skills.non-company-intro": "Skills needed to excel in this career", // Skill intro text when viewing all activities for a career while viewing a NON-company tenant, e.g. an association
  "view-activity-category.courses": "Courses", // Activities of the type course
  "view-activity-category.documentTitle.afterLoading": "{categoryName} - Programs - SkillsWave", // Title of web page when viewing a specific category's activities, while it is loading. Do not translate "SkillsWave".
  "view-activity-category.documentTitle.whileLoading": "Loading... - Programs - SkillsWave", // Title of web page when viewing a specific category's activities, after it has loaded. Do not translate "SkillsWave".
  "view-activity-category.programs": "Programs", // Activities of the type program

  "view-admin.activities": "Activities", // Label for the Activities tab.
  "view-admin.addTenant": "Add Tenant", // Label for the button that creates tenant.
  "view-admin.documentTitle": "Admin - SkillsWave", // Do not translate "SkillsWave".
  "view-admin.documentTitle.auditPermissions": "Permissions - audit", // title for permissions audit page.
  "view-admin.employers.heading1": "Employers", // Heading for the employers list.
  "view-admin.features": "Features", // Label for the Features tab.
  "view-admin.fetchSkills": "Fetch Skills", // Label for the button that fetches skills.
  "view-admin.filterSkills.label": "Filter Skills", // Label for the filter skills textbox.
  "view-admin.filterSkills.table.activityId": "Activity ID", // Heading for the activity ID column.
  "view-admin.filterSkills.table.activityName": "Activity Name", // Heading for the activity name column.
  "view-admin.filterSkills.table.activityType": "Activity Type", // Heading for the activity type column.
  "view-admin.filterSkills.table.skillId": "Skill ID", // Heading for the skill ID column.
  "view-admin.filterSkills.table.skillName": "Skill Name", // Heading for the skill name column.
  "view-admin.loading": "Loading admin...", // Placeholder loading admin page.
  "view-admin.providers.heading1": "Providers", // Heading for the providers list.
  "view-admin.tenantTypeSelect.label": "Tenant Type", // Label for tenant type selection dropdown.
  "view-admin.tenantTypeSelect.none": "-- SELECT TENANT TYPE --", // Default option for tenant type selection dropdown.
  "view-admin.tenants": "Tenants", // Label for the Tenants tab.

  "view-application.activity-details.heading.details": "{activityType} Details", // Heading for activity info
  "view-application.activity-details.submit-own-request.message": "This learning opportunity was found outside of the SkillsWave catalog", // message for courses outside of wave catalog
  "view-application.activityMetadata.footer.nextSession": "Next Session", // The request approval button label
  "view-application.activityOverview.activityLink": "For exact start dates, registration deadlines, refund policy, and other details, view <link>{activityTitle}</link> at {providerName}.", // program/course link text, <link> tag should NOT be translated
  "view-application.activityOverview.header": "Overview", // Overview section header
  "view-application.admissionRequirements.description": "This program has admission requirements and prerequisites.",
  "view-application.backToResults.link.label": "Back to results", // Label for back to results link
  "view-application.documentTitle": "View Request - SkillsWave", // Heading for activity info. Do not translate "SkillsWave".
  "view-application.paymentHandledByProvider.registrationDialog.cancel": "Cancel",
  "view-application.paymentHandledByProvider.registrationDialog.confirm": "Continue",
  "view-application.paymentHandledByProvider.registrationDialog.content": "You are being directed to complete your next steps to register with <b>{providerName}.</b>",
  "view-application.paymentHandledByProvider.registrationDialog.title": "You are leaving {tenantName}",
  "view-application.registrationDetails.cancelled": "This {activityType, select, course {course} program {program} other {activity}} has been cancelled", // toast banner to appear if the course has been cancelled
  "view-application.registrationDetails.connectionbanner": "Account connection email not found for {displayName}.", // error banner when account connection record doesn't exist for user
  "view-application.registrationDetails.copied": "Email copied to your clipboard", // toast banner to confirm that the connection email has been copied to the users clipboard
  "view-application.registrationDetails.copy": "Copy Email", // text for copy button in registation details
  "view-application.registrationDetails.failed": "{displayName} has failed the {activityType, select, course {course} program {program} other {activity}}.", // banner to appear when user has academically failed the course
  "view-application.registrationDetails.header": "Register and Update", // Header for the stripe provider request view
  "view-application.registrationDetails.helpMessage": "Use the email below to find {displayName} in your registration system. If you can’t find this learner, contact SkillsWave Support.", // body text to direct providers to action on the copy email below. Do not translate "SkillsWave".
  "view-application.registrationDetails.nextAction": "Once registered, update this learner’s registration and completion information.", // body text to direct providers to fill out registration form details
  "view-application.registrationDetails.withdrawn": "{displayName} has withdrawn from the {activityType, select, course {course} program {program} other {activity}}.", // toast banner to appear when user has withdrawn from course
  "view-application.requestInformation.header": "Review Request Information", // Header for the stripe provider request view
  "view-application.requirements.header": "Requirements", // Requirements section header

  "view-application-confirmation.backButton": "Back to Request", // Button text for navigating back to request page
  "view-application-confirmation.info": "{institution} will typically take {registrationTime} days to register you. They’ll email you with details at <emailSpan>{email}</emailSpan>.", // Info on request confirmation page
  "view-application-confirmation.info.admissionBased": "{institution} will email you at: <emailSpan>{email}</emailSpan> within {registrationTime} business days about admission and registration details.", // Info on request confirmation page for admission based course
  "view-application-confirmation.subtitle": "{institution} will register you in {activityTitle} and send you an email.", // Request confirmation page subtitle
  "view-application-confirmation.subtitle.admissionBased": "{institution} will send you an email.", // Request confirmation page subtitle for admission based course
  "view-application-confirmation.title": "You’re all done!<linebreak></linebreak> Now just wait to be registered.", // Request confirmation page title for desktop
  "view-application-confirmation.title.mobile": "You’re all done! Now just wait to be registered.", // Request confirmation page title for mobile
  "view-application-confirmation.wait.info": "If your {activityType} doesn’t start for a while, the registration window might not be open yet. Don’t worry, {institution} will register you within {registrationTime} days of registration opening.", // Info about registration window on request confirmation page
  "view-application-confirmation.wait.info.admissionBased": "If your {activityType} doesn’t start for a while, the registration window might not be open yet. Don’t worry, {institution} will contact you within {registrationTime} days of registration opening.", // Info about registration window on request confirmation page for admission based course

  "view-applications.documentTitle": "Requests - SkillsWave", // Web page title for requests page. Do not translate "SkillsWave".
  "view-applications.title": "Requests", // Requests page title

  "view-bookmarks.noBookmarks": "Click the bookmark icon from any program’s details page to add or remove to this list", // Message displayed when there are no bookmarked activities
  "view-bookmarks.title": "Bookmarks", // Bookmarks page title
  "view-error-loading.documentTitle": "Error - SkillsWave", // Document title for when there is an error loading wave. Do not translate "SkillsWave".

  "view-home.carousel.next": "Next Provider Logos",
  "view-home.carousel.prev": "Previous Provider Logos",

  "view-how-it-works.documentTitle": "How it works",

  "view-landing-page.carousel.error.action": "Refresh",
  "view-landing-page.carousel.error.description": "An error occurred while loading recommendations. Refresh your browser to try again.",
  "view-landing-page.carousel.interestedCareer.heading": "Recommended for your career goal:",
  "view-landing-page.carousel.interestedSkillSet.heading": "Recommended for your goals:",
  "view-landing-page.carousel.interestedSkillSet.menuLabel": "Skill sets",
  "view-landing-page.carousel.tabTitle.bestResults": "Best Results",
  "view-landing-page.carousel.tabTitle.courses": "Courses",
  "view-landing-page.carousel.tabTitle.degrees": "Degrees",
  "view-landing-page.carousel.tabTitle.microlearning": "Microlearning",
  "view-landing-page.carousel.tabTitle.shortCredentials": "Short Credentials",
  "view-landing-page.carousel.userRole.heading": "Recommended for your role:",
  "view-landing-page.documentTitle": "Recommendations",
  "view-landing-page.profile.credit.corporatePolicy": "{remainingBalance} out of {totalBalance} available from {employerTenant}",
  "view-landing-page.profile.credit.zeroInvestment": "{amount} invested in you so far, request today to start learning",
  "view-landing-page.profile.description.interests": "You're interested in:", // For the profile card, will be followed by a list of interests (skills and careers)
  "view-landing-page.profile.description.interestsPlaceholder": "Pick 3 or more skill sets.", // For the profile card, will be followed by a list of interests (skills and careers)
  "view-landing-page.profile.description.jobTitle": "Your role is: {jobTitle}", // For the profile card, is followed by view-landing-page.profile.description.interests if there are any interests
  "view-landing-page.profile.editButton": "Edit Goals",
  "view-landing-page.profile.header": "Welcome, {firstName}!",
  "view-landing-page.profile.recommendSettingGoals": "Set your goals to get better recommendations",
  "view-landing-page.profile.showLessButton": "Show less",
  "view-landing-page.profile.showMoreButton": "Show more",
  "view-landing-page.profile.thisFiscalYear": "for this fiscal year",
  "view-landing-page.profile.usedCredit": "{amount} invested in you by {employerTenant}",

  "view-not-found.documentTitle": "Page Not Found - SkillsWave", // Document title for when user navigates to an invalid page and it's not found. Do not translate "SkillsWave".

  "view-onboarding.area-of-interest.description": "You're a(n) {jobTitle} who is interested in:",
  "view-onboarding.documentTitle": "Onboarding", // title for document of onboarding page
  "view-onboarding.generatingResults.subtext.AddingMagic": "Adding some magic", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.Enjoy": "Enjoy!", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.FingingCourses": "Finding courses from top providers", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.subtext.GatherSkills": "Gathering your skill sets and career goals", // text to display beneath the animation that changes throughout
  "view-onboarding.generatingResults.text": "Recommending courses for you", // text to display beneath the animation
  "view-onboarding.getStarted.closestMatchLabel": "Your closest industry match", // Label for input where user selects a predefined job title that most closely matches the industry definition of their current role
  "view-onboarding.getStarted.currentRoleLabel": "Enter your current role", // Offscreen label for input where user inputs their job title
  "view-onboarding.getStarted.currentRolePlaceholder": "Your current role", // Placeholder text for input where user inputs their job title
  "view-onboarding.getStarted.editSOC.button": "Edit", // Button text for editing job title match with third party platform job titles
  "view-onboarding.getStarted.existingUserAlert": "Before continuing to your account, set your goals to improve learning recommendations", // alert message for exisitng users that haven't completed onboarding
  "view-onboarding.getStarted.introduction.subheader": "Hi {name}, your role is:", // Introduction text saying hello to user and introducing their job title/role in our system
  "view-onboarding.getStarted.marquee.header": "Upskill now, thank yourself later", // Marquee header for onboarding process to upskilling platform
  "view-onboarding.getStarted.marquee.subheader": "Find quality education opportunities to help you reach your full potential", // Subtext for marquee header that explains the websites purpose
  "view-onboarding.getStarted.skillpopper.label": "Key skills for your role:", // Supplemental text explaining that the system is currently loading skills related to the users selected profession
  "view-onboarding.getStarted.submit.button": "Set Your Goals", // Button text for submitting page and moving forward with onboarding process

  "view-report-dashboard.documentTitle": "Analytics - SkillsWave", // Document title for the Analytics page. Do not translate "SkillsWave".
  "view-report-dashboard.employerSelect.all": "ALL EMPLOYERS", // All employers option for employer selection dropdown
  "view-report-dashboard.employerSelect.label": "Select an employer", // Label for employer selection dropdown
  "view-report-dashboard.employerSelect.none": "-- SELECT EMPLOYER --", // Default option for employer selection dropdown
  "view-report-dashboard.filterBy": "Filter by", // Label to proceed filter bar interactions
  "view-report-dashboard.filterRemove": "Click to remove value", // Button to remove filter options
  "view-report-dashboard.mobile.otherFilters": "Other filters", // The other filters button label
  "view-report-dashboard.providerSelect.all": "ALL PROVIDERS", // All providers option for provider selection dropdown
  "view-report-dashboard.providerSelect.label": "Select a provider", // Label for provider selection dropdown
  "view-report-dashboard.providerSelect.none": "-- SELECT PROVIDER --", // Default option for provider selection dropdown
  "view-report-dashboard.timeframeFilter.appliedFilter": "Applied Filters", // Label for applied filter
  "view-report-dashboard.timeframeFilter.applyButton": "Apply", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.clearButton": "Clear", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.custom": "Custom", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.endDate": "End Date", // Timeframe end date input label
  "view-report-dashboard.timeframeFilter.from": "From", // Time frame 'From' word to use it like part of 'From dd\mm\yyyy ...'
  "view-report-dashboard.timeframeFilter.invalidDateRange": "Start date must be before end date", // Invalid date range message
  "view-report-dashboard.timeframeFilter.last": "Last", // Time frame 'Last' word to use it like part of 'Last 30 days'
  "view-report-dashboard.timeframeFilter.lastMonth": "30 days", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastThreeMonths": "90 days", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastWeek": "Last 7 days", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.lastYear": "YTD", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.requiredFieldMessage": "Field is required", // Required field message
  "view-report-dashboard.timeframeFilter.sinceFiscalYear": "Fiscal YTD", // Reports dashboard time frame filter option
  "view-report-dashboard.timeframeFilter.startDate": "Start Date", // Timeframe start date input label
  "view-report-dashboard.timeframeFilter.timeframeFilterButton": "Time", // Reports dashboard time frame filter button
  "view-report-dashboard.timeframeFilter.to": "to", // Time frame 'From' word to use it like part of '... to dd\mm\yyyy'
  "view-report-dashboard.title": "Analytics", // Reports dashboard page title
};
